import React, {useEffect} from "react";
import BookingsSettingsLogic from "./BookingsSettingsLogic";

import moment from "moment";
import localization from "moment/locale/fr";

// @material-ui/core components
import {
    Button as MUIButton,
    CircularProgress,
    Divider,
    Backdrop,
    Modal,
    Tooltip,
    Chip,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// Other front packages
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import Tabs from "components/CustomTabs/CustomTabs.js";

// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import FormA1001 from "components/FormUsers/FormA1001.js";
import FormA1001Doors from "components/FormUsers/FormA1001Doors.js";
import FormCleaning from "components/FormUsers/FormCleaning.js";
import Form2ns from "components/FormUsers/Form2ns";
import FormLocations from "components/FormUsers/FormLocations.js";
import FormRessources from "components/FormUsers/FormRessources.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import {ReactComponent as AddIcon} from "assets/img/svg/AddIcon.svg";

import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import RoomIcon from "@material-ui/icons/Room";
import StorageIcon from "@material-ui/icons/Storage";
import DateRangeIcon from "@material-ui/icons/DateRange";
import BookIcon from "@material-ui/icons/Book";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DialpadIcon from "@material-ui/icons/Dialpad";

const paginationStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#6E6FA9",
        },
        "& .Mui-selected": {
            backgroundColor: "#6E6FA9",
            color: "#E7E6ED",
        },
    },
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function BookingsSettings(props) {
    const classes = useStyles();
    const paginationClasses = paginationStyles();

    const {
        a1001List,
        a1001Count,
        a1001ModalOpened,
        bookingDestroy,
        cleaningCount,
        cleaningList,
        cleaningModalOpened,
        doorsList,
        doorsCount,
        doorsModalOpened,
        formTwoNValid,
        formA1001List,
        formA1001Valid,
        formCleaningList,
        formCleaningValid,
        formDoorsValid,
        formLocationDoorsList,
        formLocationList,
        formLocationValid,
        formModulesList,
        formRessourceValid,
        formRulesList,
        formSectorList,
        formTypeList,
        limits,
        locationRecurrence,
        locationCount,
        locationList,
        locationModalOpened,
        modalTwoNType,
        modalA1001Type,
        modalCleaningType,
        modalDoorsType,
        modalLocationType,
        modalRessourceType,
        newTwoNInfo,
        newA1001Info,
        newCleaningInfo,
        newA1001DoorsInfo,
        newLocationInfo,
        newRessourceInfo,
        notifColor,
        notifMessage,
        openNotif,
        pageTwoN,
        pageA1001,
        pageCleaning,
        pageDoors,
        pageLocation,
        pageRessource,
        periodsList,
        refresh,
        refreshImageLocation,
        refreshImageRessource,
        ressourcesCount,
        ressourcesList,
        ressourceRecurrence,
        ressourceModalOpened,
        twoNCount,
        twoNList,
        twoNModalOpened,
        addPeriodToRessource,
        updatePeriodFromRessource,
        deletePeriodFromRessource,
        deleteBooking,
        getTitleInterval,
        handle2nFormChange,
        handleA1001FormChange,
        handleCleaningFormChange,
        handleDoorsFormChange,
        handleLocationFormChange,
        handlePagination2n,
        handlePaginationA1001,
        handlePaginationCleaning,
        handlePaginationDoors,
        handlePaginationLocation,
        handlePaginationRessource,
        handleRessourceFormChange,
        modalMessageBooking,
        parseHourTime,
        refreshData,
        requestNewIcsKey,
        setBookingState,
        setOpenNotif,
        setPeriodsList,
        submitNew2n,
        submitNewA1001,
        submitNewCleaning,
        submitNewDoors,
        submitNewLocation,
        submitNewRessource,
        toggleConfirmBookingDestroy,
        toggleModal2n,
        toggleModalA1001,
        toggleModalCleaning,
        toggleModalA1001Doors,
        toggleModalLocation,
        toggleModalRessource,
    } = BookingsSettingsLogic();

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refresh]);

    if (ressourcesList) {
        return (
            <div>
                <Snackbar
                    place="tr"
                    message={notifMessage}
                    open={openNotif}
                    close
                    icon={
                        notifColor === "success"
                            ? CheckCircleIcon
                            : notifColor === "danger"
                                ? WarningIcon
                                : NotificationsIcon
                    }
                    closeNotification={() => setOpenNotif(false)}
                    color={notifColor}
                />
                {bookingDestroy && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{timeout: 500}}
                        className={classes.modal}
                        onClose={() => toggleConfirmBookingDestroy(null)}
                        open={!!bookingDestroy}
                    >
                        <div className={classes.paper}>
                            <ConfirmModal
                                closeModal={() => toggleConfirmBookingDestroy(null)}
                                kind={modalMessageBooking()}
                                makeAction={() => deleteBooking(bookingDestroy.uuid)}
                            />
                        </div>
                    </Modal>
                )}
                {ressourceModalOpened && newRessourceInfo && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        className={classes.modal}
                        onClose={() => toggleModalRessource()}
                        open={ressourceModalOpened}
                    >
                        <div
                            className={classes.paper}
                            style={{
                                maxHeight: "80%",
                                maxWidth: "70%",
                            }}
                        >
                            <FormRessources
                                formCleaningList={formCleaningList}
                                formLocationList={formLocationList}
                                formModulesList={formModulesList}
                                formRessourceValid={formRessourceValid}
                                periodsList={periodsList}
                                formRulesList={formRulesList}
                                formTypeList={formTypeList}
                                modalRessourceType={modalRessourceType}
                                newRessourceInfo={newRessourceInfo}
                                refreshImageRessource={refreshImageRessource}
                                handleRessourceFormChange={handleRessourceFormChange}
                                submitNewRessource={submitNewRessource}
                                toggleModalRessource={toggleModalRessource}
                                addPeriodToRessource={addPeriodToRessource}
                                updatePeriodFromRessource={updatePeriodFromRessource}
                                deletePeriodFromRessource={deletePeriodFromRessource}
                                setPeriodsList={setPeriodsList}
                            />
                        </div>
                    </Modal>
                )}
                {locationModalOpened && newLocationInfo && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        className={classes.modal}
                        onClose={() => toggleModalLocation()}
                        open={locationModalOpened}
                    >
                        <div
                            className={classes.paper}
                            style={{
                                maxHeight: "80%",
                            }}
                        >
                            <FormLocations
                                formLocationValid={formLocationValid}
                                modalLocationType={modalLocationType}
                                newLocationInfo={newLocationInfo}
                                refreshImageLocation={refreshImageLocation}
                                handleLocationFormChange={handleLocationFormChange}
                                submitNewLocation={submitNewLocation}
                                toggleModalLocation={toggleModalLocation}
                                formSectorList={formSectorList}
                            />
                        </div>
                    </Modal>
                )}
                {cleaningModalOpened && newCleaningInfo && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        className={classes.modal}
                        onClose={() => toggleModalCleaning()}
                        open={cleaningModalOpened}
                    >
                        <div
                            className={classes.paper}
                            style={{
                                maxHeight: "80%",
                            }}
                        >
                            <FormCleaning
                                formCleaningValid={formCleaningValid}
                                modalCleaningType={modalCleaningType}
                                newCleaningInfo={newCleaningInfo}
                                handleCleaningFormChange={handleCleaningFormChange}
                                requestNewIcsKey={requestNewIcsKey}
                                submitNewCleaning={submitNewCleaning}
                                toggleModalCleaning={toggleModalCleaning}
                            />
                        </div>
                    </Modal>
                )}
                {a1001ModalOpened && newA1001Info && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        className={classes.modal}
                        onClose={() => toggleModalA1001()}
                        open={a1001ModalOpened}
                    >
                        <div
                            className={classes.paper}
                            style={{
                                maxHeight: "80%",
                            }}
                        >
                            <FormA1001
                                formA1001Valid={formA1001Valid}
                                modalA1001Type={modalA1001Type}
                                newA1001Info={newA1001Info}
                                handleA1001FormChange={handleA1001FormChange}
                                requestNewIcsKey={requestNewIcsKey}
                                submitNewA1001={submitNewA1001}
                                toggleModalA1001={toggleModalA1001}
                            />
                        </div>
                    </Modal>
                )}
                {doorsModalOpened && newA1001DoorsInfo && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        className={classes.modal}
                        onClose={() => toggleModalA1001Doors()}
                        open={doorsModalOpened}
                    >
                        <div
                            className={classes.paper}
                            style={{
                                maxHeight: "80%",
                            }}
                        >
                            <FormA1001Doors
                                formA1001List={formA1001List}
                                formDoorsValid={formDoorsValid}
                                formLocationDoorsList={formLocationDoorsList}
                                modalDoorsType={modalDoorsType}
                                newA1001DoorsInfo={newA1001DoorsInfo}
                                handleDoorsFormChange={handleDoorsFormChange}
                                requestNewIcsKey={requestNewIcsKey}
                                submitNewDoors={submitNewDoors}
                                toggleModalA1001Doors={toggleModalA1001Doors}
                            />
                        </div>
                    </Modal>
                )}
                {twoNModalOpened && newTwoNInfo && (
                    <Modal
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        className={classes.modal}
                        onClose={() => toggleModal2n()}
                        open={twoNModalOpened}
                    >
                        <div
                            className={classes.paper}
                            style={{
                                maxHeight: "80%",
                            }}
                        >
                            <Form2ns
                                formTwoNValid={formTwoNValid}
                                modalTwoNType={modalTwoNType}
                                formLocationList={formLocationList}
                                newTwoNInfo={newTwoNInfo}
                                handle2nFormChange={handle2nFormChange}
                                submitNew2n={submitNew2n}
                                toggleModal2n={toggleModal2n}
                            />
                        </div>
                    </Modal>
                )}
                <GridContainer>
                    <Tabs
                        tabs={[
                            {
                                // tabWidth: 'auto',
                                tabName: "Ressources",
                                tabIcon: BookIcon,
                                tabOnClick: () => setBookingState("ressource"),
                                tabContent: (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <MUIButton
                                                        className={classes.button}
                                                        onClick={() => toggleModalRessource()}
                                                        startIcon={<AddIcon/>}
                                                        style={{width: "auto"}}
                                                    >
                                                        Ajouter une nouvelle ressource
                                                    </MUIButton>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                        <div>
                                            {ressourcesList && ressourcesList.length === 0 && (
                                                <h4>Aucune ressource à afficher</h4>
                                            )}
                                            {ressourcesList &&
                                                ressourcesList?.map((ressource, i) => (
                                                    <div key={i}>
                                                        <Card>
                                                            <div>
                                                                <CardHeader
                                                                    style={{
                                                                        borderRadius: 1,
                                                                        paddingTop: 0,
                                                                        border: "none",
                                                                        boxShadow:
                                                                            "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                                                                    }}
                                                                >
                                                                    <h4
                                                                        className={classes.cardTitle}
                                                                        style={{display: "flex"}}
                                                                    >
                                                                        {ressource?.enabled ? (
                                                                            <Tooltip title="Activé">
                                                                                <DoneIcon
                                                                                    style={{color: "#5cb85c"}}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Désactivé">
                                                                                <BlockIcon
                                                                                    style={{color: "#c9302c"}}
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                        <div style={{marginLeft: "0.5%"}}>
                                                                            {ressource?.title}
                                                                        </div>
                                                                    </h4>
                                                                    <p className={classes.cardDescription}>
                                                                        {parseHourTime(ressource?.open_at) +
                                                                            " - " +
                                                                            parseHourTime(ressource?.close_at)}
                                                                    </p>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <GridItem
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        style={{marginTop: "1%"}}
                                                                    >
                                                                        {ressource?.features &&
                                                                            ressource.features.map((it, i) => (
                                                                                <Chip
                                                                                    key={i}
                                                                                    label={it.custom_title}
                                                                                    color="primary"
                                                                                    style={{marginRight: "5px"}}
                                                                                />
                                                                            ))}
                                                                    </GridItem>
                                                                    <div style={{display: "flex"}}>
                                                                        {refreshImageRessource &&
                                                                            (refreshImageRessource[
                                                                                ressource?.uuid
                                                                                ] ? (
                                                                                <CircularProgress/>
                                                                            ) : (
                                                                                ressource.has_picture && (
                                                                                    <img
                                                                                        style={{
                                                                                            marginTop: "1%",
                                                                                            height: "100px",
                                                                                        }}
                                                                                        src={`${process.env.REACT_APP_CDN_URL}/app/module/reservation/ressources/${ressource?.uuid}/image?refresh=${refresh}`}
                                                                                        alt=""
                                                                                        onError={i => {
                                                                                            i.target.style.display = "none";
                                                                                            i.target.alt =
                                                                                                "Aucune image disponible";
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            ))}
                                                                        <div
                                                                            style={{
                                                                                display: "column",
                                                                                alignSelf: "center",
                                                                            }}
                                                                        >
                                                                            <GridItem
                                                                                xs={12}
                                                                                sm={12}
                                                                                md={12}
                                                                                style={{
                                                                                    marginTop: "1%",
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                {`Prix: ${parseFloat(
                                                                                    ((ressource?.booking_price
                                                                                            ? ressource?.booking_price
                                                                                            : 0) +
                                                                                        (ressource?.unit_price
                                                                                            ? ressource?.unit_price
                                                                                            : 0)) /
                                                                                    100
                                                                                ).toFixed(2)} €`}
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={12}>
                                                                                {`Prix de la réservation: ${parseFloat(
                                                                                    ressource?.booking_price
                                                                                        ? ressource?.booking_price / 100
                                                                                        : 0
                                                                                ).toFixed(
                                                                                    2
                                                                                )} € / prix de l'unité: ${parseFloat(
                                                                                    ressource?.unit_price
                                                                                        ? ressource?.unit_price / 100
                                                                                        : 0
                                                                                ).toFixed(2)} €`}
                                                                            </GridItem>
                                                                        </div>
                                                                    </div>
                                                                    {ressource?.custom_message?.length > 0 && (
                                                                        <div
                                                                            style={{
                                                                                marginTop: "2%",
                                                                                fontSize: 17,
                                                                                marginLeft: "8px",
                                                                            }}
                                                                        >
                                                                            {`Message complémentaire: ${ressource?.custom_message}`}
                                                                        </div>
                                                                    )}
                                                                    <Table
                                                                        tableHeaderColor="primary"
                                                                        tableType="booking"
                                                                        tableHead={[
                                                                            "Emplacement",
                                                                            "Mode de réservation",
                                                                            "Règles d'usage",
                                                                            "Jours d'ouverture",
                                                                            "Nettoyage",
                                                                            "Accès continu",
                                                                            "Unité de réservation",
                                                                            "Unité réservable",
                                                                            "Mois disponible",
                                                                            "Jours disponible",
                                                                        ]}
                                                                        tableData={[
                                                                            Object.values({
                                                                                location: ressource?.location?.title,
                                                                                booking_method:
                                                                                    ressource?.booking_method ===
                                                                                    "SUBSCRIBE"
                                                                                        ? "Abonnement"
                                                                                        : "Ponctuel",
                                                                                rule: ressource?.rule?.title,
                                                                                recurence:
                                                                                    ressourceRecurrence?.length > i &&
                                                                                    ressourceRecurrence[i].length ===
                                                                                    7 ? (
                                                                                        ressourceRecurrence[
                                                                                            i
                                                                                            ].map((it, j) => (
                                                                                            <Chip
                                                                                                key={j}
                                                                                                label={it.d}
                                                                                                color={
                                                                                                    it.active
                                                                                                        ? "primary"
                                                                                                        : "default"
                                                                                                }
                                                                                                style={{marginRight: "5px"}}
                                                                                            />
                                                                                        ))
                                                                                    ) : (
                                                                                        <div className={classes.root}>
                                                                                            <CircularProgress/>
                                                                                        </div>
                                                                                    ),
                                                                                cleaning: ressource?.cleaning?.title,
                                                                                access: ressource?.interval_unified
                                                                                    ? "Oui"
                                                                                    : "Non",
                                                                                unit: `${
                                                                                    ressource?.interval_value
                                                                                        ? ressource?.interval_value
                                                                                        : 0
                                                                                } ${getTitleInterval(
                                                                                    ressource?.interval_type
                                                                                )}`,
                                                                                available:
                                                                                    ressource?.unit_min ===
                                                                                    ressource?.unit_max
                                                                                        ? ressource?.unit_min
                                                                                        : `De ${
                                                                                            ressource?.unit_min
                                                                                                ? ressource?.unit_min
                                                                                                : 0
                                                                                        } à ${
                                                                                            ressource?.unit_max
                                                                                                ? ressource?.unit_max
                                                                                                : 0
                                                                                        }`,
                                                                                month: ressource?.available_months,
                                                                                day: ressource?.available_days,
                                                                            }),
                                                                        ]}
                                                                    />
                                                                    <Table
                                                                        tableHeaderColor="primary"
                                                                        tableType="booking"
                                                                        tableHead={[
                                                                            "Nombre d'accès simultanés",
                                                                            "Délai de réservation pour un utilisateur",
                                                                            "Délai de réservation entre deux utilisateurs",
                                                                            "Délai de réservation",
                                                                            "Délai d'annulation",
                                                                            "Quota d'utilisation",
                                                                            "Nombre Réservation max",
                                                                            "Réservation appliquée au groupe",
                                                                            "Avertir Admins",
                                                                        ]}
                                                                        tableData={[
                                                                            Object.values({
                                                                                access: ressource?.available_units,
                                                                                delay: `${
                                                                                    ressource?.unit_self_delay
                                                                                        ? ressource?.unit_self_delay
                                                                                        : 0
                                                                                }  unité(s)`,
                                                                                delay_other: `${
                                                                                    ressource?.unit_users_delay
                                                                                        ? ressource?.unit_users_delay
                                                                                        : 0
                                                                                } unité(s)`,
                                                                                bookingD:
                                                                                (ressource?.booking_delay_hr
                                                                                    ? ressource?.booking_delay_hr
                                                                                    : 0) + " heure(s)",
                                                                                cancelD:
                                                                                    (ressource?.cancel_delay_hr
                                                                                        ? ressource?.cancel_delay_hr
                                                                                        : 0) + " heure(s)",
                                                                                quota: ressource?.quota
                                                                                    ? ressource?.quota
                                                                                    : "Sans limite",
                                                                                max_rolling_bookings: ressource?.max_rolling_bookings
                                                                                    ? ressource?.max_rolling_bookings
                                                                                    : "Sans limite",
                                                                                group: ressource?.is_group_access
                                                                                    ? "Oui"
                                                                                    : "Non",
                                                                                warnAdmin: ressource?.warnAdmin
                                                                                    ? "Oui"
                                                                                    : "Non",
                                                                            }),
                                                                        ]}
                                                                    />
                                                                    <GridItem
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        style={{marginTop: "1%"}}
                                                                    >
                                                                        <Button
                                                                            onClick={() =>
                                                                                toggleModalRessource(ressource)
                                                                            }
                                                                            color="warning"
                                                                        >
                                                                            Éditer
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            onClick={() =>
                                                                                toggleConfirmBookingDestroy(
                                                                                    "ressource",
                                                                                    ressource
                                                                                )
                                                                            }
                                                                            color="danger"
                                                                        >
                                                                            Supprimer
                                                                        </Button>
                                                                    </GridItem>
                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                        {ressourcesList.length - 1 !== i && <Divider/>}
                                                    </div>
                                                ))}
                                            {ressourcesCount > 0 && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: 100,
                                                    }}
                                                >
                                                    <Pagination
                                                        count={Math.ceil(
                                                            ressourcesCount / limits.ressource
                                                        )}
                                                        page={pageRessource}
                                                        onChange={(event, pageNumber) => {
                                                            handlePaginationRessource(pageNumber);
                                                        }}
                                                        classes={{ul: paginationClasses.ul}}
                                                        size="large"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </GridItem>
                                ),
                            },
                            {
                                // tabWidth: 'auto',
                                tabName: "Emplacements",
                                tabIcon: RoomIcon,
                                tabOnClick: () => setBookingState("location"),
                                tabContent: (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <MUIButton
                                                        className={classes.button}
                                                        onClick={() => toggleModalLocation()}
                                                        startIcon={<AddIcon/>}
                                                        style={{width: "auto"}}
                                                    >
                                                        Ajouter un nouvel emplacement
                                                    </MUIButton>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {locationList && locationList.length === 0 && (
                                                <h4>Aucun emplacement à afficher</h4>
                                            )}
                                            {locationList && locationList.length > 0 && (
                                                <Table
                                                    tableHeaderColor="primary"
                                                    tableType="booking"
                                                    tableHead={[
                                                        "Titre",
                                                        "Image",
                                                        "Détails de l'emplacement",
                                                        "Bâtiment",
                                                        "",
                                                    ]}
                                                    tableData={
                                                        locationList &&
                                                        locationList.map(location => {
                                                            const locationList = {
                                                                title: location?.title,
                                                                img:
                                                                    refreshImageLocation &&
                                                                    (refreshImageLocation[location?.uuid] ? (
                                                                        <CircularProgress/>
                                                                    ) : location?.has_picture ? (
                                                                        <img
                                                                            style={{
                                                                                marginTop: "1%",
                                                                                height: "100px",
                                                                            }}
                                                                            src={`${process.env.REACT_APP_CDN_URL}/app/module/reservation/locations/${location?.uuid}/image?refresh=${refresh}`}
                                                                            alt=""
                                                                            onError={i => {
                                                                                i.target.style.display = "none";
                                                                                i.target.alt =
                                                                                    "Aucune image disponible";
                                                                            }}
                                                                        />
                                                                    ) : null),
                                                                details: location?.details,
                                                                sector: location?.sector?.name ?? "---",
                                                                actions: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex-end",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            onClick={() =>
                                                                                toggleModalLocation(location)
                                                                            }
                                                                            size="sm"
                                                                            color="warning"
                                                                        >
                                                                            Éditer
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                toggleConfirmBookingDestroy(
                                                                                    "location",
                                                                                    location
                                                                                )
                                                                            }
                                                                            color="danger"
                                                                        >
                                                                            Supprimer
                                                                        </Button>
                                                                    </div>
                                                                ),
                                                            };
                                                            return Object.values(locationList);
                                                        })
                                                    }
                                                />
                                            )}
                                            {locationCount > 0 && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: 100,
                                                    }}
                                                >
                                                    <Pagination
                                                        count={Math.ceil(locationCount / limits.location)}
                                                        page={pageLocation}
                                                        onChange={(event, pageNumber) => {
                                                            handlePaginationLocation(pageNumber);
                                                        }}
                                                        classes={{ul: paginationClasses.ul}}
                                                        size="large"
                                                    />
                                                </div>
                                            )}
                                        </GridItem>
                                    </GridItem>
                                ),
                            },
                            {
                                // tabWidth: 150,
                                tabName: "Ménage",
                                tabIcon: DateRangeIcon,
                                tabOnClick: () => setBookingState("cleaning"),
                                tabContent: (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <MUIButton
                                                        className={classes.button}
                                                        onClick={() => toggleModalCleaning()}
                                                        startIcon={<AddIcon/>}
                                                        style={{width: "auto"}}
                                                    >
                                                        Ajouter un nouveau ménage
                                                    </MUIButton>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {cleaningList && cleaningList.length === 0 && (
                                                <h4>Aucun ménage à afficher</h4>
                                            )}
                                            {cleaningList && cleaningList.length > 0 && (
                                                <Table
                                                    tableHeaderColor="primary"
                                                    tableType="booking"
                                                    tableHead={[
                                                        "Titre",
                                                        "Email",
                                                        "Email CC",
                                                        "Lien Calendrier iCal",
                                                        "Jours travaillés",
                                                        "",
                                                    ]}
                                                    tableData={
                                                        cleaningList &&
                                                        cleaningList.map((cleaning, i) => {
                                                            const CleaningList = {
                                                                title: cleaning?.title,
                                                                email: cleaning?.email,
                                                                emailCc: cleaning?.email_cc,
                                                                ics: (
                                                                    <a
                                                                        href={`https://ics.ouba.city/cleaning/${cleaning?.ics_key}/calendar.ics`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {`https://ics.ouba.city/cleaning/${cleaning?.ics_key}/calendar.ics`}
                                                                    </a>
                                                                ),
                                                                days:
                                                                    locationRecurrence?.length > i &&
                                                                    locationRecurrence[i].length === 7 ? (
                                                                        locationRecurrence[i].map((it, j) => (
                                                                            <Chip
                                                                                key={j}
                                                                                label={it.d}
                                                                                color={
                                                                                    it.active ? "primary" : "default"
                                                                                }
                                                                                style={{marginRight: "5px"}}
                                                                            />
                                                                        ))
                                                                    ) : (
                                                                        <div className={classes.root}>
                                                                            <CircularProgress/>
                                                                        </div>
                                                                    ),
                                                                actions: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex-end",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            onClick={() =>
                                                                                toggleModalCleaning(cleaning)
                                                                            }
                                                                            size="sm"
                                                                            color="warning"
                                                                        >
                                                                            Éditer
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                toggleConfirmBookingDestroy(
                                                                                    "cleaning",
                                                                                    cleaning
                                                                                )
                                                                            }
                                                                            color="danger"
                                                                        >
                                                                            Supprimer
                                                                        </Button>
                                                                    </div>
                                                                ),
                                                            };
                                                            return Object.values(CleaningList);
                                                        })
                                                    }
                                                />
                                            )}
                                            {cleaningCount > 0 && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: 100,
                                                    }}
                                                >
                                                    <Pagination
                                                        count={Math.ceil(cleaningCount / limits.cleaning)}
                                                        page={pageCleaning}
                                                        onChange={(event, pageNumber) => {
                                                            handlePaginationCleaning(pageNumber);
                                                        }}
                                                        classes={{ul: paginationClasses.ul}}
                                                        size="large"
                                                    />
                                                </div>
                                            )}
                                        </GridItem>
                                    </GridItem>
                                ),
                            },
                            {
                                // tabWidth: 120,
                                tabName: "A1001",
                                tabIcon: StorageIcon,
                                tabOnClick: () => setBookingState("a1001"),
                                tabContent: (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <MUIButton
                                                        className={classes.button}
                                                        onClick={() => toggleModalA1001()}
                                                        startIcon={<AddIcon/>}
                                                        style={{width: "auto"}}
                                                    >
                                                        Ajouter un nouveau A1001
                                                    </MUIButton>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {a1001List && a1001List.length === 0 && (
                                                <h4>Aucun A1001 à afficher</h4>
                                            )}
                                            {a1001List && a1001List.length > 0 && (
                                                <Table
                                                    tableHeaderColor="primary"
                                                    tableType="booking"
                                                    tableHead={[
                                                        "Titre",
                                                        "IP / Domaine",
                                                        "Port",
                                                        "TLS faible",
                                                        "Utilisateur",
                                                        "Mot de passe",
                                                        "",
                                                    ]}
                                                    tableData={
                                                        a1001List &&
                                                        a1001List.map((a1001, i) => {
                                                            const A1001List = {
                                                                title: a1001?.title,
                                                                host: a1001?.host,
                                                                port: a1001?.port,
                                                                is_weak_cert: `${a1001?.is_weak_cert ? "Oui" : "Non"}`,
                                                                user: a1001?.username,
                                                                password: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex-end",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                const isChromium = !!window.chrome;
                                                                                const copyToClipboard = (text) => {
                                                                                    navigator.clipboard.writeText(text).then(() => {
                                                                                        console.log("Copied to Clipboard Succefully");
                                                                                    }, () => {
                                                                                        console.error("Clipboard Copy Failed");
                                                                                    });
                                                                                }
                                                                                if (isChromium){
                                                                                    navigator.permissions.query({name: "clipboard-write"}).then((result) => {
                                                                                      if (result.state === "granted" || result.state === "prompt") {
                                                                                          copyToClipboard(a1001.password)
                                                                                      }else{
                                                                                          console.warn("User did not authorized clipboard copy");
                                                                                      }
                                                                                    });
                                                                                }else{
                                                                                    copyToClipboard(a1001.password)
                                                                                }
                                                                                    
                                                                            }}
                                                                            color="primary"
                                                                        >Copier</Button>
                                                                    </div>
                                                                ),
                                                                actions: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex-end",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            target="_blank"
                                                                            href={`https://${a1001.host}:${a1001.port}`}
                                                                            color="primary"
                                                                        >
                                                                            Accéder
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => toggleModalA1001(a1001)}
                                                                            style={{marginLeft: "2%"}}
                                                                            size="sm"
                                                                            color="warning"
                                                                        >
                                                                            Éditer
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                toggleConfirmBookingDestroy(
                                                                                    "a1001",
                                                                                    a1001
                                                                                )
                                                                            }
                                                                            color="danger"
                                                                        >
                                                                            Supprimer
                                                                        </Button>
                                                                    </div>
                                                                ),
                                                            };
                                                            return Object.values(A1001List);
                                                        })
                                                    }
                                                />
                                            )}
                                            {a1001Count > 0 && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: 100,
                                                    }}
                                                >
                                                    <Pagination
                                                        count={Math.ceil(a1001Count / limits.a1001)}
                                                        page={pageA1001}
                                                        onChange={(event, pageNumber) => {
                                                            handlePaginationA1001(pageNumber);
                                                        }}
                                                        classes={{ul: paginationClasses.ul}}
                                                        size="large"
                                                    />
                                                </div>
                                            )}
                                        </GridItem>
                                    </GridItem>
                                ),
                            },
                            {
                                // tabWidth: 140,
                                tabName: "Portes A1001",
                                tabIcon: MeetingRoomIcon,
                                tabOnClick: () => setBookingState("doors"),
                                tabContent: (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <MUIButton
                                                        className={classes.button}
                                                        onClick={() => toggleModalA1001Doors()}
                                                        startIcon={<AddIcon/>}
                                                        style={{width: "auto"}}
                                                    >
                                                        Ajouter un nouvelle porte
                                                    </MUIButton>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {doorsList && doorsList.length === 0 && (
                                                <h4>Aucune porte à afficher</h4>
                                            )}
                                            {doorsList && doorsList.length > 0 && (
                                                <Table
                                                    tableHeaderColor="primary"
                                                    tableType="booking"
                                                    tableHead={[
                                                        "Titre",
                                                        "APToken",
                                                        "DoorToken",
                                                        "FastOpen",
                                                        "A1001",
                                                        "Emplacement",
                                                        "",
                                                    ]}
                                                    tableData={
                                                        doorsList &&
                                                        doorsList.map((door, i) => {
                                                            const DoorList = {
                                                                title: door?.title,
                                                                accessPointToken: door?.accessPointToken,
                                                                doorToken: door?.doorToken,
                                                                fastOpen: door?.fastOpen == 0 ? "0" : 1,
                                                                a1001: door?.a1001?.title,
                                                                location:
                                                                    door?.locations &&
                                                                    door.locations.map((it, i) => (
                                                                        <Chip
                                                                            key={i}
                                                                            label={it.title}
                                                                            color="primary"
                                                                            style={{marginRight: "5px"}}
                                                                        />
                                                                    )),
                                                                actions: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            style={{marginLeft: "auto"}}
                                                                            onClick={() => toggleModalA1001Doors(door)}
                                                                            size="sm"
                                                                            color="warning"
                                                                        >
                                                                            Éditer
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                toggleConfirmBookingDestroy(
                                                                                    "door",
                                                                                    door
                                                                                )
                                                                            }
                                                                            color="danger"
                                                                        >
                                                                            Supprimer
                                                                        </Button>
                                                                    </div>
                                                                ),
                                                            };
                                                            return Object.values(DoorList);
                                                        })
                                                    }
                                                />
                                            )}
                                            {doorsCount > 0 && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: 100,
                                                    }}
                                                >
                                                    <Pagination
                                                        count={Math.ceil(doorsCount / limits.door)}
                                                        page={pageDoors}
                                                        onChange={(event, pageNumber) => {
                                                            handlePaginationDoors(pageNumber);
                                                        }}
                                                        classes={{ul: paginationClasses.ul}}
                                                        size="large"
                                                    />
                                                </div>
                                            )}
                                        </GridItem>
                                    </GridItem>
                                ),
                            },
                            {
                                // tabWidth: 140,
                                tabName: "2N",
                                tabIcon: DialpadIcon,
                                tabOnClick: () => setBookingState("2n"),
                                tabContent: (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <MUIButton
                                                        className={classes.button}
                                                        onClick={() => toggleModal2n()}
                                                        startIcon={<AddIcon/>}
                                                        style={{width: "auto"}}
                                                    >
                                                        Ajouter un nouveau 2N
                                                    </MUIButton>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {twoNList && twoNList.length === 0 && (
                                                <h4>Aucun 2N à afficher</h4>
                                            )}
                                            {twoNList && twoNList.length > 0 && (
                                                <Table
                                                    tableHeaderColor="primary"
                                                    tableType="booking"
                                                    tableHead={[
                                                        "Titre",
                                                        "IP / Domaine",
                                                        "Port",
                                                        "Utilisateur",
                                                        "Mot de passe",
                                                        "Emplacement",
                                                        "",
                                                    ]}
                                                    tableData={
                                                        twoNList &&
                                                        twoNList.map((twoN, i) => {
                                                            const TwoNList = {
                                                                label: twoN?.label,
                                                                host: twoN?.host,
                                                                port: twoN?.port,
                                                                user: twoN?.username,
                                                                password: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex-end",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                const isChromium = !!window.chrome;
                                                                                const copyToClipboard = (text) => {
                                                                                    navigator.clipboard.writeText(text).then(() => {
                                                                                        console.log("Copied to Clipboard Succefully");
                                                                                    }, () => {
                                                                                        console.error("Clipboard Copy Failed");
                                                                                    });
                                                                                }
                                                                                if (isChromium){
                                                                                    navigator.permissions.query({name: "clipboard-write"}).then((result) => {
                                                                                      if (result.state === "granted" || result.state === "prompt") {
                                                                                          copyToClipboard(twoN.password)
                                                                                      }else{
                                                                                          console.warn("User did not authorized clipboard copy");
                                                                                      }
                                                                                    });
                                                                                }else{
                                                                                    copyToClipboard(twoN.password)
                                                                                }
                                                                                    
                                                                            }}
                                                                            color="primary"
                                                                        >Copier</Button>
                                                                    </div>
                                                                ),
                                                                location: twoN?.location?.title,
                                                                actions: (
                                                                    <div
                                                                        style={{
                                                                            display: "flex-end",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            target="_blank"
                                                                            href={`https://${twoN.host}:${twoN.port}`}
                                                                            color="primary"
                                                                        >
                                                                            Accéder
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            onClick={() => toggleModal2n(twoN)}
                                                                            size="sm"
                                                                            color="warning"
                                                                        >
                                                                            Éditer
                                                                        </Button>
                                                                        <Button
                                                                            style={{marginLeft: "2%"}}
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                toggleConfirmBookingDestroy("2n", twoN)
                                                                            }
                                                                            color="danger"
                                                                        >
                                                                            Supprimer
                                                                        </Button>
                                                                    </div>
                                                                ),
                                                            };
                                                            return Object.values(TwoNList);
                                                        })
                                                    }
                                                />
                                            )}
                                            {twoNCount > 0 && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        paddingTop: 100,
                                                    }}
                                                >
                                                    <Pagination
                                                        count={Math.ceil(twoNCount / limits["2n"])}
                                                        page={pageTwoN}
                                                        onChange={(event, pageNumber) => {
                                                            handlePagination2n(pageNumber);
                                                        }}
                                                        classes={{ul: paginationClasses.ul}}
                                                        size="large"
                                                    />
                                                </div>
                                            )}
                                        </GridItem>
                                    </GridItem>
                                ),
                            },
                        ]}
                    />
                </GridContainer>
            </div>
        );
    } else {
        return (
            <div className={classes.circularProgress}>
                <CircularProgress/>
            </div>
        );
    }
}
