import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
// import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

import {
  newsPostServices,
  newsCategoryServices,
  featureServices,
  moduleServices,
  linkFileServices,
} from "../../../services";

import FileType from 'file-type/browser'
import { saveAs } from 'file-saver'
import { useDropzone } from "react-dropzone";

const NewsLogic = () => {

  const { myself } = useSelector(userState => userState.myself);

  const limitNews = 10

  // React State
  const [newsList, setNewsList] = useState(undefined);
  const [page, setPage] = useState(1)
  const [newsCount, setNewsCount] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)

  const [newsCategoryList, setNewsCategoryList] = useState(undefined);
  const [newsCategory, setNewsCategory] = useState(undefined);
  const [formModulesList, setFormModulesList] = useState(undefined)
  const [featuresList, setFeaturesList] = useState([])

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newsSelected, setNewsSelected] = useState("");
  const [newsTitle, setNewsTitle] = useState("");
  const [newsDestroy, setNewsDestroy] = useState(undefined);
  const [newsUUID, setNewsUUID] = useState(undefined);
  const [newsPublished, setNewsPublished] = useState(undefined)

  const [linkDialog, setLinkDialog] = useState(false)
  const [linkInfo, setLinkInfo] = useState(undefined)

  const [pictureDialog, setPictureDialog] = useState(false)
  const [pictureInfo, setPictureInfo] = useState(undefined)
  const [file, setFile] = useState(undefined);
  const [filesAssociated, setFilesAssociated] = useState([])
  const [formFileList, setFormFileList] = useState(undefined)
  const [autoCompleteKey, setAutoCommpleteKey] = useState(0)

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  useEffect(() => {
    (async () => {
      const news = await newsPostServices.getAll(page, limitNews);
      if (news) {
        // console.log(news)
        setNewsList(news?.rows);
        setNewsCount(news?.count)
      }
    })();
  }, [refresh, page]);

  useEffect(() => {
    (async () => {
      const categories = await newsCategoryServices.getAll();
      if (categories) setNewsCategoryList(categories);
    })();
  }, [refresh]);

  const handlePagination = page => {
    setPage(page);
  };

  const refreshData = () => setRefresh(!refresh)

  const handleNewsCategory = category => setNewsCategory(category);
  const handleNewsFeatures = feature => setFeaturesList(feature);
  const toggleModal = (_) => setEditModalOpened(_);
  const toggleConfirmNewDestroy = news => setNewsDestroy(news);
  const toggleNewsTitle = title => {
    setNewsTitle(title)
  };
  const toggleNewsPublished = published => {
    setNewsPublished(published)
  };

  const removeNews = async uuid => {
    const response = await newsPostServices.destroy(uuid);
    if (response) {
      openNotification(`L'article ${newsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
      refreshData()
      setNewsDestroy(undefined);
    }
  };

  const toggleModalNewsType = async (news) => {
    const module = await moduleServices.getAll();
    const feature = await featureServices.getAll()
    if (module && feature) {
      const mod = module.find(it => it.identifier === "news")?.id
      if (mod)
        setFormModulesList(feature.filter(it => it.module_id === mod))
    }
    setFilesAssociated(news?.files ? news?.files : [])
    const files = await linkFileServices.getAll()
    if (files) {
      files.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1)
      setFormFileList(news?.files ? files.filter(file => !news.files.find(it => it.uuid === file.uuid)) : files)
    }
    if (news) {
      setNewsSelected(news?.content)
      // console.log(news?.content)
      // const contentHTML = convertFromHTML(news?.content);
      // const contentState = ContentState.createFromBlockArray(
      //   contentHTML.contentBlocks,
      //   contentHTML.entityMap
      // );
      // const content = JSON.stringify(convertToRaw(contentState));
      // setNewsSelected(
      //   content.replace('"data":{"src":', '"data":{"url":') || ""
      // );
    } else setNewsSelected("");
    if (news?.features)
      setFeaturesList(news?.features.map(it => it.uuid))
    setNewsUUID(news?.uuid);
    setNewsTitle(news?.title || "");
    setNewsPublished(news ? news?.published : true)
    setNewsCategory(news?.category);
    setEditModalOpened(true);
  };

  const submitUpdateHandler = async () => {
    setLoading(true)
    // const raw = document.getElementsByClassName("public-DraftEditor-content");
    const raw = document.querySelector(".note-editable");
    // const body = Object.values(raw)
    //   ?.map(b => b?.innerHTML)
    //   .join("");
    if (!newsTitle?.length || !raw?.innerHTML?.length) {
      openNotification("Veuillez compléter le titre et le contenu de l'actualité avant de l'enregister", "danger")
    } else {
      var response = null
      if (newsUUID) {
        const features = newsList.find(it => it.uuid === newsUUID).features
        features
          .filter(feature => !featuresList
            .find(it => it === feature.uuid))
          .forEach(async it => await newsPostServices.detachFeature(
            newsUUID, it.uuid))
        featuresList
          .filter(it => !features
            .find(feature => feature.uuid === it))
          .forEach(async it => await newsPostServices.attachFeature(
            newsUUID, it))
        const files = newsList.find(it => it.uuid === newsUUID).files
        files
          .filter(file => !filesAssociated
            .find(it => it.uuid === file.uuid))
          .forEach(async it => await newsPostServices.detachFile(
            newsUUID, it.uuid))
        filesAssociated
          .filter(it => !files
            .find(file => file.uuid === it.uuid))
          .forEach(async it => await newsPostServices.attachFile(
            newsUUID, it.uuid))
        response = await newsPostServices.update(
          newsUUID,
          newsTitle,
          raw.innerHTML,
          newsPublished,
          newsCategory?.uuid
        )
        if (response) {
          openNotification(`L'article ${newsTitle} a été modifié`, 'success')
        } else {
          openNotification(`Erreur, une erreur est survenu lors de la modification de l'article ${newsTitle}`, 'danger')
        }
      } else {
        response = await newsPostServices.add(newsTitle, raw.innerHTML, newsCategory?.uuid, newsPublished);
        if (response) {
          for (let x of featuresList) {
            await newsPostServices.attachFeature(response.uuid, x)
          }
          for (let x of filesAssociated) {
            await newsPostServices.attachFile(response.uuid, x.uuid)
          }
          openNotification(`L'article ${newsTitle} a été ajouté`, 'success')
        } else {
          openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'article ${newsTitle}`, 'danger')
        }
      }
      if (response) {
        setNewsList([
          ...newsList.filter(n => n.uuid !== newsUUID),
          { ...response, ...{ category: newsCategory, content: raw.innerHTML } },
        ]);
      }
      refreshData()
      setEditModalOpened(false);
    }
    setLoading(false)
  };

  const toggleLinkDialog = () => {
    const sel = window.getSelection ? window.getSelection() : null
    const link = {
      link: '',
      title: '',
      window: true,
      tcp: true,
      range: sel && sel.getRangeAt ? sel.getRangeAt(0) : null,
      document: document?.selection,
    }
    setLinkInfo(link)
    setLinkDialog(!linkDialog)
  }

  function getParents(elem) {
    while (elem?.parentNode && elem?.parentNode?.className !== 'note-editable') {
      elem = elem?.parentNode;
    }
    if (elem?.parentNode?.className === 'note-editable')
      return true
    return false;
  }

  function insertHtml(html, info) {
    var sel, range;
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (info.range && sel.rangeCount && getParents(info.range?.commonAncestorContainer)) {
        range = info.range;
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        var el = document.createElement("div");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(), node, lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      } else {
        const editArea = document.querySelector(".note-editable");
        editArea.innerHTML += html
      }
    } else if (info.document && info.document.type !== "Control") {
      // IE < 9
      info.document.createRange().pasteHTML(html);
    }
    const event = new Event('input', { bubbles: true });
    document.querySelector(".note-editable").dispatchEvent(event);
  }

  const submitLink = (e) => {
    e.preventDefault()
    setLinkDialog(false)
    insertHtml(`<a href=${(linkInfo.tcp && linkInfo.link.search('http://') < 0 &&
      linkInfo.link.search('https://') < 0 ? 'http://' : '') + linkInfo.link} ${linkInfo.window
        ? 'target="_blank"' : ''}>${!linkInfo?.title?.length ? linkInfo.link : linkInfo.title}</a>`, linkInfo)
    // const editArea = document.querySelector(".note-editable");
    // editArea.focus()
  }

  const handleLinkChange = (key, value) => {
    const link = { ...linkInfo }
    link[key] = value
    setLinkInfo(link)
  }

  const togglePictureDialog = (width = null) => {
    const sel = window.getSelection ? window.getSelection() : null
    const picture = {
      url: '',
      width,
      range: sel && sel.getRangeAt ? sel.getRangeAt(0) : null,
      document: document?.selection,
    }
    setPictureInfo(picture)
    setPictureDialog(!pictureDialog)
  }

  const handlePictureChange = (key, value) => {
    const picture = { ...pictureInfo }
    picture[key] = value
    setPictureInfo(picture)
  }

  const submitPicture = (e) => {
    e.preventDefault()
    uploadImg(pictureInfo.url)
  }

  const uploadImg = (img, name = null) => {
    var image = new Image();
    image.src = img;
    setPictureDialog(false)
    image.onload = () => {
      insertHtml(`<img style="width: ${pictureInfo?.width || pictureInfo?.width === 0 ? Math.min(image.width, pictureInfo.width) : image.width}px;" src=${img} ${name ? `data-filename=${name}` : ''}>`, pictureInfo)
      // const editArea = document.querySelector(".note-editable");
      // editArea.focus()
    };
    image.onerror = error => {
      console.log('Error: ', error);
    };
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length !== 1)
      return
    if (pictureDialog) {
      var reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        uploadImg(reader.result, acceptedFiles[0].name)
      };
      reader.onerror = error => {
        console.log('Error: ', error);
      };
    } else {
      setFile(acceptedFiles[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureInfo, pictureDialog]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    type: "file",
    accept: "image/*",
    onDrop,
    maxFiles: 1,
  });

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const uploadfile = async () => {
    const fileList = await linkFileServices.getAll()
    if (fileList.find(f => f.name === file?.name)) {
      openNotification('Erreur, le fichier existe déjà', 'danger')
      return
    }
    let data = new FormData();
    data.append('file', file)
    const f = await linkFileServices.add(data, file?.name)
    setFilesAssociated(oldArray => [...oldArray, f])
    setFile(null)
    refreshData()
  }

  const dropzone = useDropzone({
    type: "file",
    onDrop,
    maxFiles: 1,
  });

  const handleFormFileChange = (key, value) => {
    let files = filesAssociated.slice(0)
    let filesList = formFileList.slice(0)
    if (key === 'add') {
      filesList = filesList.filter(file => file.uuid !== value.uuid)
      files.push(value)
      files.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1)
      setAutoCommpleteKey(autoCompleteKey + 1)
    } else if (key === 'delete') {
      files = files.filter((file) => file.uuid !== value.uuid)
      filesList.push(value)
      filesList.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1)
    }
    setFilesAssociated(files)
    setFormFileList(filesList)
  }

  const downloadFile = async (file) => {
    const res = await linkFileServices.download(file.uuid)
    const blob = new Blob([res])
    const type = await FileType.fromBlob(blob)
    saveAs(blob, file.name.endsWith("." + type.ext) ? file.name : file.name + "." + type.ext)
  }

  return {
    autoCompleteKey,
    dropzone,
    editModalOpened,
    file,
    filesAssociated,
    formFileList,
    isDragActive,
    isDragAccept,
    isDragReject,
    featuresList,
    formModulesList,
    limitNews,
    linkInfo,
    linkDialog,
    loading,
    myself,
    newsDestroy,
    newsList,
    newsCategory,
    newsCategoryList,
    newsCount,
    newsPublished,
    newsSelected,
    newsTitle,
    notifColor,
    notifMessage,
    openNotif,
    page,
    pictureDialog,
    pictureInfo,
    downloadFile,
    getRootProps,
    getInputProps,
    handleFormFileChange,
    handleLinkChange,
    handleNewsCategory,
    handleNewsFeatures,
    handlePagination,
    handlePictureChange,
    refreshData,
    removeNews,
    setOpenNotif,
    submitLink,
    submitPicture,
    submitUpdateHandler,
    toggleConfirmNewDestroy,
    toggleLinkDialog,
    toggleModal,
    toggleNewsPublished,
    toggleModalNewsType,
    toggleNewsTitle,
    togglePictureDialog,
    uploadfile,
  };
};

export default NewsLogic;
