import { useEffect, useState, useCallback } from "react";

import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

import {
  sectorServices,
  groupServices
} from "../../services";

const GroupsLogic = () => {

  const limitGroups = 20

  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [groupsList, setGroupsList] = useState(undefined)
  const [groupsCount, setGroupsCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newGroupsInfo, setNewGroupsInfo] = useState(undefined);
  const [formAttributesList, setFormAttributesList] = useState(undefined)
  const [modalType, setModalType] = useState(false);

  const [errorUpload, setErrorUpload] = useState([]);
  const [uploadGroupStart, setUploadGroupStart] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [file, setFile] = useState(undefined);
  const [fileData, setFileData] = useState([]);
  const [errorFileData, setErrorFileData] = useState(false);
  const [progress, setProgress] = useState(0)
  const [fileTemplate] = useState([
    {
      key: "group",
      title: "Numéro de lot",
      include: ["lot"],
      exclude: [],
      allowNull: false,
    },
    {
      key: "sector",
      title: "Nom du bâtiment",
      include: ["batiment"],
      exclude: [],
      allowNull: false,
    },
  ]);

  const [groupDestroy, setGroupDestroy] = useState(undefined);

  const toggleConfirmGroupDestroy = group => setGroupDestroy(group);

  useEffect(() => {
    (async () => {
      const attributes = await sectorServices.getAll()
      if (attributes) {
        setFormAttributesList(attributes)
      }
      const group = await groupServices.getAllByEstate()
      if (group) {
        setGroupsCount(group.length)
        setGroupsList(group.slice(limitGroups * (page - 1), limitGroups * (page - 1) + limitGroups))
      }
    })();
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deleteGroup = async uuid => {
    await groupServices.destroy(uuid)
    openNotification(`L'appartement ${groupsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmGroupDestroy(null)
    refreshData()
  };

  const toggleModal = async (group = null) => {
    setModalType(group ? true : false);
    const groupInfo = {
      uuid: group?.uuid,
      title: group?.title ? group?.title : '',
      attribute: group?.sector?.uuid ? group?.sector?.uuid : ''
    };
    setNewGroupsInfo(groupInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value) => {
    let groupInfo = { ...newGroupsInfo };
    groupInfo[key] = value;
    setNewGroupsInfo(groupInfo);
  };

  const submitNewGroup = async event => {
    event.preventDefault();
    if (newGroupsInfo.uuid) {
      if (await groupServices.update(newGroupsInfo.uuid, newGroupsInfo.title, newGroupsInfo.attribute)) {
        openNotification(`L'appartement ${newGroupsInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification de l'appartement ${newGroupsInfo?.title}`, 'danger')
      }
    } else {
      if (await groupServices.add(newGroupsInfo.title, newGroupsInfo.attribute)) {
        openNotification(`L'appartement ${newGroupsInfo?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'appartement ${newGroupsInfo?.title}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    refreshData();
  };

  const uploadGroup = async () => {
    setProgress(0)
    setErrorUpload([]);
    setUploadGroupStart(true);
    let attributesList = formAttributesList.slice(0)
    let errorTotal = 0;
    let groupUpload = 0;
    let groups = []
    for (let line of fileData) {
      setProgress((fileData.length / line.line) * 100)
      let error = 0;
      if (line.data?.group !== null && (groupsList.find(it => it.title === line.data.group) ||
        groups.find(it => it === line.data.group))) {
        error += 1;
        setErrorUpload(oldArray => [
          ...oldArray,
          `Ligne ${line.line}: ${fileTemplate.find(it => it.key === "group").title
          }: Ce nom existe déjà`,
        ]);
      }
      if (line.data?.sector !== null && !attributesList.find(it => it.name === line.data.sector)) {
        const res = await sectorServices.add(line.data.sector);
        attributesList.push(res)
      }
      if (error) {
        errorTotal += error;
        continue;
      }
      groups.push(line.data?.group)
      const group = await groupServices.add(
        line.data?.group,
        attributesList.find(it => it.name === line.data?.sector)?.uuid
      );
      if (!group) {
        errorTotal += 1;
        setErrorUpload(oldArray => [
          ...oldArray,
          `Ligne ${line.line}: Une erreur est survenu pendant la création de l'utilisateur, vérifier les données ou ressayer ultérieurement`,
        ]);
        continue;
      }
      groupUpload += 1;
    }
    setProgress(100)
    setRefresh(!refresh);
    const groupPlural = groupUpload > 1 ? "s" : "";
    const errorPlural = errorTotal > 1 ? "s" : "";
    openNotification(
      `La création des utilisateurs est terminée: ${groupUpload} utilisateur${groupPlural} créé${groupPlural}, ${errorTotal} erreur${errorPlural} détectée${errorPlural}`,
      "info",
      15
    );
    setUploadGroupStart(false);
  };

  const generateHeaderInfo = (line = 1) => {
    let header = { line };
    fileTemplate.map(it => (header[it.key] = null));
    return header;
  };

  const validateHeaderInfo = headerInfo => {
    return fileTemplate.find(it => headerInfo[it.key] === null) === undefined;
  };

  const parseFileKey = (cell, headerInfo, col) => {
    for (let box of fileTemplate) {
      if (
        !headerInfo[box.key] &&
        box.include
          .map(it => cell.toLowerCase().search(it) < 0)
          .reduce((a, b) => a + b, 0) === 0 &&
        box.exclude
          .map(it => cell.toLowerCase().search(it) >= 0)
          .reduce((a, b) => a + b, 0) === 0
      ) {
        headerInfo[box.key] = col;
        return headerInfo;
      }
    }
    return headerInfo;
  };

  const fillFileData = (v, data, line, key) => {
    if (!data.find(it => it.line === line)) {
      let l = { line, data: {} };
      fileTemplate.map(it => (l.data[it.key] = null));
      data.push(l);
    }
    data.find(it => it.line === line).data[key] = v.toString();
    return data;
  };

  const parseFileData = (f, headerInfo) => {
    let data = [];
    const keys = Object.keys(f).filter(it => it[0] !== "!");
    const keysHeader = fileTemplate.map(it => it.key);
    keys.forEach(c => {
      const line = c.match(/^\d+|\d+\b|\d+(?=\w)/g)[0];
      const col = c.replace(/[0-9]/g, "");
      if (parseInt(line) <= parseInt(headerInfo.line)) return;
      const key = keysHeader.find(it => headerInfo[it] === col);
      if (key) {
        data = fillFileData(f[c].v, data, line, key);
      }
    });
    setFileData(data);
    setFileUpload(true);
  };

  const parseFile = f => {
    let headerInfo = generateHeaderInfo();
    let line = 1;
    let col = "A";
    const keys = Object.keys(f).filter(it => it[0] !== "!");
    for (let c of keys) {
      line = c.match(/^\d+|\d+\b|\d+(?=\w)/g)[0];
      col = c.replace(/[0-9]/g, "");
      if (line !== headerInfo.line) headerInfo = generateHeaderInfo(line);
      headerInfo = parseFileKey(
        f[c].v
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
        headerInfo,
        col
      );
      if (validateHeaderInfo(headerInfo)) {
        parseFileData(f, headerInfo);
        return;
      }
    }
    openNotification(
      "Erreur lors de la lecture du fichier. Vérifiez que le titre des colonnes comprend ces champs: " +
      fileTemplate.map(it => it.title).join(", "),
      "danger",
      15
    );
  };

  const readFile = () => {
    setErrorUpload([]);
    setUploadGroupStart(false);
    setFileUpload(false);
    setErrorFileData(false);
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => {
      console.log("file reading has failed");
      openNotification(
        "La lecture du fichier a échoué ! Vérifiez que le fichier existe ou ressayer de le sélectionner",
        "danger",
        15
      );
    };
    reader.onload = evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      parseFile(ws);
    };
    reader.readAsBinaryString(file);
  };

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) setFile(acceptedFiles[0]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    type: "file",
    accept:
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, text/csv",
    onDrop,
    maxFiles: 1,
  });

  return {
    editModalOpened,
    errorFileData,
    errorUpload,
    file,
    fileData,
    fileTemplate,
    fileUpload,
    isDragActive,
    isDragAccept,
    isDragReject,
    formAttributesList,
    groupDestroy,
    groupsCount,
    groupsList,
    limitGroups,
    modalType,
    newGroupsInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    progress,
    uploadGroupStart,
    deleteGroup,
    getRootProps,
    getInputProps,
    handleFormChange,
    handlePagination,
    readFile,
    refreshData,
    setErrorFileData,
    setOpenNotif,
    submitNewGroup,
    toggleConfirmGroupDestroy,
    toggleModal,
    uploadGroup,
  }
}

export default GroupsLogic