/* eslint-disable react/no-children-prop */
import React, { useEffect } from "react";
import BookingsLogic from "./BookingsLogic";

import moment from "moment";
import localization from "moment/locale/fr";

// @material-ui/core components
import { Backdrop, Button as MUIButton, CircularProgress, Divider, Modal, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Other front packages
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import Tabs from "components/CustomTabs/CustomTabs.js";

// core components
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import ConfirmModal from "components/ConfirmModal";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardBody from "components/Card/CardBody.js";
import FormRules from "components/FormUsers/FormRules.js";
import CardFooter from "components/Card/CardFooter.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

import AssignmentIcon from "@material-ui/icons/Assignment";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ViewListIcon from "@material-ui/icons/ViewList";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function Bookings(props) {
  const textClasses = textStyles();
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    bookingDestroy,
    bookingsList,
    endDate,
    formRuleValid,
    limits,
    modalRuleType,
    myself,
    newRuleInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    pageRule,
    pageSubscription,
    pageModalRule,
    query,
    ressource,
    ressources,
    ruleModalOpened,
    rules,
    rulesCount,
    startDate,
    statusSub,
    statusBookings,
    subscriptions,
    subscriptionsCount,
    subscriptionStatus,
    bookingStatus,
    deleteRule,
    handlePagination,
    handlePaginationRule,
    handlePaginationSubscription,
    handleRuleFormChange,
    refreshData,
    setBookingState,
    setOpenNotif,
    handlePaginationModalRule,
    setEndDate,
    setStartDate,
    submitNewRule,
    toggleQuery,
    toggleModalRule,
    toggleConfirmBookingDestroy,
    toggleSelectedRessource,
    updateSubscriptionStatusFilter,
    updateBookingStatusFilter,
  } = BookingsLogic();

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (bookingsList) {
    return (
      <div>
        <Snackbar
          place="tr"
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === "success" ? CheckCircleIcon : (notifColor === "danger" ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {bookingDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmBookingDestroy(null)}
            open={bookingDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmBookingDestroy(null)}
                kind={`Supprimer la règle "${bookingDestroy.title}" ?`}
                makeAction={() => deleteRule(bookingDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {ruleModalOpened && newRuleInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModalRule()}
            open={ruleModalOpened}
          >
            <div className={classes.paper} style={{
              width: "80%",
              maxHeight: "80%",
              maxWidth: "80%",
            }}>
              <FormRules
                formRuleValid={formRuleValid}
                newRuleInfo={newRuleInfo}
                modalRuleType={modalRuleType}
                pageModalRule={pageModalRule}
                handleRuleFormChange={handleRuleFormChange}
                handlePaginationModalRule={handlePaginationModalRule}
                submitNewRule={submitNewRule}
                toggleModalRule={toggleModalRule}
              />
            </div>
          </Modal>
        )
        }
        {
          myself && (
            <GridContainer>
              <Tabs
                tabs={[
                  {
                    // tabWidth: 'auto',
                    tabName: "Réservations",
                    tabIcon: AssignmentIcon,
                    tabOnClick: () => setBookingState("booking"),
                    tabContent: (
                      <GridItem xs={12} sm={12} md={12}>
                        {!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_read ? (
                          <GridItem xs={12} sm={12} md={6}>
                            <h4>
                              {
                                "Vous n'avez pas la permission de voir les réservations"
                              }
                            </h4>
                          </GridItem>
                        ) : (
                          <div>
                            <Card
                              style={{
                                boxShadow: "none",
                                marginTop: 0,
                                paddingTop: 0,
                              }}>
                              <CardHeader
                                style={{
                                  borderRadius: 1,
                                  paddingTop: 0,
                                  border: "none",
                                  boxShadow:
                                    "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                                }}
                              >
                                <GridContainer>
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <GridItem xs={12} sm={12} md={3}>
                                      <DatePicker
                                        autoOk
                                        label="Date de début"
                                        labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
                                        value={startDate}
                                        onChange={date => {
                                          date >= endDate && setEndDate(moment(date).add(1, "months"));
                                          setStartDate(date);
                                        }}
                                        InputProps={{ className: classes.input }}
                                        InputLabelProps={{
                                          classes: {
                                            root: textClasses.inputLabelRoot,
                                          },
                                        }}
                                        classes={{ root: textClasses.formControlRoot }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                      <DatePicker
                                        autoOk
                                        label="Date de fin"
                                        labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
                                        value={endDate}
                                        onChange={date => {
                                          date <= startDate && setStartDate(moment(date).subtract(1, "months"));
                                          setEndDate(date);
                                        }}
                                        InputProps={{ className: classes.input }}
                                        InputLabelProps={{
                                          classes: {
                                            root: textClasses.inputLabelRoot,
                                          },
                                        }}
                                        classes={{ root: textClasses.formControlRoot }}
                                      />
                                    </GridItem>
                                  </MuiPickersUtilsProvider>
                                  <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      classes={{
                                        root: textClasses.formControlRoot,
                                      }}
                                      label="Rechercher par mot clé"
                                      onChange={ev => {
                                        //if (ev.key === "Enter") {
                                          ev.preventDefault();
                                          refreshData();
                                        //}
                                      }}
                                      inputProps={{
                                        // placeholder: "Rechercher par mot clé",
                                        className: classes.input,
                                        onChange: event => {
                                          toggleQuery(event.target.value);
                                        },
                                      }}
                                      InputLabelProps={{
                                        classes: {
                                          root: textClasses.inputLabelRoot,
                                        },
                                      }}
                                      value={query}
                                    />
                                  </GridItem>
                                </GridContainer>
                                <GridContainer>
                                  {ressources && (<GridItem xs={12} sm={12} md={6}>
                                    <Autocomplete
                                      multiple
                                      id="tags-groups"
                                      options={ressources}
                                      getOptionLabel={option => option.title}
                                      onChange={(_, value) => {
                                        toggleSelectedRessource(value);
                                      }}
                                      value={ressource}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          classes={{
                                            root: textClasses.formControlRoot,
                                          }}
                                          label="Filtrer par ressource"
                                          placeholder="ressources sélectionnées"
                                          InputProps={{
                                            ...params.InputProps,
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            classes: {
                                              root: textClasses.inputLabelRoot,
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </GridItem>)
                                  }
                                  <GridItem xs={12} sm={12} md={6}>
                                    <Autocomplete
                                      multiple
                                      id="tags-booking-status"
                                      options={statusBookings}
                                      getOptionSelected={(option, value) => option.id === value.id}
                                      getOptionLabel={option => option.title}
                                      onChange={(_, value) => {
                                        updateBookingStatusFilter(value);
                                      }}
                                      value={bookingStatus}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          classes={{
                                            root: textClasses.formControlRoot,
                                          }}
                                          label="Filtrer par statut"
                                          placeholder="statut sélectionnés"
                                          InputProps={{
                                            ...params.InputProps,
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            classes: {
                                              root: textClasses.inputLabelRoot,
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </GridItem>
                                </GridContainer>
                              </CardHeader>
                            </Card>
                            <CardBody>
                              {bookingsList.data.rows.length > 0 ? (
                                <Table
                                  tableHeaderColor="primary"
                                  tableHead={[
                                    "ID",
                                    "Statut",
                                    "Ressource",
                                    "Date",
                                    "Début",
                                    "Fin",
                                    "Prénom",
                                    "Nom",
                                    "Email",
                                    "Télephone",
                                    "Code",
                                    "Montant",
                                    "Transaction",
                                  ]}
                                  tableData={bookingsList.data.rows.map(booking => {
                                    const bookingList = {
                                      id: booking?.id,
                                      status:
                                        booking?.status === "booked"
                                          ? "Reservé"
                                          : booking.status === "canceled"
                                            ? "Annulé"
                                            : booking.status === "initiated"
                                              ? "Initié"
                                              : "",
                                      ressource:
                                        booking.ressource !== null
                                          ? booking.ressource.title
                                          : "Non indiqué",
                                      date: moment(booking.created_at).format(
                                        "DD MMMM YYYY [à] HH[h]mm",
                                      ),
                                      begin: moment(booking.begin_at).format(
                                        "DD MMMM YYYY [à] HH[h]mm",
                                      ),
                                      end: moment(booking.end_at).format(
                                        "DD MMMM YYYY [à] HH[h]mm",
                                      ),
                                      firstName: booking.user_first_name,
                                      lastName: booking.user_last_name,
                                      email: booking.user_email,
                                      phoneNumber: booking.sms_number,
                                      code: booking.access_code,
                                      price:
                                        booking.payflow_out != null
                                          ? `${booking.payflow_out.amount / 100.0} €`
                                          : 0,
                                      state:
                                        booking?.payflow_out != null &&
                                        booking?.payflow_out?.status === "SUCCEEDED"
                                          ? "Confirmé"
                                          : booking?.payflow_out?.status === "CREATED"
                                            ? "En attente"
                                            : booking?.payflow_out?.status === "FAILED"
                                              ? "Échec"
                                              : "Aucune",
                                    };
                                    return Object.values(bookingList);
                                  })}
                                />
                              ) : (
                                <h4>Aucune réservation à afficher</h4>
                              )}
                            </CardBody>
                            <CardFooter
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              {bookingsList.data.rows.length > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: 100,
                                  }}
                                >
                                  <Pagination
                                    count={bookingsList.data.meta.pageCount}
                                    page={page}
                                    onChange={(event, pageNumber) => {
                                      // if(!searchEnabled || !groupsFilter || !rolesFilter || !sectorsFilter)
                                      handlePagination(pageNumber);
                                    }}
                                    classes={{ ul: paginationClasses.ul }}
                                    size="large"
                                  />
                                </div>
                              )}
                            </CardFooter>
                          </div>
                        )}
                      </GridItem>),
                  },
                  {
                    // tabWidth: 'auto',
                    tabName: "Abonnements",
                    tabIcon: PostAddIcon,
                    tabOnClick: () => setBookingState("sub"),
                    tabContent: (
                      <div>
                        {!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_subscriptions_read ? (
                          <GridItem xs={12} sm={12} md={6}>
                            <h4>
                              {
                                "Vous n'avez pas la permission de voir les abonnements"
                              }
                            </h4>
                          </GridItem>
                        ) : (<div>
                          <Card
                            style={{
                              boxShadow: "none",
                              marginTop: 0,
                              paddingTop: 0,
                            }}>
                            <CardHeader
                              style={{
                                borderRadius: 1,
                                paddingTop: 0,
                                border: "none",
                                boxShadow:
                                  "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                              }}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                  <Autocomplete
                                    multiple
                                    id="tags-groups"
                                    options={statusSub}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.title}
                                    onChange={(_, value) => {
                                      updateSubscriptionStatusFilter(value);
                                    }}
                                    value={subscriptionStatus}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        classes={{
                                          root: textClasses.formControlRoot,
                                        }}
                                        label="Filtrer par statut"
                                        placeholder="statut sélectionnés"
                                        InputProps={{
                                          ...params.InputProps,
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: textClasses.inputLabelRoot,
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </GridItem>
                              </GridContainer>
                            </CardHeader>
                          </Card>
                          <GridItem xs={12} sm={12} md={12}>
                            {subscriptions && subscriptions.length === 0 && (
                              <h4>
                                Aucun abonnement à afficher
                              </h4>
                            )}
                            {subscriptions && subscriptions.length > 0 && (<Table
                              tableHeaderColor="primary"
                              tableType="booking"
                              tableHead={[
                                "Ressource",
                                "Prénom",
                                "Nom",
                                "Email",
                                "Periode",
                                "Fin le",
                                "Montant",
                                "Statut",
                                "Créé le"
                              ]}
                              tableData={subscriptions &&
                                subscriptions.map(sub => {
                                  let periodType = "";
                                  if (sub?.period?.period_type == "MONTH"){
                                    periodType = "mois";
                                  }else if(sub?.period?.period_type == "YEAR"){
                                    periodType = "ans";
                                  }else if(sub?.period?.period_type == "WEEK"){
                                    periodType = "semaines";
                                  }
                                  const amountt = sub?.payflow_out?.amount;
                                  const period = `${sub?.period?.period_number} ${periodType}`;
                                  const subList = {
                                    ressource: sub?.ressource_title,
                                    firstName: sub?.user?.first_name,
                                    lastName: sub?.user?.last_name,
                                    email: sub?.user?.email,
                                    period: period,
                                    end: (sub?.end_date ? moment(sub.end_date).format("DD/MM/YYYY") : ""),
                                    amount : amountt ? `${amountt/100} €` : "---",
                                    status: (sub?.status ? statusSub.find(it => it.key === sub.status).title : ""),
                                    date: (sub?.created_at ? moment(sub.created_at).format("DD/MM/YYYY") : "")
                                  };
                                  return Object.values(subList);
                                })
                              }
                            />)
                            }
                            {subscriptionsCount > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: 100,
                                }}
                              >
                                <Pagination
                                  count={Math.ceil(subscriptionsCount / limits.subscription)}
                                  page={pageSubscription}
                                  onChange={(event, pageNumber) => {
                                    handlePaginationSubscription(pageNumber);
                                  }}
                                  classes={{ ul: paginationClasses.ul }}
                                  size="large"
                                />
                              </div>
                            )}
                          </GridItem>
                        </div>)
                        }
                      </div>
                    ),
                  },
                  {
                    // tabWidth: 140,
                    tabName: "Règles",
                    tabIcon: ViewListIcon,
                    tabOnClick: () => setBookingState("rule"),
                    tabContent: (
                      <GridItem xs={12} sm={12} md={12}>
                        {(myself?.rights?.role_superadmin || myself?.rights?.role_bookings_rules_add) && (
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <MUIButton
                                  className={classes.button}
                                  onClick={() => toggleModalRule()}
                                  startIcon={<AddIcon />}
                                  style={{ width: "auto" }}
                                >
                                  Ajouter une nouvelle règle
                                </MUIButton>
                              </GridItem>
                            </GridContainer>
                          </CardBody>)
                        }
                        <div>
                          {!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_rules_read ? (
                            <GridItem xs={12} sm={12} md={6}>
                              <h4>
                                {
                                  "Vous n'avez pas la permission de voir les règles"
                                }
                              </h4>
                            </GridItem>
                          ) : (<div>
                            {rules && rules.length === 0 && (
                              <h4>
                                Aucune règle à afficher
                              </h4>
                            )}
                            {rules &&
                              rules?.map((rule, i) => (
                                <div key={i}>
                                  <Card>
                                    {rule?.ressources[0]?.title && (
                                      <div>
                                        <CardHeader>
                                          <div style={{ marginLeft: "1%", fontWeight: "bold", fontSize: 16 }}>
                                            {rule?.ressources[0]?.title}
                                          </div>
                                        </CardHeader>
                                        <Divider />
                                      </div>
                                    )
                                    }
                                    <CardBody>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <h1 style={{ fontWeight: "bold" }}>
                                          {rule?.title}
                                        </h1>
                                        <Divider />
                                        {rule.content.split("\n").map((it, i) => (
                                          (it.length ? <ReactMarkdown key={i} remarkPlugins={[gfm]}
                                                                      children={it.replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")} /> :
                                              <div key={i} style={{ whiteSpace: "pre-wrap" }}>{"\r\n"}</div>
                                          )),
                                        )}
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "1%" }}>
                                        {(myself?.rights?.role_superadmin || myself?.rights?.role_bookings_rules_edit) && (
                                          <Button
                                            onClick={() => toggleModalRule(rule)}
                                            color="warning"
                                          >Éditer</Button>)
                                        }
                                        {(myself?.rights?.role_superadmin || myself?.rights?.role_bookings_rules_delete) && (
                                          <Button
                                            style={{ marginLeft: "2%" }}
                                            onClick={() => toggleConfirmBookingDestroy(rule)}
                                            color="danger"
                                          >Supprimer</Button>)
                                        }
                                      </GridItem>
                                    </CardBody>
                                    <Divider />
                                    <CardFooter>
                                      Crée le{" "}{moment(rule.created_at).format("DD/MM/YYYY à HH:mm")}
                                    </CardFooter>
                                  </Card>
                                </div>
                              ))
                            }
                            {rulesCount > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: 100,
                                }}
                              >
                                <Pagination
                                  count={Math.ceil(rulesCount / limits.rule)}
                                  page={pageRule}
                                  onChange={(event, pageNumber) => {
                                    handlePaginationRule(pageNumber);
                                  }}
                                  classes={{ ul: paginationClasses.ul }}
                                  size="large"
                                />
                              </div>
                            )}
                          </div>)
                          }
                        </div>
                      </GridItem>
                    ),
                  },
                ]}
              />
            </GridContainer>
          )
        }
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}