import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  incidentCategoryServices,
  incidentDeclarationServices,
  incidentReportServices,
} from "../../../services";

const IncidentsLogic = () => {
  const { myself } = useSelector(userState => userState.myself);

  const limitIncidents = 5;
  const status = [
    { id: 1, title: "Ouvert", key: "opened" },
    { id: 2, title: "En cours", key: "ongoing" },
    { id: 3, title: "Terminé", key: "closed" },
  ];

  const [incidentsCategoryList, setIncidentsCategoryList] = useState(undefined);
  const [allCategoryList, setAllCategoryList] = useState(undefined);
  const [incidentsList, setIncidentsList] = useState(undefined);
  const [incidentsCount, setIncidentsCount] = useState(undefined);
  const [incidentsNewReports, setIncidentsNewReports] = useState([]);
  const [statusFilter, setStatusFilter] = useState(
    status.filter(it => it.key === "opened" || it.key === "ongoing")
  );
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [page, setPage] = useState(1);

  const [openComment, setOpenComment] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [incidentDestroy, setIncidentDestroy] = useState(undefined);

  const toggleConfirmIncidentDestroy = incident => setIncidentDestroy(incident);

  const handleClickComment = uuid => {
    setIncidentsList(
      incidentsList.map(incident =>
        incident.uuid === uuid ? { ...incident, show: !incident.show } : incident
      )
    );
  };

  const getAllDeclaration = async (page, limit, status, category) => {
    return await incidentDeclarationServices.getAllBySite(
      page,
      limit,
      status,
      category
    );
  };
  console.log(incidentsList);
  useEffect(() => {
    let transformedDeclarations = [];
    (async () => {
      const declarations = await getAllDeclaration(
        page,
        limitIncidents,
        statusFilter.map(status => `&status=${status.key}`),
        categoryFilter.map(category => `&category=${category.id}`)
      );

      if (declarations?.rows?.length) {
        transformedDeclarations.rows = declarations?.rows?.map(declaration => ({
          ...declaration,
          show: false,
          pictureCommentURL: `http://127.0.0.1:2900/app/module/incident/declarations/${declaration.uuid}`,
          pictureComment: {},
        }));
        transformedDeclarations.count = declarations.count;
      }
      console.log("transformed declaration => ", transformedDeclarations);
      const categories = await incidentCategoryServices.getAll();
      const allCategories = await incidentCategoryServices.fetchForSettings();
      if (transformedDeclarations) {
        setIncidentsList(transformedDeclarations?.rows);
        setIncidentsCount(transformedDeclarations?.count);
        // console.log(declaration.count, declaration?.rows)
        transformedDeclarations.rows.map(async it => {
          setIncidentsNewReports(oldArray => [
            ...oldArray,
            { id: it.id, text: "" },
          ]);
        });
      }

      if (categories) {
        setIncidentsCategoryList(categories);
      }
      if (allCategories) {
        setAllCategoryList(allCategories);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatusIncident = async (state, incident) => {
    setOpenNotif(false);
    if (await incidentDeclarationServices.setStatus(incident.uuid, state)) {
      const newIncidentsList = incidentsList.slice(0);
      newIncidentsList.find(it => it.id === incident.id).status = state;
      setIncidentsList(newIncidentsList);
      setNotifMessage(
        `Le statut de l'incident a été défini sur: ${
          status.find(it => it.key === state).title
        }`
      );
      setNotifColor("success");
      setOpenNotif(true);
      setTimeout(() => setOpenNotif(false), 6000);
    } else {
      NotificationError("lors de la modification du statut de l'incident");
    }
  };

  const NotificationError = (msg = null) => {
    setOpenNotif(false);
    setNotifMessage(
      `Une erreur est survenu${
        msg ? " " : ""
      }${msg}.\nVeuillez ressayer ultérieurement`
    );
    setNotifColor("danger");
    setTimeout(() => setOpenNotif(false), 6000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const handlePagination = async pageNumber => {
    setPage(pageNumber);
    await refreshDeclaration({ p: pageNumber });
  };

  const onChangeReport = (text, id) => {
    incidentsNewReports.find(report => report.id === id).text = text;
    setIncidentsNewReports([...incidentsNewReports]);
  };

  const refreshDeclaration = async ({
    p = page,
    limit = limitIncidents,
    status = statusFilter,
    category = categoryFilter,
  }) => {
    const declaration = await getAllDeclaration(
      p,
      limit,
      status.map(status => `&status=${status.key}`),
      category.map(category => `&category=${category.id}`)
    );
    if (declaration) {
      setIncidentsList(declaration?.rows);
      setIncidentsCount(declaration?.count);
      declaration.rows.map(async it => {
        if (!incidentsNewReports.find(report => report.id === it.id))
          setIncidentsNewReports(oldArray => [
            ...oldArray,
            { id: it.id, text: "" },
          ]);
      });
    }
    const categories = await incidentCategoryServices.getAll();
    if (categories) {
      setIncidentsCategoryList(categories);
    }
  };

  const sendNewReport = async (id, uuid) => {
    const text = incidentsNewReports.find(report => report.id === id).text;
    if (await incidentReportServices.addOnDeclarationUUID(uuid, text)) {
      openNotification(`Le rapport de l'incident a été envoyé`, "success");
      onChangeReport("", id);
      await refreshDeclaration({});
    } else {
      NotificationError("lors de l'envoie du nouveau rapport d'incident");
    }
  };

  const deleteReport = async uuid => {
    if (await incidentReportServices.removeOnUUID(uuid)) {
      openNotification(`Le rapport de l'incident a été supprimé`, "success");
      toggleConfirmIncidentDestroy(null);
      await refreshDeclaration({});
    } else {
      NotificationError(`lors de la suppression du rapport de l'incident`);
    }
  };

  const updateStatusFilter = async value => {
    setStatusFilter(value);
    await refreshDeclaration({ status: value });
  };

  const updateCategoryFilter = async value => {
    setCategoryFilter(value);
    await refreshDeclaration({ category: value });
  };

  const refreshData = () => refreshDeclaration({});

  return {
    allCategoryList,
    categoryFilter,
    incidentDestroy,
    incidentsCategoryList,
    incidentsList,
    incidentsCount,
    incidentsNewReports,
    notifColor,
    notifMessage,
    limitIncidents,
    myself,
    openComment,
    openNotif,
    page,
    status,
    statusFilter,
    deleteReport,
    handlePagination,
    handleClickComment,
    onChangeReport,
    refreshData,
    sendNewReport,
    setOpenNotif,
    toggleConfirmIncidentDestroy,
    updateCategoryFilter,
    updateStatusIncident,
    updateStatusFilter,
  };
};

export default IncidentsLogic;
