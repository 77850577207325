import React, { useEffect } from "react";
import RfidLogic from "./RfidLogic";

import moment from "moment";
import localization from "moment/locale/fr";

import {
  CircularProgress,
  Modal,
  makeStyles,
  Button as MUIButton,
  Backdrop,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import FormRfid from "components/FormUsers/FormRfid";
import FormRfidTag from "components/FormUsers/FormRfidTag";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import LocalOfferSharpIcon from '@material-ui/icons/LocalOfferSharp';
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

moment.updateLocale("fr", localization);

export default function Rfid(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    rfidDestroy,
    rfidCount,
    rfidList,
    editModalOpened,
    editTagModalOpened,
    formSensorList,
    formRessourceList,
    formValid,
    formValidTag,
    limitRfid,
    limitSensorTag,
    limitTag,
    newRfidInfo,
    newTagInfo,
    notifColor,
    notifMessage,
    modalType,
    modalTypeTag,
    myself,
    openNotif,
    page,
    pageSensorTag,
    pageTag,
    tagsCount,
    tagsList,
    deleteRfid,
    getTimeInterval,
    handleFormChange,
    handleFormChangeTag,
    handlePagination,
    handlePaginationSensorTag,
    handlePaginationTag,
    modalMessageRfid,
    refreshData,
    refreshSensorTag,
    setOpenNotif,
    setRfidState,
    submitNewRfid,
    submitNewTag,
    toggleConfirmRfidDestroy,
    toggleModal,
    toggleModalTag,
  } = RfidLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (rfidList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {rfidDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmRfidDestroy(null)}
            open={rfidDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmRfidDestroy(null)}
                kind={modalMessageRfid()}
                makeAction={() => deleteRfid(rfidDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newRfidInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <FormRfid
                formValid={formValid}
                modalType={modalType}
                newRfidInfo={newRfidInfo}
                handleFormChange={handleFormChange}
                submitNewRfid={submitNewRfid}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        {editTagModalOpened && newTagInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModalTag()}
            open={editTagModalOpened}
          >
            <div className={classes.paper}>
              <FormRfidTag
                formSensorList={formSensorList}
                formRessourceList={formRessourceList}
                formValidTag={formValidTag}
                modalTypeTag={modalTypeTag}
                newTagInfo={newTagInfo}
                handleFormChangeTag={handleFormChangeTag}
                submitNewTag={submitNewTag}
                toggleModalTag={toggleModalTag}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Lecteur RFID",
                tabIcon: AssignmentIcon,
                tabOnClick: () => setRfidState("sensor"),
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {myself?.rights?.role_superadmin && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <MUIButton
                              className={classes.button}
                              onClick={() => toggleModal()}
                              startIcon={<AddIcon />}
                            >
                              Ajouter un lecteur RFID
                            </MUIButton>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    )}
                    <div>
                      {rfidList && rfidList.length === 0 ? (
                        <h4>Aucun lecteur RFID à afficher</h4>
                      ) :
                        (<Table
                          tableHeaderColor="primary"
                          tableType="rfid"
                          tableHead={
                            [
                              "Titre",
                              "IP / Domaine",
                              "Port",
                              "Utilisateur",
                              "Démarré à",
                              "Éteint à",
                              "",
                            ]
                          }
                          tableData={
                            rfidList &&
                            rfidList.map(rfid => {
                              let list = {
                                title: rfid?.title,
                                host: rfid?.host,
                                port: rfid?.port,
                                user: rfid?.username,
                                start: rfid?.startedAt
                                  ? moment(rfid.startedAt).format(
                                    "DD/MM/YYYY à HH:mm"
                                  )
                                  : "",
                                stop: rfid?.stoppedAt
                                  ? moment(rfid.stoppedAt).format(
                                    "DD/MM/YYYY à HH:mm"
                                  )
                                  : "",
                                actions: (
                                  <div style={{
                                    display: "flex",
                                  }}>
                                    <IconButton
                                      style={{ marginLeft: 'auto', }}
                                      aria-label="api"
                                      onClick={() => window.open(`https://${rfid?.host}:${rfid?.port}/api/v1/data/stream`, "_blank")}
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                    {myself?.rights?.role_superadmin && (
                                      <Button
                                        style={{ marginLeft: '5%', }}
                                        size="sm"
                                        onClick={() => toggleModal(rfid)}
                                        color="warning"
                                      >
                                        Éditer
                                      </Button>
                                    )}
                                    {myself?.rights?.role_superadmin && (
                                      <Button
                                        size="sm"
                                        style={{ marginLeft: '2%' }}
                                        onClick={() => toggleConfirmRfidDestroy("sensor", rfid)}
                                        color="danger"
                                      >
                                        Supprimer
                                      </Button>
                                    )}
                                  </div>
                                )
                              }
                              return Object.values(list);
                            })
                          }
                        />)
                      }
                      {rfidCount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 100,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(rfidCount / limitRfid)}
                            page={page}
                            onChange={(event, pageNumber) => {
                              handlePagination(pageNumber);
                            }}
                            classes={{ ul: paginationClasses.ul }}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                )
              },
              {
                tabName: "Étiquette RFID",
                tabIcon: LocalOfferSharpIcon,
                tabOnClick: () => setRfidState("tag"),
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {myself?.rights?.role_superadmin && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <MUIButton
                              className={classes.button}
                              onClick={() => toggleModalTag()}
                              startIcon={<AddIcon />}
                            >
                              Ajouter une étiquette RFID
                            </MUIButton>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    )}
                    <div>
                      {tagsList && tagsList.length === 0 ? (
                        <h4>Aucune étiquette RFID à afficher</h4>
                      ) :
                        (tagsList &&
                          tagsList?.map((tag, i) => (
                            <div key={i}>
                              <Card>
                                <CardHeader
                                  style={{
                                    borderRadius: 1,
                                    paddingTop: 0,
                                    border: "none",
                                    boxShadow:
                                      "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                                  }}
                                >
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4 className={classes.cardTitle}>
                                      {`Antenne ${tag?.sensor?.title}`}
                                      <IconButton
                                        style={{ marginLeft: '1%', top: '-1' }}
                                        aria-label="api"
                                        onClick={() => window.open(`https://${tag?.sensor?.host}:${tag?.sensor?.port}/api/v1/data/stream`, "_blank")}
                                      >
                                        <OpenInNewIcon />
                                      </IconButton>
                                    </h4>
                                    {pageTag && pageTag.length === tagsList.length &&
                                      (<div style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>
                                        Mis à jour le {moment(pageTag[i].date).format("DD/MM/YYYY à HH:mm")}
                                        <IconButton
                                          style={{ marginLeft: '1%' }}
                                          aria-label="refresh"
                                          onClick={() => refreshSensorTag(tag?.sensor?.uuid)}
                                        >
                                          <RefreshIcon />
                                        </IconButton>
                                      </div>)
                                    }
                                  </div>
                                  <p className={classes.cardDescription}>
                                    {tag?.sensor?.host}:{tag?.sensor?.port}
                                  </p>
                                </CardHeader>
                                <CardBody>
                                  {tag?.tag && tag?.tag?.length === 0 ? ( 
                                    <h4 style={{ marginTop: '1%' }}>
                                      Aucune étiquette RFID à afficher pour cette antenne
                                    </h4>
                                  ) :
                                    (pageTag && pageTag.length === tagsList.length ?
                                      (<div>
                                        <Table
                                          tableHeaderColor="primary"
                                          tableType="rfid"
                                          tableHead={(myself?.rights?.role_superadmin ? [
                                            "Présence",
                                            "Titre",
                                            "Base64 id",
                                            "Ressource",
                                            "Partie principale",
                                            "Dernière fois vue",
                                            "État",
                                            "Rendu",
                                            "Dernier email envoyé",
                                            // "nextEmail",
                                            "",
                                          ] : [
                                            "Présence",
                                            "Titre",
                                            "Ressource",
                                            "Partie principale",
                                            "Dernière fois vue",
                                            "Rendu",
                                            "Dernier email envoyé",
                                          ])}
                                          tableData={
                                            tag &&
                                            tag?.tag
                                              ?.sort((a, b) => {
                                                if (a.ressourceId !== b.ressourceId) {
                                                  return a.ressourceId > b.ressourceId
                                                } else {
                                                  return a.isCore < b.isCore
                                                }
                                              })
                                              .slice(limitTag * (pageTag[i].page - 1), limitTag * (pageTag[i].page - 1) + limitTag)
                                              .map(it => {
                                                let list = {
                                                  present: <Tooltip title={it?.isPresent ? 'Présent' : 'Manquant'}>
                                                    <FiberManualRecordIcon style={{ color: (it?.isPresent ? '#57d696' : '#ef3933') }} />
                                                  </Tooltip>,
                                                  title: it?.title,
                                                }
                                                if (myself?.rights?.role_superadmin)
                                                  list.b64 = it?.tagB64
                                                list.ressource = it?.ressource?.title
                                                list.core = it?.isCore ? 'Oui' : 'Non'
                                                list.last = it?.lastSeen
                                                  ? moment(it.lastSeen).format(
                                                    "DD/MM/YYYY à HH:mm"
                                                  ) + ` (${getTimeInterval(moment().diff(it.lastSeen) / 1000)})`
                                                  : ""
                                                if (myself?.rights?.role_superadmin)
                                                  list.state = it?.state
                                                list.returned = it?.hasReturned ? 'Oui' : 'Non'
                                                list.email = it?.lastEmailSend
                                                  ? moment(it.lastEmailSend).format(
                                                    "DD/MM/YYYY à HH:mm"
                                                  )
                                                  : ""
                                                // nextemail: it?.nextEmail
                                                //   ? moment(it.nextEmail).format(
                                                //     "DD/MM/YYYY à HH:mm"
                                                //   )
                                                //   : "",
                                                if (myself?.rights?.role_superadmin)
                                                  list.actions = (
                                                    <div style={{
                                                      display: "flex",
                                                    }}>
                                                      <Button
                                                        style={{ marginLeft: 'auto', }}
                                                        size="sm"
                                                        onClick={() => toggleModalTag(it)}
                                                        color="warning"
                                                      >
                                                        Éditer
                                                      </Button>
                                                      <Button
                                                        size="sm"
                                                        style={{ marginLeft: '2%' }}
                                                        onClick={() => toggleConfirmRfidDestroy("tag", it)}
                                                        color="danger"
                                                      >
                                                        Supprimer
                                                      </Button>
                                                    </div>
                                                  )
                                                return Object.values(list);
                                              })
                                          }
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingTop: 40,
                                          }}
                                        >
                                          <Pagination
                                            count={Math.ceil(pageTag[i].count / limitTag)}
                                            page={pageTag[i].page}
                                            onChange={(event, pageNumber) => {
                                              handlePaginationTag(pageNumber, i);
                                            }}
                                            classes={{ ul: paginationClasses.ul }}
                                          // size="large"
                                          />
                                        </div>
                                      </div>) : (
                                        <div className={classes.circularProgress}>
                                          <CircularProgress />
                                        </div>
                                      )
                                    )
                                  }
                                </CardBody>
                              </Card>
                            </div>
                          ))
                        )
                      }
                      {tagsCount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 100,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(tagsCount / limitSensorTag)}
                            page={pageSensorTag}
                            onChange={(event, pageNumber) => {
                              handlePaginationSensorTag(pageNumber);
                            }}
                            classes={{ ul: paginationClasses.ul }}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}