import React, { useEffect, useState, createRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch                   } from "react-router-dom";
import { loadState, saveState     } from "../helpers/localStorage";
import { CircularProgress         } from "@material-ui/core";
import { makeStyles               } from "@material-ui/core/styles";
import { OubaboxRoutes            } from "routes.js";
import { privilegeService         } from "../services";
import { history                  } from "../helpers";

import Navbar       from "components/Navbars/Navbar.js";
import Sidebar      from "components/Sidebar/Sidebar.js";
import styles       from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import logo         from "assets/img/svg/oubabox-logo.svg";
import PrivateRoute from "components/PrivateRoutes/PrivatesRoutes";

import {
  userActions,
  siteActions,
  paginationActions,
} from "../actions";

const useStyles = makeStyles(styles);

const Oubabox = ({ ...rest }) => {
  const classes                 = useStyles();
  const dispatch                = useDispatch();
  const mainPanel               = createRef();
  const sites                   = loadState("sites");
  const routes                  = OubaboxRoutes();
  const { loggingIn, loggedIn } = useSelector(state => state.authentication);
  const { open                } = useSelector(state => state.displaySitesModal);
  const { loadedMyself        } = useSelector(state => state.myself);
  const [ selectedSite, setSelectedSite ] = useState(loadState("site"));
  const [ isSuperAdmin, setIsSuperAdmin ] = useState(undefined)
  const [ mobileOpen,   setMobileOpen   ] = useState(false);
  const [ refresh,      setRefresh      ] = useState(false);

  useEffect(() => {
    (loggedIn && !loadedMyself && selectedSite) && dispatch(userActions.getSelf());
    return () => {
      (!loggedIn && !loggingIn) && localStorage.clear();
    };
    // eslint-disable-next-line
  }, [loadedMyself, loggedIn, loggingIn, selectedSite]);

  useEffect(() => {
    (async () => {
      const myself = await privilegeService.getSelf();
      if (isSuperAdmin === undefined)
        setIsSuperAdmin(myself?.sites_to_admins?.find(it => it?.role_superadmin === true) ? true : false);
      if (loggedIn && open && sites?.length === 1 &&
        myself?.sites_to_admins?.find(it => it?.role_oubabox_maintainer))
          selectSite(sites[0]);
      if (!myself?.sites_to_admins?.find(it => it?.role_oubabox_maintainer) && !myself?.sites_to_admins?.find(it => it?.role_superadmin))
        history.push("/admin/statistics");
    })();
    // eslint-disable-next-line
  }, [loggedIn, open])

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const selectSite = site => {
    saveState("site", site);
    setSelectedSite(site);
    if (site) {
      setRefresh(!refresh);
      dispatch(userActions.getSelf());
      dispatch(paginationActions.setPageUsers(1));
      setRefresh(!refresh);
      dispatch(paginationActions.setPageBookings(1));
      dispatch(siteActions.getSite(site));
    }
  }

  return (loggedIn && isSuperAdmin !== undefined) ? (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logo={logo}
        logoText={selectedSite && selectedSite.title}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        bgColor="blue"
        color="brown"
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {routes.map((prop, key) => 
                (prop.layout === "/oubabox" && selectedSite) && (
                  <PrivateRoute
                    path={`${prop.layout}${prop.path}`}
                    component={prop.component}
                    refresh={refresh}
                    key={key * 2}
                  />
                ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.wrapper}>
      {isSuperAdmin !== undefined && <CircularProgress />}
    </div>
  );
}

export default memo(Oubabox);