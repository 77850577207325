import { useEffect, useState } from "react";
import { loadState, saveState } from "../../helpers/localStorage";
import { useSelector, useDispatch } from "react-redux";

import {
  privilegeService,
  collectionServices,
  userService,
} from "../../services";

const AdminPanelLogic = () => {
  const dispatch = useDispatch();

  const { myself } = useSelector(userState => userState.myself);

  const limitAdmins = 10;

  const [collectionsList, setCollectionsList] = useState(undefined);
  const [admins, setAdmins] = useState(undefined);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [editRoleInfo, setEditRoleInfo] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [adminsCount, setAdminsCount] = useState(undefined);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [editModalAdminOpened, setEditModalAdminOpened] = useState(false);
  const [formAdminValid, setFormAdminValid] = useState(false);
  const [newAdminsInfo, setNewAdminsInfo] = useState(undefined);

  const [roles] = useState([
    {
      key: "statistics",
      title: "Statistiques",
    },
    {
      key: "users",
      title: "Utilisateurs",
    },
    {
      key: "alerts",
      title: "Alertes",
    },
    {
      key: "news",
      title: "Actualités",
    },
    {
      key: "bookings",
      title: "Réservations",
    },
    {
      key: "classified",
      title: "Petites annonces",
    },
    {
      key: "links",
      title: "Liens",
    },
    {
      key: "incident",
      title: "Incidents",
    },
    {
      key: "events",
      title: "Événements",
    },
    {
      key: "files",
      title: "Documents",
    },
    {
      key: "mails",
      title: "Mails",
    },
    {
      key: "oubabox",
      title: "Oubabox",
    },
    {
      key: "edit_privilege",
      title: "Modifier les rôles",
    },
  ]);
  const [adminDestroy, setAdminDestroy] = useState(undefined);
  const [adminResendPassword, setAdminResentPassword] = useState(undefined);

  const toggleConfirmAdminDestroy = admin => setAdminDestroy(admin);
  const toggleConfirmAdminResendPassword = admin =>
    setAdminResentPassword(admin);

  useEffect(() => {
    (async () => {
      const collection = await collectionServices.getAll();
      if (collection) setCollectionsList(collection);
      const admin = await privilegeService.getAll();
      if (admin) {
        setAdminsCount(admin.length);
        setAdmins(
          admin.slice(
            limitAdmins * (page - 1),
            limitAdmins * (page - 1) + limitAdmins
          )
        );
      }
    })();
  }, [refresh, page]);

  const parserRole = (admin, value) => {
    const keys = Object.keys(admin).filter(
      it => it !== `role_${value}` && it.search(`role_${value}`) >= 0
    );
    const formattedRole = keys.map(it => ({
      role: admin[it],
      title: it.replace(`role_${value}_`, ""),
    }));
    if (value === "users") {
      formattedRole.splice(0, 0, {
        role: admin?.groupCollectionId
          ? collectionsList.find(it => it.id === admin?.groupCollectionId).id
          : -1,
        title: "collection",
      });
    }
    return [admin[`role_${value}`], formattedRole];
  };

  const toggleModal = (admin = null) => {
    if (admin) {
      const roleInfo = {
        name: admin.user?.first_name + " " + admin.user?.last_name,
        uid: admin.user?.uid,
        roles: [],
        hasPrivilege: false,
      };
      roles.forEach(it => {
        const [role, formattedRole] = parserRole(admin, it.key);
        roleInfo.roles.push({
          title: it.title,
          key: it.key,
          global: role,
          formattedRole,
        });
        if (role && it.key === "edit_privilege") roleInfo.hasPrivilege = true;
      });
      setEditRoleInfo(roleInfo);
    } else {
      setEditRoleInfo(null);
    }
    setEditModalOpened(!editModalOpened);
  };

  const submitRoleEdit = async event => {
    event.preventDefault();
    if (editRoleInfo.uid) {
      let roleInfo = {};
      editRoleInfo.roles.forEach(role => {
        roleInfo[`role_${role.key}`] = role.global;
        role.formattedRole.forEach(it => {
          if (typeof it.role === "boolean")
            roleInfo[`role_${role.key}_${it.title}`] = role.global
              ? it.role
              : false;
          if (it.title === "collection")
            roleInfo.groupCollectionId = it.role < 0 ? null : it.role;
        });
      });
      await privilegeService.update(editRoleInfo.uid, roleInfo);
      const myself = loadState("myself");
      if (editRoleInfo.uid === myself.uid) {
        const newMyself = {
          ...myself,
          rights: { ...myself.rights, ...roleInfo },
        };
        saveState("myself", newMyself);
        window.location.reload();
      }
      openNotification(
        `Les nouveaux rôles de ${editRoleInfo.name} ont été enregistrés`,
        "success"
      );
    } else {
      openNotification(
        "Une erreur est survenu lors la modification des rôles de cet utilisateur. Veuillez réessayer ultérieurement",
        "danger"
      );
    }
    setRefresh(!refresh);
    toggleModal();
  };

  const getTitle = title => {
    if (title === "maintainer") return "Gestionnaire";
    if (title === "provider") return "Installateur";
    if (title === "read") return "Lecture";
    if (title === "add") return "Ajouter";
    if (title === "edit") return "Modifier";
    if (title === "delete") return "Supprimer";
    if (title === "comment_add") return "Commenter";
    if (title === "comment_delete") return "Supprimer les commentaires";
    if (title === "change_status") return "Changer le statut";
    if (title === "validate") return "Valider";
    if (title === "edit_other") return "Modifier autre";
    if (title === "delete_other") return "Supprimer autre";
    if (title === "send") return "Envoyer";
    if (title === "subscriptions_read") return "Lecture abonnements";
    if (title === "rules_read") return "Lecture règles";
    if (title === "rules_add") return "Ajouter règles";
    if (title === "rules_edit") return "Modifier règles";
    if (title === "rules_delete") return "Supprimer règles";
    if (title === "collection") return "Utilisateurs:";
    if (title === "rfid") return "RFID";
    return "";
  };

  const handleFormChange = (key, value, title = null) => {
    let roleInfo = { ...editRoleInfo };
    if (!title) {
      roleInfo.roles.find(role => role.key === key).global = value;
    } else {
      roleInfo.roles
        .find(role => role.key === key)
        .formattedRole.find(it => it.title === title).role = value;
    }
    setEditRoleInfo(roleInfo);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const handlePagination = async pageNumber => {
    setPage(pageNumber);
  };

  const refreshData = () => setRefresh(!refresh);

  const toggleModalAdmin = () => {
    setFormAdminValid(false);
    const adminInfo = {
      call_title: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_phone: "",
    };
    setNewAdminsInfo(adminInfo);
    setEditModalAdminOpened(!editModalAdminOpened);
  };

  const handleFormAdminChange = (key, value) => {
    let adminInfo = { ...newAdminsInfo };
    adminInfo[key] = value;
    setNewAdminsInfo(adminInfo);
    isFormAdminValid(adminInfo);
  };

  const submitNewAdmin = async event => {
    event.preventDefault();
    const res = await privilegeService.addAdmin(newAdminsInfo);
    if (res) {
      setEditModalAdminOpened(!editModalAdminOpened);
      setFormAdminValid(true);
      refreshData();
    } else {
      openNotification(
        "Une erreur est survenu lors de la creation de l'administrateur, vérifiez les informations données sont correctes",
        "danger",
        10
      );
    }
  };

  const isFormAdminValid = admin => {
    if (
      !admin?.call_title?.length ||
      !admin?.first_name?.length ||
      !admin?.last_name?.length ||
      !admin?.email?.length
    )
      setFormAdminValid(false);
    else setFormAdminValid(true);
  };

  const deleteAdmin = async id => {
    await privilegeService.destroy(id);
    toggleConfirmAdminDestroy(null);
    refreshData();
  };

  const resendPasswordAdmin = async uid => {
    await userService.sendNewPassword(uid);
    toggleConfirmAdminResendPassword(null);
    refreshData();
    openNotification(
      `Un mail contenant un lien de réinitialisation de mot de passe a été envoyé.`,
      "success"
    );
  };

  return {
    admins,
    adminDestroy,
    adminsCount,
    adminResendPassword,
    collectionsList,
    editModalOpened,
    editModalAdminOpened,
    editRoleInfo,
    formAdminValid,
    limitAdmins,
    myself,
    newAdminsInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    roles,
    deleteAdmin,
    handleFormAdminChange,
    handleFormChange,
    handlePagination,
    getTitle,
    parserRole,
    refreshData,
    resendPasswordAdmin,
    setOpenNotif,
    submitNewAdmin,
    submitRoleEdit,
    toggleConfirmAdminDestroy,
    toggleModal,
    toggleModalAdmin,
  };
};

export default AdminPanelLogic;
