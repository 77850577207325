import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

import React from "react";
export default function CustomDialog(props) {
  const {
    userSelected,
    openDialog,
    dialogSelected,
    toggleDialog,
    toggleSector,
    toggleGroup,
    handleAttach,
    handleDetach,
    handleRemoveFromResidence,
    handleUserAssociation,
    sectors,
    sector,
    sectorGroups,
    groups,
    group,
    userState,
  } = props;
  return (
    <Dialog
      open={openDialog}
      onClose={() => toggleDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogSelected.type === "group" &&
          userState === "accepted" &&
          `Dissocier du groupe ?`}
        {dialogSelected.type === "sector" &&
          userState === "accepted" &&
          `Dissocier du bâtiment ?`}
        {dialogSelected.type === "group" &&
          userState === "disabled" &&
          `Réassocier au groupe ?`}
        {dialogSelected.type === "sector" &&
          userState === "disabled" &&
          `Réassocier au bâtiment ?`}

        {dialogSelected.type === "addGroup" &&
          `Associer un groupe à ${userSelected.firstName} ${userSelected.lastName}`}
        {dialogSelected.type === "addSector" &&
          `Associer un bâtiment à ${userSelected.firstName} ${userSelected.lastName}`}
        {dialogSelected.type === "removeUser" &&
          `Supprimer ${userSelected.firstName} ${userSelected.lastName} de la résidence ?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogSelected.type === "group" &&
            userState === "accepted" &&
            `Confirmez la désactivation de ${userSelected.firstName} ${userSelected.lastName} du groupe ${dialogSelected.group.title} ?`}
          {dialogSelected.type === "sector" && userState === "accepted" && (
            <div>
              {`Confirmez la dissociation de ${userSelected.firstName} ${userSelected.lastName} du bâtiment ${dialogSelected.sector.name} ?`}
              <br />
              {`Cela s'appliquera également`} <b>pour tous les groupes </b>{" "}
              {`de ${dialogSelected.sector.name} rattachés à ${userSelected.firstName}
              ${userSelected.lastName}.`}
            </div>
          )}

          {dialogSelected.type === "group" &&
            userState === "disabled" &&
            `Confirmez la réassociation de ${userSelected.firstName} ${userSelected.lastName} au groupe ${dialogSelected.group.title} ?`}
          {dialogSelected.type === "sector" &&
            userState === "disabled" &&
            `Confirmez la réassociation de ${userSelected.firstName} ${userSelected.lastName} au bâtiment ${dialogSelected.sector.name} ?
            Cela s'appliquera également pour tous les groupes de ${dialogSelected.sector.name} rattachés à ${userSelected.firstName} ${userSelected.lastName}.`}
        </DialogContentText>
        {dialogSelected.type === "addGroup" && groups && (
          <Select
            value={group || "null"}
            onChange={event =>
              handleUserAssociation("group", event.target.value)
            }
            inputProps={{
              name: "group",
              id: "group",
            }}
          >
            <MenuItem value={"null"}>
              <em>Sélectionnez un groupe à associer</em>
            </MenuItem>
            {groups &&
              groups.map((group, indexgroup) => {
                return (
                  <MenuItem key={indexgroup} value={group.uuid}>
                    {group.title}
                  </MenuItem>
                );
              })}
          </Select>
        )}
        {dialogSelected.type === "addSector" && sectors && (
          <>
            <Select
              value={sector || "null"}
              onChange={event =>
                handleUserAssociation("sector", event.target.value)
              }
              inputProps={{
                name: "sector",
                id: "sector",
              }}
            >
              <MenuItem value={"null"}>
                <em>Sélectionnez un bâtiment à associer</em>
              </MenuItem>
              {sectors &&
                sectors.map((sector, indexAttribute) => (
                  <MenuItem
                    key={indexAttribute}
                    value={sector.uuid}
                    onClick={() => toggleSector(sector.id)}
                  >
                    {sector.name}
                  </MenuItem>
                ))}
            </Select>
            {sectorGroups && (
              <>
                {sectorGroups.filter(
                  group =>
                    !userSelected.estateToUsers.some(
                      eTu => eTu.groupId === group.id
                    )
                ).length ? (
                  <>
                    <br />
                    <Select
                      value={group || "null"}
                      onChange={event =>
                        handleUserAssociation("group", event.target.value)
                      }
                      inputProps={{
                        name: "group",
                        id: "group",
                      }}
                    >
                      <MenuItem value="null">
                        <em>Sélectionnez le(s) groupe(s)</em>
                      </MenuItem>
                      {sectorGroups
                        .filter(
                          group =>
                            !userSelected.estateToUsers.some(
                              eTu => eTu.groupId === group.id
                            )
                        )
                        .map((group, i) => (
                          <MenuItem
                            key={i}
                            value={group.uuid}
                            onClick={() => toggleGroup(group.id)}
                          >
                            {group.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : (
                  <>
                    <br />
                    Aucun groupe à associer
                  </>
                )}
              </>
            )}
          </>
        )}
        {dialogSelected.type === "removeUser" && (
          <p>
            {`${userSelected.firstName} ${userSelected.lastName} ne pourra plus se connecter à cette résidence.`}
            <br />
            <span>
              Il sera dissocié de tous les bâtiments et lots où une demande a
              été soumise.
            </span>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleDialog(false)} color="info">
          Annuler
        </Button>
        {((dialogSelected.type === "group" && userState === "accepted") ||
          (dialogSelected.type === "sector" && userState === "accepted")) && (
          <Button
            onClick={() => {
              // console.log("dialog selected => ", userSelected, dialogSelected)
              handleDetach(
                userSelected.uid,
                dialogSelected.sector?.uuid,
                dialogSelected.group?.uuid
              );
            }}
            color="danger"
            autoFocus
          >
            Détacher
          </Button>
        )}
        {((dialogSelected.type === "group" && userState === "disabled") ||
          (dialogSelected.type === "sector" && userState === "disabled")) && (
          <Button
            onClick={() =>
              handleDetach(
                userSelected.uid,
                dialogSelected.sector?.uuid,
                dialogSelected.group?.uuid
              )
            }
            color="success"
            autoFocus
          >
            Réassocier
          </Button>
        )}
        {(dialogSelected.type === "addGroup" ||
          dialogSelected.type === "addSector") && (
          <Button
            onClick={() => {
              if (dialogSelected.type === "addGroup") {
                handleAttach(userSelected.uid, sector, group);
              }
              if (dialogSelected.type === "addSector") {
                handleAttach(userSelected.uid, sector, group);
              }
            }}
            color="success"
            autoFocus
          >
            Ajouter
          </Button>
        )}
        {dialogSelected.type === "removeUser" && (
          <Button
            onClick={() => {
              handleRemoveFromResidence(userSelected.uid);
            }}
            color="danger"
            autoFocus
          >
            Exclure
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
CustomDialog.propTypes = {
  userSelected: PropTypes.object,
  openDialog: PropTypes.bool,
  dialogSelected: PropTypes.object,
  toggleDialog: PropTypes.func,
  toggleSector: PropTypes.func,
  toggleGroup: PropTypes.func,
  handleAttach: PropTypes.func,
  handleDetach: PropTypes.func,
  handleUserAssociation: PropTypes.func,
  sectors: PropTypes.array,
  sector: PropTypes.string,
  sectorGroups: PropTypes.array,
  groups: PropTypes.array,
  group: PropTypes.string,
  userState: PropTypes.string,
};
