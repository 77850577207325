import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // @material-ui/core components
import moment from "moment";
import localization from "moment/locale/fr";
import {
  bookingServices,
  reservationRuleServices,
  reservationSubscriptionServices,
} from "../../../services";
import { paginationActions, ressourceActions } from "../../../actions";

moment.updateLocale("fr", localization);

const BookingsLogic = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(moment().subtract(1, "months"));
  const [endDate, setEndDate] = useState(moment().add(1, "months"));
  const [query, setQuery] = useState("");

  const [ressource, setRessource] = useState([]);
  const [bookingState, setBookingState] = useState('booking')
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);

  const { myself } = useSelector(state => state.myself);
  const { ressources } = useSelector(
    state => state.ressourcesFetching
  );
  const [bookingsList, setBookingsList] = useState(undefined);
  const limits = {
    subscription: 15,
    rule: 4,
  }
  const [subscriptions, setSubscriptions] = useState(undefined)
  const [subscriptionsCount, setSubscriptionsCount] = useState(0)
  const [pageSubscription, setPageSubscription] = useState(1)
  const statusSub = [
    { id: 1, title: 'Initié', key: 'initiated' },
    { id: 2, title: 'En cours', key: 'ongoing' },
    { id: 3, title: 'Dépassé', key: 'outdated' },
    { id: 4, title: 'Annulé', key: 'canceled' },
  ]
  const statusBookings = [
    { id: 1, title: 'Innitié', key: 'initiated' },
    { id: 2, title: 'Réservé', key: 'booked' },
    { id: 3, title: 'Annulé', key: 'canceled' },
  ]
  const [subscriptionStatus, setSubscriptionStatus] = useState(statusSub.filter(it => it.key === 'ongoing' || it.key === 'initiated'))
  const [bookingStatus, setBookingStatus] = useState(statusBookings.filter(it => it.key === 'booked'))
  const [rules, setRules] = useState(undefined)
  const [rulesCount, setRulesCount] = useState(0)
  const [pageRule, setPageRule] = useState(1)
  const [ruleModalOpened, setRuleModalOpened] = useState(false);
  const [newRuleInfo, setNewRuleInfo] = useState(undefined)
  const [modalRuleType, setModalRuleType] = useState(false)
  const [formRuleValid, setFormRuleValid] = useState(false)
  const [pageModalRule, setPageModalRule] = useState(1)

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [bookingDestroy, setBookingDestroy] = useState(undefined);

  const toggleConfirmBookingDestroy = booking => setBookingDestroy(booking);

  const toggleSelectedRessource = value => setRessource(value);
  const refreshData = () => setRefresh(!refresh);

  useEffect(() => {
    dispatch(ressourceActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(paginationActions.getPageBookings(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (startDate && endDate) {
      (async () => {
        const bookings = await bookingServices.getAllPage(
          startDate?.format("MM/DD/YYYY"),
          endDate?.format("MM/DD/YYYY"),
          query,
          ressource.map(ressource => `&ressourceId=${ressource.id}`),
          bookingStatus.map(status => `&status=${status.key}`),
          page
        );
        if (bookings) setBookingsList(bookings);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, page, ressource, bookingStatus, refresh]);

  useEffect(() => {
    (async () => {
      if (bookingState === 'rule') {
        const rule = await reservationRuleServices.getAll()
        if (rule) {
          rule.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          // console.log(rule)
          setRulesCount(rule.length)
          setRules(rule.slice(limits.rule * (pageRule - 1), limits.rule * (pageRule - 1) + limits.rule))
        }
      } else if (bookingState === 'sub') {
        let sub = await reservationSubscriptionServices.getAll()
        if (sub) {
          sub = sub.filter(s => !subscriptionStatus.length || subscriptionStatus.find(it => it.key === s.status))
          // console.log(sub)
          setSubscriptionsCount(sub.length)
          setSubscriptions(sub.slice(limits.subscription * (pageSubscription - 1), limits.subscription * (pageSubscription - 1) + limits.subscription))
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingState, pageRule, pageSubscription, refresh])

  const toggleQuery = query => {
    setQuery(query);
  };

  const handlePagination = page => {
    setPage(page);
  };

  const handlePaginationRule = page => {
    setPageRule(page);
  };

  const handlePaginationSubscription = page => {
    setPageSubscription(page);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageModalRule])

  const handlePaginationModalRule = page => {
    setPageModalRule(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const toggleModalRule = (rule = null) => {
    setPageModalRule(1)
    setFormRuleValid(false)
    setModalRuleType(rule ? true : false)
    const ruleInfo = {
      uuid: rule?.uuid,
      title: rule?.title,
      content: rule?.content
    }
    setNewRuleInfo(ruleInfo)
    setRuleModalOpened(!ruleModalOpened)
  }

  const submitNewRule = async (event) => {
    event.preventDefault()
    const ruleInfo = {
      title: newRuleInfo.title,
      content: newRuleInfo.content,
    }
    if (newRuleInfo.uuid) {
      if (await reservationRuleServices.update(newRuleInfo.uuid, ruleInfo)) {
        openNotification(`La régle ${ruleInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification de la règle ${ruleInfo?.title}`, 'danger')
      }
    } else {
      if (await reservationRuleServices.add(ruleInfo)) {
        openNotification(`La régle ${ruleInfo?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout de la règle ${ruleInfo?.title}`, 'danger')
      }
    }
    refreshData()
    setRuleModalOpened(!ruleModalOpened)
    setFormRuleValid(true)
  }

  const handleRuleFormChange = (key, value) => {
    const ruleInfo = { ...newRuleInfo }
    ruleInfo[key] = value
    setNewRuleInfo(ruleInfo)
    isFormRuleValid(ruleInfo)
  }

  const isFormRuleValid = (rule) => {
    if (!rule?.title?.length || !rule?.content?.length)
      setFormRuleValid(false)
    else
      setFormRuleValid(true)
  }

  const deleteRule = async (uuid) => {
    await reservationRuleServices.destroy(uuid)
    openNotification(`La régle ${rules.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmBookingDestroy(null)
    refreshData()
  }

  const updateSubscriptionStatusFilter = (value) => {
    setSubscriptionStatus(value)
    refreshData()
  }
  
  const updateBookingStatusFilter = (value) => {
    setBookingStatus(value)
    refreshData()
  }

  return {
    bookingDestroy,
    bookingsList,
    endDate,
    formRuleValid,
    limits,
    modalRuleType,
    myself,
    newRuleInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    pageRule,
    pageSubscription,
    pageModalRule,
    query,
    ressource,
    ressources,
    ruleModalOpened,
    rules,
    rulesCount,
    startDate,
    statusSub,
    statusBookings,
    subscriptions,
    subscriptionsCount,
    subscriptionStatus,
    bookingStatus,
    deleteRule,
    handlePagination,
    handlePaginationRule,
    handlePaginationSubscription,
    handleRuleFormChange,
    refreshData,
    setBookingState,
    setOpenNotif,
    handlePaginationModalRule,
    setEndDate,
    setStartDate,
    submitNewRule,
    toggleQuery,
    toggleModalRule,
    toggleConfirmBookingDestroy,
    toggleSelectedRessource,
    updateSubscriptionStatusFilter,
    updateBookingStatusFilter,
  };
};

export default BookingsLogic;
