/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormRfidTag(props) {
  const classes = useStyles();

  const b64tag_2 = useRef();
  const b64tag_3 = useRef();
  const b64tag_4 = useRef();
  const b64tag_5 = useRef();
  const b64tag_6 = useRef();

  const {
    formSensorList,
    formRessourceList,
    formValidTag,
    modalTypeTag,
    newTagInfo,
    handleFormChangeTag,
    submitNewTag,
    toggleModalTag,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalTypeTag ? (
            <Typography variant="h5">Modifier l'étiquette</Typography>
          ) : (
            <Typography variant="h5">Ajouter une étiquette</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalTag()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewTag} noValidate>
          <Grid container spacing={3}>
            <Grid item md={9}>
              <InputLabel>Titre *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTagInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChangeTag("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputLabel>Partie principale</InputLabel>
              <Switch
                checked={newTagInfo.isCore}
                onChange={e =>
                  handleFormChangeTag("isCore", e.target.checked)
                }
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel>Antenne *</InputLabel>
              {formSensorList ? (
                <Select
                  value={newTagInfo.sensor}
                  style={{ top: "5px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handleFormChangeTag("sensor", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formSensorList &&
                    formSensorList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel>Ressource *</InputLabel>
              {formRessourceList ? (
                <Select
                  value={newTagInfo.ressource}
                  style={{ top: "5px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handleFormChangeTag("ressource", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formRessourceList &&
                    formRessourceList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabel>Base64 id *</InputLabel>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginRight: 15,
                    fontSize: 18,
                    marginLeft: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  3000
                </div>
                <TextField
                  style={{ marginRight: 5, width: '100px' }}
                  defaultValue={newTagInfo.tagId[0]}
                  name="tagB64_1"
                  required
                  id="tagB64_1"
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    style: {
                      fontSize: 18,
                      textAlign: 'center',
                      letterSpacing: 5,
                    }
                  }}
                  onChange={event => {
                    event.target.value = event?.target?.value?.replace(/[^0-9]/g, '');
                    if (event?.target?.value?.length <= 4) {
                      handleFormChangeTag("tagId", event.target.value, 0)
                      if (event?.target?.value?.length === 4) {
                        b64tag_2.current.select()
                      }
                    } else {
                      event.target.value = event?.target?.value.slice(0, 4)
                    }
                  }
                  }
                />
                <TextField
                  style={{ marginRight: 5, width: '100px' }}
                  defaultValue={newTagInfo.tagId[1]}
                  name="tagB64_2"
                  required
                  id="tagB64_2"
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    style: {
                      fontSize: 18,
                      textAlign: 'center',
                      letterSpacing: 5,
                    }
                  }}
                  inputRef={b64tag_2}
                  onChange={event => {
                    event.target.value = event?.target?.value?.replace(/[^0-9]/g, '');
                    if (event?.target?.value?.length <= 4) {
                      handleFormChangeTag("tagId", event.target.value, 1)
                      if (event?.target?.value?.length === 4) {
                        b64tag_3.current.select()
                      }
                    } else {
                      event.target.value = event?.target?.value.slice(0, 4)
                    }
                  }
                  }
                />
                <TextField
                  style={{ marginRight: 5, width: '100px' }}
                  defaultValue={newTagInfo.tagId[2]}
                  name="tagB64_3"
                  required
                  id="tagB64_3"
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    style: {
                      fontSize: 18,
                      textAlign: 'center',
                      letterSpacing: 5,
                    }
                  }}
                  inputRef={b64tag_3}
                  onChange={event => {
                    event.target.value = event?.target?.value?.replace(/[^0-9]/g, '');
                    if (event?.target?.value?.length <= 4) {
                      handleFormChangeTag("tagId", event.target.value, 2)
                      if (event?.target?.value?.length === 4) {
                        b64tag_4.current.select()
                      }
                    } else {
                      event.target.value = event?.target?.value.slice(0, 4)
                    }
                  }
                  }
                />
                <TextField
                  style={{ marginRight: 5, width: '100px' }}
                  defaultValue={newTagInfo.tagId[3]}
                  name="tagB64_4"
                  required
                  id="tagB64_4"
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    style: {
                      fontSize: 18,
                      textAlign: 'center',
                      letterSpacing: 5,
                    }
                  }}
                  inputRef={b64tag_4}
                  onChange={event => {
                    event.target.value = event?.target?.value?.replace(/[^0-9]/g, '');
                    if (event?.target?.value?.length <= 4) {
                      handleFormChangeTag("tagId", event.target.value, 3)
                      if (event?.target?.value?.length === 4) {
                        b64tag_5.current.select()
                      }
                    } else {
                      event.target.value = event?.target?.value.slice(0, 4)
                    }
                  }
                  }
                />
                <TextField
                  style={{ marginRight: 5, width: '100px' }}
                  defaultValue={newTagInfo.tagId[4]}
                  name="tagB64_5"
                  required
                  id="tagB64_5"
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    style: {
                      fontSize: 18,
                      textAlign: 'center',
                      letterSpacing: 5,
                    }
                  }}
                  inputRef={b64tag_5}
                  onChange={event => {
                    event.target.value = event?.target?.value?.replace(/[^0-9]/g, '');
                    if (event?.target?.value?.length <= 4) {
                      handleFormChangeTag("tagId", event.target.value, 4)
                      if (event?.target?.value?.length === 4) {
                        b64tag_6.current.select()
                      }
                    } else {
                      event.target.value = event?.target?.value.slice(0, 4)
                    }
                  }
                  }
                />
                <TextField
                  style={{ marginRight: 5, width: '100px' }}
                  defaultValue={newTagInfo.tagId[5]}
                  name="tagB64_6"
                  required
                  id="tagB64_6"
                  variant="outlined"
                  margin="normal"
                  inputProps={{
                    style: {
                      fontSize: 18,
                      textAlign: 'center',
                      letterSpacing: 5,
                    }
                  }}
                  inputRef={b64tag_6}
                  onChange={event => {
                    event.target.value = event?.target?.value?.replace(/[^0-9]/g, '');
                    if (event?.target?.value?.length <= 4) {
                      handleFormChangeTag("tagId", event.target.value, 5)
                    } else {
                      event.target.value = event?.target?.value.slice(0, 4)
                    }
                  }
                  }
                />
              </div>
            </Grid>
            <Grid item md={12}>
              <div style={{ fontSize: 16 }}>
                Id: {newTagInfo.tagB64}
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValidTag}
          >
            Enregister
          </Button>
        </form >
      </div >
    </Container >
  );
}

FormRfidTag.propTypes = {
  formSensorList: PropTypes.array,
  formRessourceList: PropTypes.array,
  formValidTag: PropTypes.bool,
  modalTypeTag: PropTypes.bool,
  newTagInfo: PropTypes.object,
  handleFormChangeTag: PropTypes.func,
  submitNewTag: PropTypes.func,
  toggleModalTag: PropTypes.func,
};
