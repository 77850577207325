import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
    featureServices,
    moduleServices,
    reservation2nServices,
    reservationA1001DoorServices,
    reservationA1001Services,
    reservationTypeServices,
    reservationCleaningServices,
    reservationLocationServices,
    reservationRessourceServices,
    reservationRuleServices,
    reservationSubscriptionServices,
    sectorServices,
} from "../../../../services";

moment.updateLocale("fr", localization);

const BookingsSettingsLogic = () => {
    const [bookingState, setBookingState] = useState("ressource");
    const [refresh, setRefresh] = useState(false);

    const limits = {
        ressource: 5,
        location: 10,
        cleaning: 10,
        a1001: 15,
        door: 15,
        "2n": 15,
    };

    const [openNotif, setOpenNotif] = useState(false);
    const [notifMessage, setNotifMessage] = useState("");
    const [notifColor, setNotifColor] = useState("info");
    const [refreshImageRessource, setRefreshImageRessource] = useState(undefined);
    const [refreshImageLocation, setRefreshImageLocation] = useState(undefined);

    const [ressourcesList, setRessourcesList] = useState(undefined);
    const [periodsList, setPeriodsList] = useState([]);
    const [ressourcesCount, setRessourcesCount] = useState(0);
    const [pageRessource, setPageRessource] = useState(1);
    const [ressourceRecurrence, setRessourceRecurrence] = useState([]);
    const [ressourceModalOpened, setRessourceModalOpened] = useState(false);
    const [newRessourceInfo, setNewRessourceInfo] = useState(undefined);
    const [modalRessourceType, setModalRessourceType] = useState(false);
    const [formRessourceValid, setFormRessourceValid] = useState(false);

    const [formRulesList, setFormRulesList] = useState(undefined);
    const [formCleaningList, setFormCleaningList] = useState(undefined);
    const [formModulesList, setFormModulesList] = useState(undefined);
    const [formLocationList, setFormLocationList] = useState(undefined);
    const [formTypeList, setFormTypeList] = useState(undefined);
    const [locationList, setLocationList] = useState(undefined);
    const [locationCount, setLocationCount] = useState(0);
    const [pageLocation, setPageLocation] = useState(1);
    const [locationModalOpened, setLocationModalOpened] = useState(false);
    const [newLocationInfo, setNewLocationInfo] = useState(undefined);
    const [modalLocationType, setModalLocationType] = useState(false);
    const [formLocationValid, setFormLocationValid] = useState(false);

    const [cleaningList, setCleaningList] = useState(undefined);
    const [cleaningCount, setCleaningCount] = useState(0);
    const [pageCleaning, setPageCleaning] = useState(1);
    const [cleaningModalOpened, setCleaningModalOpened] = useState(false);
    const [newCleaningInfo, setNewCleaningInfo] = useState(undefined);
    const [modalCleaningType, setModalCleaningType] = useState(false);
    const [formCleaningValid, setFormCleaningValid] = useState(false);
    const [locationRecurrence, setLocationRecurrence] = useState([]);
    const [isKeyRegenerate, setIsKeyRegenerate] = useState(false);
    
    const [typeList, setTypeList] = useState(undefined);

    const [a1001List, setA1001List] = useState(undefined);
    const [a1001Count, setA1001Count] = useState(0);
    const [pageA1001, setPageA1001] = useState(1);
    const [a1001ModalOpened, setA1001ModalOpened] = useState(false);
    const [newA1001Info, setNewA1001Info] = useState(undefined);
    const [modalA1001Type, setModalA1001Type] = useState(false);
    const [formA1001Valid, setFormA1001Valid] = useState(false);

    const [doorsList, setDoorsList] = useState(undefined);
    const [doorsCount, setDoorsCount] = useState(0);
    const [pageDoors, setPageDoors] = useState(1);
    const [doorsModalOpened, setDoorsModalOpened] = useState(false);
    const [newA1001DoorsInfo, setNewDoorsInfo] = useState(undefined);
    const [modalDoorsType, setModalDoorsType] = useState(false);
    const [formDoorsValid, setFormDoorsValid] = useState(false);
    const [formLocationDoorsList, setFormLocationDoorsList] = useState(undefined);
    const [formA1001List, setFormA1001List] = useState(undefined);

    const [twoNList, setTwoNList] = useState(undefined);
    const [twoNCount, setTwoNCount] = useState(0);
    const [pageTwoN, setPageTwoN] = useState(1);
    const [twoNModalOpened, setTwoNModalOpened] = useState(false);
    const [newTwoNInfo, setNewTwoNInfo] = useState(undefined);
    const [modalTwoNType, setModalTwoNType] = useState(false);
    const [formTwoNValid, setFormTwoNValid] = useState(false);

    const [bookingDestroy, setBookingDestroy] = useState(undefined);
    const [bookingDestroyType, setBookingDestroyType] = useState(undefined);
    
    const [formSectorList, setFormSectorList] = useState(undefined);

    const toggleConfirmBookingDestroy = (type, booking) => {
        setBookingDestroyType(type);
        setBookingDestroy(booking);
    };

    const deleteBooking = uuid => {
        if (bookingDestroyType === "ressource") {
            deleteRessource(uuid);
        } else if (bookingDestroyType === "location") {
            deleteLocation(uuid);
        } else if (bookingDestroyType === "cleaning") {
            deleteCleaning(uuid);
        } else if (bookingDestroyType === "a1001") {
            deleteA1001(uuid);
        } else if (bookingDestroyType === "door") {
            deleteDoors(uuid);
        } else if (bookingDestroyType === "2n") {
            delete2n(uuid);
        }
        toggleConfirmBookingDestroy(null);
    };

    const modalMessageBooking = () => {
        if (bookingDestroyType === "ressource") {
            return `Supprimer la ressource "${bookingDestroy.title}" ?`;
        } else if (bookingDestroyType === "location") {
            return `Supprimer l'emplacement "${bookingDestroy.title}" ?`;
        } else if (bookingDestroyType === "cleaning") {
            return `Supprimer le ménage "${bookingDestroy.title}" ?`;
        } else if (bookingDestroyType === "a1001") {
            return `Supprimer l'A1001 "${bookingDestroy.title}" ?`;
        } else if (bookingDestroyType === "door") {
            return `Supprimer la porte "${bookingDestroy.title}" ?`;
        } else if (bookingDestroyType === "2n") {
            return `Supprimer le 2N "${bookingDestroy.label}" ?`;
        }
    };

    const refreshData = () => setRefresh(!refresh);

    useEffect(() => {
        (async () => {
            if (bookingState === "ressource") {
                const ressources = await reservationRessourceServices.getAllRessources();
                if (ressources) {
                    // console.log(ressource, ressource[0])
                    setRessourcesCount(ressources.length);
                    const ress = ressources.slice(
                        limits.ressource * (pageRessource - 1),
                        limits.ressource * (pageRessource - 1) + limits.ressource
                    );
                    setRessourcesList(ress);
                    if (!refreshImageRessource) {
                        let r = {};
                        ress.forEach(it => (r[it.uuid] = false));
                        setRefreshImageRessource(r);
                    }
                    const recs = ressources
                        .slice(
                            limits.ressource * (pageRessource - 1),
                            limits.ressource * (pageRessource - 1) + limits.ressource
                        )
                        .map(r => {
                            let recurrence = r.weeks_availability;
                            if (!r.weeks_availability) recurrence = "0000000";
                            const days = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];
                            const rec = [];
                            for (let i in recurrence) {
                                if (recurrence[i] === "1")
                                    rec.push({d: days[i], active: true});
                                else rec.push({d: days[i], active: false});
                            }
                            return rec;
                        });
                    setRessourceRecurrence(recs);
                }
            } else if (bookingState === "location") {
                let location = await reservationLocationServices.getAll();
                if (location) {
                    setLocationCount(location.length);
                    const l = location.slice(
                        limits.location * (pageLocation - 1),
                        limits.location * (pageLocation - 1) + limits.location
                    );
                    setLocationList(l);
                    if (!refreshImageLocation) {
                        let r = {};
                        l.forEach(it => (r[it.uuid] = false));
                        setRefreshImageLocation(r);
                    }
                }
            } else if (bookingState === "cleaning") {
                let cleaning = await reservationCleaningServices.getAll();
                if (cleaning) {
                    setCleaningCount(cleaning.length);
                    setCleaningList(
                        cleaning.slice(
                            limits.cleaning * (pageCleaning - 1),
                            limits.cleaning * (pageCleaning - 1) + limits.cleaning
                        )
                    );
                    const days = cleaning
                        .slice(
                            limits.cleaning * (pageCleaning - 1),
                            limits.cleaning * (pageCleaning - 1) + limits.cleaning
                        )
                        .map(r => {
                            let recurrence = r.working_days;
                            if (!r.working_days) recurrence = "0000000";
                            const days = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];
                            const rec = [];
                            for (let i in recurrence) {
                                if (recurrence[i] === "1")
                                    rec.push({d: days[i], active: true});
                                else rec.push({d: days[i], active: false});
                            }
                            return rec;
                        });
                    setLocationRecurrence(days);
                }
            } else if (bookingState === "a1001") {
                let a1001 = await reservationA1001Services.getAll();
                if (a1001) {
                    setA1001Count(a1001.length);
                    setA1001List(
                        a1001.slice(
                            limits.a1001 * (pageA1001 - 1),
                            limits.a1001 * (pageA1001 - 1) + limits.a1001
                        )
                    );
                }
            } else if (bookingState === "doors") {
                let door = await reservationA1001DoorServices.getAll();
                if (door) {
                    setDoorsCount(door.length);
                    setDoorsList(
                        door.slice(
                            limits.door * (pageDoors - 1),
                            limits.door * (pageDoors - 1) + limits.door
                        )
                    );
                }
            } else if (bookingState === "2n") {
                let twoN = await reservation2nServices.getAll();
                if (twoN) {
                    setTwoNCount(twoN.length);
                    setTwoNList(
                        twoN.slice(
                            limits["2n"] * (pageTwoN - 1),
                            limits["2n"] * (pageTwoN - 1) + limits["2n"]
                        )
                    );
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bookingState,
        pageRessource,
        pageLocation,
        pageCleaning,
        pageA1001,
        pageDoors,
        pageTwoN,
        refresh,
    ]);

    const handlePaginationRessource = page => {
        setPageRessource(page);
    };

    const handlePaginationLocation = page => {
        setPageLocation(page);
    };

    const handlePaginationCleaning = page => {
        setPageCleaning(page);
    };

    const handlePaginationA1001 = page => {
        setPageA1001(page);
    };

    const handlePaginationDoors = page => {
        setPageDoors(page);
    };

    const handlePagination2n = page => {
        setPageTwoN(page);
    };

    const refreshImgRessource = uuid => {
        const refreshOn = {...refreshImageRessource};
        refreshOn[uuid] = true;
        setRefreshImageRessource(refreshOn);
        setTimeout(() => {
            const refreshOff = {...refreshImageRessource};
            refreshOff[uuid] = false;
            setRefreshImageRessource(refreshOff);
        }, 700);
    };

    const refreshImgLocation = uuid => {
        const refreshOn = {...refreshImageLocation};
        refreshOn[uuid] = true;
        setRefreshImageLocation(refreshOn);
        setTimeout(() => {
            const refreshOff = {...refreshImageLocation};
            refreshOff[uuid] = false;
            setRefreshImageLocation(refreshOff);
        }, 700);
    };

    const openNotification = (msg, color = "info", time = 6) => {
        setOpenNotif(false);
        setNotifMessage(msg);
        setNotifColor(color);
        setTimeout(() => setOpenNotif(false), time * 1000);
        setTimeout(() => setOpenNotif(true), 100);
    };

    const parseHourTime = time => {
        const t = time.split(":");
        if (t.length < 2) return time;
        return t[0] + ":" + t[1];
    };

    const getTitleInterval = val => {
        if (val === "nights") return "Nuit(s)";
        else if (val === "minutes") return "Minute(s)";
        else if (val === "hours") return "Heure(s)";
        else if (val === "days") return "Jour(s)";
        return "";
    };

    const getAllRessourceform = async () => {
        const module = await moduleServices.getAll();
        const feature = await featureServices.getAll();
        if (module && feature) {
            const share = module.find(it => it.identifier === "share")?.id;
            if (share)
                setFormModulesList(feature.filter(it => it.module_id === share));
        }
        const type = await reservationTypeServices.getAll();
        if (type) {
            setFormTypeList(type);
        }
        const cleaning = await reservationCleaningServices.getAll();
        if (cleaning) {
            setFormCleaningList(cleaning);
        }
        const location = await reservationLocationServices.getAll();
        if (location) {
            setFormLocationList(location);
        }
        const rule = await reservationRuleServices.getAll();
        if (rule) {
            setFormRulesList(rule);
        }
    };

    const addPeriodToRessource = (resourceUUID, timeLength, timeType, price) => {
        const data = {
            period_number: timeLength,
            period_type: timeType,
            price,
        };
        return reservationSubscriptionServices.addPeriod(resourceUUID, data);
    };

    const updatePeriodFromRessource = (uuid, timeLength, timeType, price) => {
        const data = {
            period_number: timeLength,
            period_type: timeType,
            price,
        };
        return reservationSubscriptionServices.updatePeriod(uuid, data);
    };

    const deletePeriodFromRessource = uuid => {
        return reservationSubscriptionServices.deletePeriod(uuid);
    };

    const toggleModalRessource = async (ressource = null) => {
        getAllRessourceform();
        setFormRessourceValid(false);
        setModalRessourceType(ressource ? true : false);
        const ressourceInfo = {
            uuid: ressource?.uuid,
            title: ressource?.title ? ressource?.title : "",
            enabled:
                typeof ressource?.enabled === "boolean" ? ressource?.enabled : true,
            weeks_availability: ressource?.weeks_availability
                ? ressource?.weeks_availability
                : "0000000",
            available_months: ressource?.available_months
                ? ressource?.available_months
                : 0,
            available_days: ressource?.available_days
                ? ressource?.available_days
                : 0,
            interval_value: ressource?.interval_value ? ressource?.interval_value : 1,
            interval_type: ressource?.interval_type
                ? ressource?.interval_type
                : "days",
            interval_unified: ressource?.interval_unified
                ? ressource?.interval_unified
                : false,
            open_at: ressource?.open_at ? parseHourTime(ressource?.open_at) : "",
            close_at: ressource?.close_at ? parseHourTime(ressource?.close_at) : "",
            booking_price: parseFloat(
                ressource?.booking_price ? ressource?.booking_price / 100 : 0
            ).toFixed(2),
            unit_price: parseFloat(
                ressource?.unit_price ? ressource?.unit_price / 100 : 0
            ).toFixed(2),
            unit_min: ressource?.unit_min ? ressource?.unit_min : 1,
            unit_max: ressource?.unit_max ? ressource?.unit_max : 1,
            available_units: ressource?.available_units
                ? ressource?.available_units
                : 1,
            unit_self_delay: ressource?.unit_self_delay
                ? ressource?.unit_self_delay
                : 0,
            unit_users_delay: ressource?.unit_users_delay
                ? ressource?.unit_users_delay
                : 0,
            booking_method: ressource?.booking_method && ressource?.booking_method,
            quota: ressource?.quota ? ressource?.quota : "",
            max_rolling_bookings: (ressource?.max_rolling_bookings !== null && ressource?.max_rolling_bookings !== undefined)? ressource?.max_rolling_bookings : "",
            is_group_access: !!ressource?.is_group_access,
            warnAdmin: ressource?.warnAdmin ? ressource?.warnAdmin : false,
            custom_message: ressource?.custom_message
                ? ressource?.custom_message
                : "",
            cancel_delay_hr: ressource?.cancel_delay_hr ? ressource?.cancel_delay_hr : 0,
            booking_delay_hr: ressource?.booking_delay_hr ? ressource?.booking_delay_hr : 0,
            cleaning: ressource?.cleaning?.uuid ? ressource?.cleaning?.uuid : "",
            location: ressource?.location?.uuid ? ressource?.location?.uuid : "",
            rule: ressource?.rule?.uuid ? ressource?.rule?.uuid : "",
            type: ressource?.type?.keyid ? ressource?.type?.keyid : "",
            features: ressource?.features
                ? ressource?.features.map(it => it.uuid)
                : [],
            image: null,
            has_picture: ressource?.has_picture ? ressource?.has_picture : false,
        };
        if (ressourceInfo.uuid) {
            const periods = await reservationSubscriptionServices.getAllPeriod(ressourceInfo.uuid)
            if (periods.length) {
                setPeriodsList(periods)
            }
        }
        setNewRessourceInfo(ressourceInfo);
        setRessourceModalOpened(!ressourceModalOpened);
    };

    const submitNewRessource = async event => {
        event.preventDefault();
        const ressourceInfo = {...newRessourceInfo};
        ressourceInfo.open_at = !ressourceInfo.open_at?.length
            ? ""
            : ressourceInfo.open_at + ":00";
        ressourceInfo.close_at = !ressourceInfo.close_at?.length
            ? ""
            : ressourceInfo.close_at + ":00";
        ressourceInfo.booking_price = ressourceInfo.booking_price * 100;
        ressourceInfo.unit_price = ressourceInfo.unit_price * 100;
        ressourceInfo.unit_min = parseInt(ressourceInfo.unit_min);
        ressourceInfo.unit_max = parseInt(ressourceInfo.unit_max);
        ressourceInfo.available_units = parseInt(ressourceInfo.available_units);
        ressourceInfo.unit_self_delay = parseInt(ressourceInfo.unit_self_delay);
        ressourceInfo.unit_users_delay = parseInt(ressourceInfo.unit_users_delay);
        ressourceInfo.booking_delay_hr = parseInt(ressourceInfo.booking_delay_hr);
        ressourceInfo.cancel_delay_hr = parseInt(ressourceInfo.cancel_delay_hr);
        ressourceInfo.available_months = parseInt(ressourceInfo.available_months);
        ressourceInfo.interval_value = parseInt(ressourceInfo.interval_value);
        let ressource = undefined;
        if (newRessourceInfo.uuid) {
            const features = ressourcesList.find(
                it => it.uuid === newRessourceInfo.uuid
            ).features;
            features
                .filter(
                    feature => !ressourceInfo.features.find(it => it === feature.uuid)
                )
                .forEach(
                    async it =>
                        await reservationRessourceServices.detachFeature(
                            newRessourceInfo.uuid,
                            it.uuid
                        )
                );
            ressourceInfo.features
                .filter(it => !features.find(feature => feature.uuid === it))
                .forEach(
                    async it =>
                        await reservationRessourceServices.attachFeature(
                            newRessourceInfo.uuid,
                            it
                        )
                );

            ressource = await reservationRessourceServices.update(
                newRessourceInfo.uuid,
                ressourceInfo
            );
            if (ressource) {
                openNotification(
                    `La ressource ${ressource?.title} a été modifié`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de la modification de la ressource ${newRessourceInfo?.title}`,
                    "danger"
                );
            }
        } else {
            ressource = await reservationRessourceServices.add(ressourceInfo);
            if (ressource) {
                for (let x of ressourceInfo.features) {
                    await reservationRessourceServices.attachFeature(ressource.uuid, x);
                }
                openNotification(
                    `La ressource ${ressource?.title} a été ajouté`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de l'ajout de la ressource ${newRessourceInfo?.title}`,
                    "danger"
                );
            }
        }
        if (ressource) {
            await reservationRessourceServices.setTypeForRessource(
                ressourceInfo.type?.length ? ressourceInfo.type : null,
                ressource.uuid
            );
            await reservationRessourceServices.setLocationForRessource(
                ressourceInfo.location?.length ? ressourceInfo.location : null,
                ressource.uuid
            );
            await reservationRessourceServices.setRuleForRessource(
                ressourceInfo.rule?.length ? ressourceInfo.rule : null,
                ressource.uuid
            );
            await reservationRessourceServices.setCleaningForRessource(
                ressourceInfo.cleaning?.length ? ressourceInfo.cleaning : null,
                ressource.uuid
            );
        }
        if (newRessourceInfo.image && ressource) {
            let data = new FormData();
            data.append("file", newRessourceInfo.image);
            await reservationRessourceServices.setImage(ressource.uuid, data);
            refreshImgRessource(ressource.uuid);
        }
        refreshData();
        setRessourceModalOpened(!ressourceModalOpened);
        setFormRessourceValid(true);
    };

    const handleRessourceFormChange = (key, value, idx = 0) => {
        const ressourceInfo = {...newRessourceInfo};
        if (key === "recurrence") {
            ressourceInfo.weeks_availability =
                ressourceInfo.weeks_availability.substring(0, idx) +
                (value ? "1" : "0") +
                ressourceInfo.weeks_availability.substring(idx + 1);
        } else {
            ressourceInfo[key] = value;
        }
        setNewRessourceInfo(ressourceInfo);
        isFormRessourceValid(ressourceInfo);
    };

    const isFormRessourceValid = ressource => {
        let valid = true;
        let state = 0;
        if (ressource?.quota?.length) {
            for (let i of ressource?.quota) {
                if (state === 4) {
                    valid = false;
                    break;
                }
                if (state === 1 && i === "T") state = 2;
                else if (state === 3 && (i === "D" || i === "M" || i === "Y")) state = 4;
                else if (state <= 3 && isNaN(parseInt(i))) {
                    valid = false;
                    break;
                } else if (state === 0 && !isNaN(parseInt(i))) state = 1;
                else if (state === 2 && !isNaN(parseInt(i))) state = 3;
            }
            if (state !== 4) valid = false;
        }
        
        const isLessThanZero = (prop) => {
            return parseInt(prop) < 0
        }
        
        const isLessThanOne = (prop) => {
            return parseInt(prop) < 1
        }
        
        const isLessThanMinusOne = (prop) => {
            return parseInt(prop) < -1
        }
        
        const isNotANumber = (prop) => {
            return isNaN(parseInt(prop));
        }
        
        const isNotEmpty = (prop) => {
            if (typeof prop === "string"){
                return prop.length > 0;
            }
            if (typeof prop === "number"){
                return 1;
            }
            return 0;
        }
        
        if (!valid){
            console.warn("Ressource invalid");
            setFormRessourceValid(false);
        }else if (!ressource?.title?.length){
            console.warn("Title must be set");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.available_months) || isLessThanZero(ressource?.available_months)){
            console.warn("Invalid available_months field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.available_days) || isLessThanZero(ressource?.available_days)){
            console.warn("Invalid available_days field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.interval_value) || isLessThanOne(ressource?.interval_value)) {
            console.warn("Invalid interval_value field");
            setFormRessourceValid(false);
        }else if (!["days", "nights", "hours", "minutes"].find(it => it === ressource?.interval_type)) {
            console.warn("Invalid interval_type field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.unit_min) || isLessThanOne(ressource?.unit_min)) {
            console.warn("Invalid unit_min field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.unit_max) || isLessThanOne(ressource?.unit_max)) {
            console.warn("Invalid unit_max field");
            setFormRessourceValid(false);
        }else if (ressource?.unit_max < ressource?.unit_min) {
            console.warn("unit_max must be greater or egal to unit_min");
            setFormRessourceValid(false);
        }else if (isNotEmpty(ressource?.max_rolling_bookings) && isNotANumber(ressource?.max_rolling_bookings)) {
            console.warn("Invalid max_rolling_bookings field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.available_units) || isLessThanOne(ressource?.available_units)){
            console.warn("Invalid available_units field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.unit_self_delay) || isLessThanZero(ressource?.unit_self_delay)){
            console.warn("Invalid unit_self_delay field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.unit_users_delay) || isLessThanZero(ressource?.unit_users_delay)){
            console.warn("Invalid unit_users_delay field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.cancel_delay_hr) || isLessThanZero(ressource?.cancel_delay_hr)) {
            console.warn("Invalid cancel_delay_hr field");
            setFormRessourceValid(false);
        }else if (isNotANumber(ressource?.booking_delay_hr) || isLessThanMinusOne(ressource?.booking_delay_hr)) {
            console.warn("Invalid booking_delay_hr field");
            setFormRessourceValid(false);
        }else{
            console.log("Form is valid");
            setFormRessourceValid(true);
        }
    };

    const deleteRessource = async uuid => {
        await reservationRessourceServices.destroy(uuid);
        openNotification(
            `La ressource ${
                ressourcesList.find(it => it.uuid === uuid)?.title
            } a été supprimé`,
            "success"
        );
        refreshData();
    };
    
    const fetchSectors = async () => {
        const sectors = await sectorServices.getAll();
        if (sectors) {
            setFormSectorList(sectors);
        }
    };

    const toggleModalLocation = (location = null) => {
        fetchSectors();
        setFormLocationValid(false);
        setModalLocationType(location ? true : false);
        const locationInfo = {
            uuid: location?.uuid,
            title: location?.title ? location?.title : "",
            details: location?.details ? location?.details : "",
            sector: location?.sectorId,
            image: null,
            has_picture: location?.has_picture ? location?.has_picture : false,
        };
        setNewLocationInfo(locationInfo);
        setLocationModalOpened(!locationModalOpened);
    };

    const submitNewLocation = async event => {
        event.preventDefault();
        const locationInfo = {
            title: newLocationInfo.title,
            details: newLocationInfo.details,
            sectorId: newLocationInfo.sector,
        };
        let res;
        if (newLocationInfo.uuid) {
            res = await reservationLocationServices.update(
                newLocationInfo.uuid,
                locationInfo
            );
            if (res) {
                openNotification(
                    `L'emplacement ${res?.title} a été modifié`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de la modification de l'emplacement ${newLocationInfo?.title}`,
                    "danger"
                );
            }
        } else {
            res = await reservationLocationServices.add(locationInfo);
            if (res) {
                openNotification(`L'emplacement ${res?.title} a été ajouté`, "success");
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de l'ajout de l'emplacement ${newLocationInfo?.title}`,
                    "danger"
                );
            }
        }
        if (newLocationInfo.image && res) {
            let data = new FormData();
            data.append("file", newLocationInfo.image);
            await reservationLocationServices.setImage(res.uuid, data);
            refreshImgLocation(res.uuid);
        }
        refreshData();
        setLocationModalOpened(!locationModalOpened);
        setFormLocationValid(true);
    };

    const handleLocationFormChange = (key, value) => {
        const locationInfo = {...newLocationInfo};
        locationInfo[key] = value;
        setNewLocationInfo(locationInfo);
        isFormLocationValid(locationInfo);
    };

    const isFormLocationValid = location => {
        if (!location?.title?.length) setFormLocationValid(false);
        else setFormLocationValid(true);
    };

    const deleteLocation = async uuid => {
        await reservationLocationServices.destroy(uuid);
        openNotification(
            `L'emplacement ${
                locationList.find(it => it.uuid === uuid)?.title
            } a été supprimé`,
            "success"
        );
        refreshData();
    };

    const requestNewIcsKey = async uuid => {
        const res = await reservationCleaningServices.renewIcsKey(uuid);
        if (res) {
            const cleaningInfo = {...newCleaningInfo};
            cleaningInfo.ics_key = res?.ics_key;
            cleaningInfo.dialog = !cleaningInfo.dialog;
            setNewCleaningInfo(cleaningInfo);
            setIsKeyRegenerate(true);
            isFormCleaningValid(cleaningInfo);
        }
    };

    const toggleModalCleaning = (cleaning = null) => {
        if (isKeyRegenerate) {
            setIsKeyRegenerate(false);
            refreshData();
        }
        setFormCleaningValid(false);
        setModalCleaningType(cleaning ? true : false);
        const cleaningInfo = {
            uuid: cleaning?.uuid,
            title: cleaning?.title ? cleaning?.title : "",
            email: cleaning?.email ? cleaning?.email : "",
            email_cc: cleaning?.email_cc ? cleaning?.email_cc : "",
            managerFirstName: cleaning?.managerFirstName ? cleaning?.managerFirstName : "",
            managerLastName: cleaning?.managerLastName ? cleaning?.managerLastName : "",
            landlinePhoneNumber: cleaning?.landlinePhoneNumber ? cleaning?.landlinePhoneNumber : "",
            mobilePhoneNumber: cleaning?.mobilePhoneNumber ? cleaning?.mobilePhoneNumber : "",
            sendSMSNotification: cleaning?.sendSMSNotification ? cleaning?.sendSMSNotification : 0,
            SIREN: cleaning?.SIREN ? cleaning?.SIREN : "",
            addressStreet: cleaning?.addressStreet ? cleaning?.addressStreet : "",
            addressCity: cleaning?.addressCity ? cleaning?.addressCity : "",
            addressZipcode: cleaning?.addressZipcode ? cleaning?.addressZipcode : "",
            working_days: cleaning?.working_days ? cleaning?.working_days : "0000000",
            ics_key: cleaning?.ics_key ? cleaning?.ics_key : undefined,
            dialog: false,
        };
        setNewCleaningInfo(cleaningInfo);
        setCleaningModalOpened(!cleaningModalOpened);
    };

    const submitNewCleaning = async event => {
        event.preventDefault();
        const cleaningInfo = {
            title: newCleaningInfo.title,
            email: newCleaningInfo.email,
            email_cc: newCleaningInfo.email_cc,
            managerFirstName: newCleaningInfo.managerFirstName,
            managerLastName: newCleaningInfo.managerLastName,
            landlinePhoneNumber: newCleaningInfo.landlinePhoneNumber,
            mobilePhoneNumber: newCleaningInfo.mobilePhoneNumber,
            sendSMSNotification: newCleaningInfo.sendSMSNotification,
            SIREN: newCleaningInfo.SIREN,
            addressStreet: newCleaningInfo.addressStreet,
            addressCity: newCleaningInfo.addressCity,
            addressZipcode: newCleaningInfo.addressZipcode,
            working_days: newCleaningInfo.working_days,
        };
        if (newCleaningInfo.uuid) {
            if (
                await reservationCleaningServices.update(
                    newCleaningInfo.uuid,
                    cleaningInfo
                )
            ) {
                openNotification(
                    `Le ménage ${newCleaningInfo?.title} a été modifié`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de la modification du ménage ${newCleaningInfo?.title}`,
                    "danger"
                );
            }
        } else {
            if (await reservationCleaningServices.add(cleaningInfo)) {
                openNotification(
                    `Le ménage ${newCleaningInfo?.title} a été ajouté`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de l'ajout du ménage ${newCleaningInfo?.title}`,
                    "danger"
                );
            }
        }
        refreshData();
        setCleaningModalOpened(!cleaningModalOpened);
        setFormCleaningValid(true);
    };

    const handleCleaningFormChange = (key, value, idx = 0) => {
        const cleaningInfo = {...newCleaningInfo};
        if (key === "dialog") {
            cleaningInfo[key] = !cleaningInfo[key];
        } else if (key === "recurrence") {
            cleaningInfo.working_days =
                cleaningInfo.working_days.substring(0, idx) +
                (value ? "1" : "0") +
                cleaningInfo.working_days.substring(idx + 1);
        } else {
            cleaningInfo[key] = value;
        }
        setNewCleaningInfo(cleaningInfo);
        if (key !== "dialog") isFormCleaningValid(cleaningInfo);
    };

    const isFormCleaningValid = cleaning => {
        if (isKeyRegenerate) {
            setFormCleaningValid(true);
            return;
        }
        if (!cleaning?.title?.length || !cleaning?.email?.length)
            setFormCleaningValid(false);
        else setFormCleaningValid(true);
    };

    const deleteCleaning = async uuid => {
        await reservationCleaningServices.destroy(uuid);
        openNotification(
            `Le ménage ${
                cleaningList.find(it => it.uuid === uuid)?.title
            } a été supprimé`,
            "success"
        );
        refreshData();
    };

    const toggleModalA1001 = (a1001 = null) => {
        setFormA1001Valid(false);
        setModalA1001Type(a1001 ? true : false);
        const a1001Info = {
            uuid: a1001?.uuid,
            title: a1001?.title ? a1001?.title : "",
            host: a1001?.host ? a1001?.host : "",
            port: a1001?.port ? a1001?.port : "",
            is_weak_cert: typeof a1001?.is_weak_cert === "boolean" ? a1001?.is_weak_cert : false,
            username: a1001?.username ? a1001?.username : "",
            password: a1001?.password ? a1001?.password : "",
            showPassword: false,
        };
        setNewA1001Info(a1001Info);
        setA1001ModalOpened(!a1001ModalOpened);
    };

    const submitNewA1001 = async event => {
        event.preventDefault();
        const a1001Info = {
            title: newA1001Info.title,
            host: newA1001Info.host,
            port: newA1001Info.port,
            is_weak_cert: newA1001Info.is_weak_cert,
            username: newA1001Info.username,
            password: newA1001Info.password,
        };
        if (newA1001Info.uuid) {
            if (await reservationA1001Services.update(newA1001Info.uuid, a1001Info)) {
                openNotification(
                    `L'A1001 ${newA1001Info?.title} a été modifié`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de la modification du A1001 ${newA1001Info?.title}`,
                    "danger"
                );
            }
        } else {
            if (await reservationA1001Services.add(a1001Info)) {
                openNotification(
                    `L'A1001 ${newA1001Info?.title} a été ajouté`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de l'ajout du A1001 ${newA1001Info?.title}`,
                    "danger"
                );
            }
        }
        refreshData();
        setA1001ModalOpened(!a1001ModalOpened);
        setFormA1001Valid(true);
    };

    const handleA1001FormChange = (key, value, idx = 0) => {
        const a1001Info = {...newA1001Info};
        if (key === "showPassword") {
            a1001Info[key] = !a1001Info[key];
        } else {
            a1001Info[key] = value;
        }
        setNewA1001Info(a1001Info);
        isFormA1001Valid(a1001Info);
    };

    const isFormA1001Valid = a1001 => {
        if (
            !a1001?.title?.length ||
            !a1001?.host?.length ||
            !a1001?.username?.length ||
            !a1001?.password?.length ||
            isNaN(parseInt(a1001?.port)) ||
            parseInt(a1001?.port) < 0
        )
            setFormA1001Valid(false);
        else setFormA1001Valid(true);
    };

    const deleteA1001 = async uuid => {
        await reservationA1001Services.destroy(uuid);
        openNotification(
            `L'A1001 ${a1001List.find(it => it.uuid === uuid)?.title} a été supprimé`,
            "success"
        );
        refreshData();
    };

    const fetchRessourceDoors = async () => {
        const a1001 = await reservationA1001Services.getAll();
        if (a1001) {
            setFormA1001List(a1001);
        }
        const location = await reservationLocationServices.getAll();
        if (location) {
            setFormLocationDoorsList(location);
        }
    };

    const toggleModalA1001Doors = (door = null) => {
        fetchRessourceDoors();
        setFormDoorsValid(false);
        setModalDoorsType(door ? true : false);
        const doorInfo = {
            uuid: door?.uuid,
            title: door?.title ? door?.title : "",
            accessPointToken: door?.accessPointToken ? door?.accessPointToken : "",
            doorToken: door?.doorToken ? door?.doorToken : "",
            fastOpen: door?.fastOpen ? door?.fastOpen : 0,
            a1001: door?.a1001?.uuid ? door?.a1001?.uuid : "",
            location: door?.locations ? door?.locations.map(it => it.uuid) : [],
        };
        setNewDoorsInfo(doorInfo);
        setDoorsModalOpened(!doorsModalOpened);
    };

    const submitNewDoors = async event => {
        event.preventDefault();
        const doorInfo = {
            title: newA1001DoorsInfo.title,
            doorToken: newA1001DoorsInfo.doorToken,
            accessPointToken: newA1001DoorsInfo.accessPointToken,
            fastOpen: newA1001DoorsInfo.fastOpen
        };
        if (newA1001DoorsInfo.uuid) {
            const doors = doorsList.find(it => it.uuid === newA1001DoorsInfo.uuid);
            doors.locations
                .filter(
                    location => !newA1001DoorsInfo.location.find(it => it === location.uuid)
                )
                .forEach(
                    async it =>
                        await reservationA1001DoorServices.unsetLocationForDoor(
                            newA1001DoorsInfo.uuid,
                            it.uuid
                        )
                );
            newA1001DoorsInfo.location
                .filter(it => !doors.locations.find(location => location.uuid === it))
                .forEach(
                    async it =>
                        await reservationA1001DoorServices.setLocationToDoor(
                            it,
                            newA1001DoorsInfo.uuid
                        )
                );
            if (doors?.a1001 && doors?.a1001?.uuid !== newA1001DoorsInfo.a1001)
                await reservationA1001DoorServices.unsetA1001ForDoor(newA1001DoorsInfo.uuid);
            if (
                newA1001DoorsInfo.a1001?.length &&
                (!doors?.a1001 || doors?.a1001?.uuid !== newA1001DoorsInfo.a1001)
            )
                await reservationA1001DoorServices.setA1001ToDoor(
                    newA1001DoorsInfo.a1001,
                    newA1001DoorsInfo.uuid
                );
            if (
                await reservationA1001DoorServices.update(newA1001DoorsInfo.uuid, doorInfo)
            ) {
                openNotification(
                    `La porte ${newA1001DoorsInfo?.title} a été modifié`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de la modification de la prote ${newA1001DoorsInfo?.title}`,
                    "danger"
                );
            }
        } else {
            const res = await reservationA1001DoorServices.add(doorInfo);
            if (res) {
                if (newA1001DoorsInfo.a1001?.length)
                    await reservationA1001DoorServices.setA1001ToDoor(
                        newA1001DoorsInfo.a1001,
                        res.uuid
                    );
                for (let x of newA1001DoorsInfo.location) {
                    await reservationA1001DoorServices.setLocationToDoor(x, res.uuid);
                }
                openNotification(
                    `La porte ${newA1001DoorsInfo?.title} a été ajouté`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de l'ajout de la prote ${newA1001DoorsInfo?.title}`,
                    "danger"
                );
            }
        }
        refreshData();
        setDoorsModalOpened(!doorsModalOpened);
        setFormDoorsValid(true);
    };

    const handleDoorsFormChange = (key, value, idx = 0) => {
        const doorInfo = {...newA1001DoorsInfo};
        doorInfo[key] = value;
        setNewDoorsInfo(doorInfo);
        isFormDoorsValid(doorInfo);
    };

    const isFormDoorsValid = door => {
        if (door?.title?.length < 1) return setFormDoorsValid(false);
        return setFormDoorsValid(true);
    };

    const deleteDoors = async uuid => {
        await reservationA1001DoorServices.destroy(uuid);
        openNotification(
            `La porte ${
                doorsList.find(it => it.uuid === uuid)?.title
            } a été supprimé`,
            "success"
        );
        refreshData();
    };

    const getAll2nform = async () => {
        const location = await reservationLocationServices.getAll();
        if (location) {
            setFormLocationList(location);
        }
    };

    const toggleModal2n = (twoN = null) => {
        getAll2nform();
        setFormTwoNValid(false);
        setModalTwoNType(twoN ? true : false);
        const twoNInfo = {
            uuid: twoN?.uuid,
            label: twoN?.label ? twoN?.label : "",
            host: twoN?.host ? twoN?.host : "",
            port: twoN?.port ? twoN?.port : "",
            username: twoN?.username ? twoN?.username : "",
            password: twoN?.password ? twoN?.password : "",
            location: twoN?.location?.uuid ? twoN?.location?.uuid : "",
            showPassword: false,
        };
        setNewTwoNInfo(twoNInfo);
        setTwoNModalOpened(!twoNModalOpened);
    };

    const submitNew2n = async event => {
        event.preventDefault();
        const twoNInfo = {
            label: newTwoNInfo.label,
            host: newTwoNInfo.host,
            port: newTwoNInfo.port,
            username: newTwoNInfo.username,
            password: newTwoNInfo.password,
            free_user_uuid: newTwoNInfo.free_user_uuid,
        };
        if (newTwoNInfo.uuid) {
            if (
                twoNList.find(it => it.uuid === newTwoNInfo.uuid)?.location?.uuid !==
                newTwoNInfo.location
            )
                await reservation2nServices.setLocation(
                    newTwoNInfo.uuid,
                    newTwoNInfo.location
                );
            if (await reservation2nServices.update(newTwoNInfo.uuid, twoNInfo)) {
                openNotification(
                    `Le 2N ${newTwoNInfo?.label} a été modifié`,
                    "success"
                );
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de la modification du 2N ${newTwoNInfo?.label}`,
                    "danger"
                );
            }
        } else {
            const res = await reservation2nServices.add(twoNInfo);
            if (res) {
                await reservation2nServices.setLocation(res.uuid, newTwoNInfo.location);
                openNotification(`Le 2N ${newTwoNInfo?.label} a été ajouté`, "success");
            } else {
                openNotification(
                    `Erreur, une erreur est survenu lors de l'ajout du 2N ${newTwoNInfo?.label}`,
                    "danger"
                );
            }
        }
        refreshData();
        setTwoNModalOpened(!twoNModalOpened);
        setFormTwoNValid(true);
    };

    const handle2nFormChange = (key, value, idx = 0) => {
        const twoNInfo = {...newTwoNInfo};
        if (key === "showPassword") {
            twoNInfo[key] = !twoNInfo[key];
        } else {
            twoNInfo[key] = value;
        }
        setNewTwoNInfo(twoNInfo);
        isForm2nValid(twoNInfo);
    };

    const isForm2nValid = twoN => {
        if (
            !twoN?.label?.length ||
            !twoN?.host?.length ||
            !twoN?.username?.length ||
            !twoN?.password?.length ||
            isNaN(parseInt(twoN?.port)) ||
            parseInt(twoN?.port) < 0 ||
            !twoN?.location?.length
        )
            setFormTwoNValid(false);
        else setFormTwoNValid(true);
    };

    const delete2n = async uuid => {
        await reservation2nServices.destroy(uuid);
        openNotification(
            `Le 2N ${twoNList.find(it => it.uuid === uuid)?.label} a été supprimé`,
            "success"
        );
        refreshData();
    };

    return {
        a1001List,
        a1001Count,
        a1001ModalOpened,
        bookingDestroy,
        cleaningCount,
        cleaningList,
        cleaningModalOpened,
        doorsList,
        doorsCount,
        doorsModalOpened,
        formTwoNValid,
        formA1001List,
        formA1001Valid,
        formCleaningList,
        formCleaningValid,
        formDoorsValid,
        formLocationDoorsList,
        formLocationList,
        formLocationValid,
        formModulesList,
        formRessourceValid,
        formRulesList,
        formSectorList,
        formTypeList,
        limits,
        locationRecurrence,
        locationCount,
        locationList,
        locationModalOpened,
        modalTwoNType,
        modalA1001Type,
        modalCleaningType,
        modalDoorsType,
        modalLocationType,
        modalRessourceType,
        newTwoNInfo,
        newA1001Info,
        newCleaningInfo,
        newA1001DoorsInfo,
        newLocationInfo,
        newRessourceInfo,
        notifColor,
        notifMessage,
        openNotif,
        pageTwoN,
        pageA1001,
        pageCleaning,
        pageDoors,
        pageLocation,
        pageRessource,
        periodsList,
        refresh,
        refreshImageLocation,
        refreshImageRessource,
        ressourcesCount,
        ressourcesList,
        ressourceRecurrence,
        ressourceModalOpened,
        twoNCount,
        twoNList,
        twoNModalOpened,
        addPeriodToRessource,
        updatePeriodFromRessource,
        deletePeriodFromRessource,
        deleteBooking,
        getTitleInterval,
        handle2nFormChange,
        handleA1001FormChange,
        handleCleaningFormChange,
        handleDoorsFormChange,
        handleLocationFormChange,
        handlePagination2n,
        handlePaginationA1001,
        handlePaginationCleaning,
        handlePaginationDoors,
        handlePaginationLocation,
        handlePaginationRessource,
        handleRessourceFormChange,
        modalMessageBooking,
        parseHourTime,
        refreshData,
        requestNewIcsKey,
        setBookingState,
        setOpenNotif,
        setPeriodsList,
        submitNew2n,
        submitNewA1001,
        submitNewCleaning,
        submitNewDoors,
        submitNewLocation,
        submitNewRessource,
        toggleConfirmBookingDestroy,
        toggleModal2n,
        toggleModalA1001,
        toggleModalCleaning,
        toggleModalA1001Doors,
        toggleModalLocation,
        toggleModalRessource,
    };
};

export default BookingsSettingsLogic;
