import { useEffect, useState, useCallback } from "react";
import {
  fetchLogs, updateLog, bulkUpdateLog, getScreenshot, getCamShot
} from "../../services";

const subTypes = [
  "SocketFailure",
  "UserAccessFailure",
  "BIDNotInDB",
  "BoxOpenFailure",
  "DatabaseInconstancy",
  "DatabaseUncomplete",
  "CanUnplugged",
  "ScreenTouchDown",
  "OubaboxLogin",
  "OubaboxLogout",
  "OubaboxScreenShot",
  "OubaboxRefresh",
  "OubaboxReboot",
  "BoxParcelDrop",
  "BoxEnable",
  "BoxOpen",
  "DeliveryPickUp",
  "DeliveryCancel"
];

export function LogLogic(oubaboxsList, oubaboxUUID, defaultData) {
  const [refresh,        setRefresh]        = useState();
  const [logsList,       setLogsList]       = useState();
  const [oubaboxsFilter, setOubaboxsFilter] = useState([ oubaboxUUID ]);
  const [subTypesFilter, setSubTypesFilter] = useState(subTypes);
  const [typesFilter,    setTypesFilter]    = useState(["failure", ...(defaultData ? [] : ["success", "info", "image"])]);
  const [activeFilter,   setActiveFilter]   = useState([ 1, ...(defaultData ? [] : [0])]);
  const [page,           setPage]           = useState(0);
  const [order,          setOrder]          = useState("desc");
  const [orderBy,        setOrderBy]        = useState("createdAt");
  const [selected,       setSelected]       = useState([]);
  const [boxsFilter,     setBoxsFilter]     = useState(!defaultData && [].concat(
    ...oubaboxsList?.filter(oubabox => oubaboxsFilter.some(filter => filter === oubabox.uuid))?.map(
  ({ boxes }) => boxes))?.map(({ uuid }) => uuid) || []);
  //const [boxsFilter,     setBoxsFilter]     = useState([]);

  // Reselect all boxes when a new oubaboxFilter is selectionned
  useEffect(() => {
    // TODO: exclude previously unchecked boxes when adding another Oubabox
    !defaultData && setBoxsFilter([].concat(...oubaboxsList?.filter(oubabox =>
      oubaboxsFilter.some(filter => filter === oubabox.uuid)
    )?.map(({ boxes }) => boxes))?.map(({ uuid }) => uuid) || []);
  }, [oubaboxsFilter]);

  // Refetch all logs each 2000ms
  useEffect(() => {
    const interval = setInterval(() => getLogs(), 2000);
    return () => clearInterval(interval);
  });

  // Refetch logs when a filter or page is modified
  useEffect(() => {
    getLogs();
  }, [getLogs, oubaboxsFilter, refresh, page, boxsFilter, typesFilter, subTypesFilter, activeFilter]);

  // Retrieve the list of logs
  const getLogs = useCallback(async () => {
    const logs = await fetchLogs(`${[
      oubaboxsFilter?.length && `oubaboxs=${oubaboxsFilter}`,
      boxsFilter?.length     && `boxes=${boxsFilter}`,
      subTypesFilter?.length && `subTypes=${subTypesFilter}`,
      typesFilter?.length    && `types=${typesFilter}`,
      activeFilter !== null  && `isActive=${activeFilter}`
    ].filter(_ => _).join('&')}`);
    logs && setLogsList(logs);
  }, [activeFilter, oubaboxsFilter, boxsFilter, typesFilter, subTypesFilter]);

  // Select or unselect logs row when clicking on it
  const handleClick = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    if (selectedIndex === -1)
      setSelected([ ...selected, id ]);
    else if (selectedIndex === 0)
      setSelected([ ...selected.slice(1) ]);
    else if (selectedIndex === selected.length - 1)
      setSelected([ ...selected.slice(0, -1) ]);
    else if (selectedIndex > 0)
      setSelected([
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1)
      ]);
  };

  // Mark a log as resolved
  const resolveLog = async ({ uuid, id }, isActive) => {
    await updateLog(uuid, { isActive });
    setLogsList(logsList.map(e => ({
      ...e, ...(e.uuid === uuid ? { isActive: isActive } : {})
    })));
    setSelected(selected?.filter(e => e !== id));
  };

  const bulkResolveLog = async (isActive) => {
    await bulkUpdateLog({
      list: logsList.filter(log =>
        selected.includes(log.id)
      ).map(log => log.uuid),
      isActive
    });
    setLogsList([
      ...logsList?.filter(e => !selected.includes(e.id)),
      ...logsList?.filter(e => 
        selected.includes(e.id)
      ).map(e => ({
        ...e, isActive: isActive
      }))
    ]);
    setSelected([]);
  };
  
  const handleRequestSort = (_, property) => {
    setOrder((orderBy === property && order === "asc") ? "desc" : "asc");
    setOrderBy(property);
  };

  // Select or unselect all logs
  const handleSelectAllClick = (event) => setSelected([
    ...(event.target.checked && !selected.length) && logsList?.map(log => log.id) || [],
  ]);

  // TODO: to delete
  const submitNewLog = async event => {
    event.preventDefault();
    refreshData();
  };

  const isSelected       = id   => selected.indexOf(id) !== -1;
  const refreshData      = ()   => setRefresh(!refresh);
  const handlePagination = page => setPage(page);
  const handleFilter     = (type, item) => {
    type === "oubabox" && setOubaboxsFilter(item);
    type === "subType" && setSubTypesFilter(item);
    type === "active"  && setActiveFilter(item);
    type === "type"    && setTypesFilter(item);
    type === "box"     && setBoxsFilter(item);
  };

  return {
    page,
    logsList,
    handleFilter,
    handlePagination,
    refreshData,
    submitNewLog,
    oubaboxsFilter,
    boxsFilter,
    subTypesFilter,
    typesFilter,
    handleRequestSort,
    activeFilter,
    handleClick,
    getCamShot,
    getScreenshot,
    resolveLog,
    bulkResolveLog,
    handleSelectAllClick,
    isSelected,
    subTypes,
    selected,
    order,
    orderBy
  };
}
