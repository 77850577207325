import React, { memo } from "react";
import PropTypes       from "prop-types";
import Button          from "components/CustomButtons/Button.js";
import GridItem        from "components/Grid/GridItem.js";
import GridContainer   from "components/Grid/GridContainer.js";
import CardHeader      from "components/Card/CardHeader.js";
import userStyles      from "assets/jss/material-dashboard-react/views/userStyle.js";

import {
  MenuItem,
  Checkbox,
  InputLabel,
  makeStyles,
  TextField,
  Select
} from "@material-ui/core";

const useStyles = makeStyles({
  ...userStyles(),
  formControlRoot: {
    fontFamily:   "futura",
    color:        "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color:      "#6E6FA9"
    }
  },
  inputLabelRoot: {
    color:        "#6E6FA9",
    fontFamily:   "futura",
    "&.focused":  {
      color:      "#6E6FA9"
    }
  }
});

const Filters = ({
  oubabox,
  oubaboxsList,
  boxsFilter,
  oubaboxsFilter,
  typesFilter,
  subTypesFilter,
  activeFilter,
  handleFilter,
  statusFilter,
  statesFilter,
  incidentsFilter,
  availableFilter,
  labelFilter,
  subTypes
}) => {
  const classes = useStyles();
  const activeTab = [
    { name: "Non-Résolu", value: 0 },
    { name: "Résolu",     value: 1 }
  ];
  const boxes = oubaboxsList ? [].concat(...oubaboxsList?.filter(oubabox => 
    oubaboxsFilter.some(filter => filter === oubabox.uuid)
  )?.map(({ boxes }) => boxes)) : [];

  return !oubabox ? (
    <>
      <div>
        <InputLabel style={{ width: "65px" }}>
          État
        </InputLabel>
        <Select
          multiple
          displayEmpty
          value={activeFilter}
          renderValue={() => activeFilter.length === 2
            ? "Tous" :       activeFilter.length === 0
              ? "Aucun"
              : activeTab[activeFilter[0]]?.name}
          onChange={({ target }) => handleFilter("active", target.value)}
        >
          {activeTab.map((i, index) => (
            <MenuItem
              key={index}
              value={i.value}
            >
              <Checkbox checked={activeFilter.indexOf(i.value) > -1} />
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <InputLabel style={{ marginLeft: "10px" }}>
          Oubabox
        </InputLabel>
        <Select
          multiple
          displayEmpty
          value={oubaboxsFilter}
          onChange={({ target }) => target.value?.includes(null) || handleFilter("oubabox", target.value)}
          renderValue={o => o.length
            ? `${o.length} ${o.length > 1
              ? "Oubabox séléctionées" : "Oubabox séléctionée"}`
            : "Ajouter une Oubabox"}
        >
          <MenuItem
            value={null}
            onClick={() =>  handleFilter("oubabox", 
              oubaboxsFilter?.length !== oubaboxsList?.length ? oubaboxsList.map(({ uuid }) => uuid) : []
            )}>
            <Checkbox checked={oubaboxsFilter?.length === oubaboxsList?.length} />
            {oubaboxsFilter?.length !== oubaboxsList?.length ? "Tous" : "Aucun"}
          </MenuItem>
          {oubaboxsList?.map(oubabox => (
            <MenuItem
              key={oubabox.id}
              value={oubabox.uuid}
            >
              <Checkbox checked={oubaboxsFilter.indexOf(oubabox.uuid) > -1} />
              {oubabox.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <InputLabel id="label">
          Casiers
        </InputLabel>
        <Select
          multiple
          displayEmpty
          value={boxsFilter}
          onChange={({ target }) => target.value?.includes(null) || handleFilter("box", target.value)}
          renderValue={b => b.length
            ? `${b.length} ${b.length > 1
              ? "Casiers séléctionés" : "Casier séléctioné"}`
            : "Ajouter un Casier"}
        >
          <MenuItem
            value={null}
            onClick={() =>  handleFilter("box", boxsFilter?.length !== boxes?.length
              ? boxes.map(({ uuid }) => uuid) : []
            )}>
            <Checkbox checked={boxsFilter.length === boxes.length} />
            {boxsFilter.length !== boxes.length ? "Tous" : "Aucun"}
          </MenuItem>
          {boxes.map(box => (
            <MenuItem
              key={box.id}
              value={box.uuid}
            >
              <Checkbox checked={boxsFilter.indexOf(box.uuid) > -1} />
              {box.bid}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <InputLabel id="label">
          Type
        </InputLabel>
        <Select
          multiple
          displayEmpty
          value={typesFilter}
          onChange={({ target }) => handleFilter("type", target.value)}
          renderValue={t => t.length
            ? `${t.length} ${t.length > 1
              ? "Type séléctionés" : "Type séléctioné"}`
            : "Ajouter un type"}
        >
          {["failure", "success", "info", "image"].map((type, index) => (
            <MenuItem
              key={index}
              value={type}
            >
              <Checkbox checked={typesFilter.indexOf(type) > -1} />
              {type}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <InputLabel id="label">
          Sous-Type
        </InputLabel>
        <Select
          multiple
          displayEmpty
          value={subTypesFilter}
          onChange={({ target }) => target.value?.includes(null) || handleFilter("subType", target.value)}
          renderValue={b => b.length
            ? `${b.length} ${b.length > 1
              ? "Sous-types séléctionés" : "Sous-Type séléctioné"}`
            : "Ajouter un Sous-Type"}
        >
          <MenuItem
            value={null}
            onClick={() =>  handleFilter("subType",
              subTypesFilter?.length !== subTypes?.length ? subTypes : []
            )}>
            <Checkbox checked={subTypesFilter.length === subTypes.length} />
            {subTypesFilter.length !== subTypes.length ? "Tous" : "Aucun"}
          </MenuItem>
          {subTypes.map((subType, index) => (
            <MenuItem
              key={index}
              value={subType}
            >
              <Checkbox checked={subTypesFilter.indexOf(subType) > -1} />
              {subType}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  ) : (
    <CardHeader
      style={{
        borderRadius: 1,
        paddingTop: 0,
        border: "none",
        boxShadow:
          "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
      }}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            variant="standard"
            classes={{ root: classes.formControlRoot }}
            label="Nom"
            inputProps={{
              className: classes.input,
              onChange: event => handleFilter("label", event.target.value)
            }}
            InputLabelProps={{
              classes: { root: classes.inputLabelRoot }
            }}
            value={labelFilter}
          />
        </GridItem>
        <GridItem style={{ marginLeft: "10px" }}>
          <InputLabel id="label" className={classes.inputLabelRoot}>
            État
          </InputLabel>
          <Select
            multiple
            displayEmpty
            classes={{ root: classes.formControlRoot }}
            value={statesFilter}
            onChange={({ target }) => handleFilter("state", target.value)}
            renderValue={s => s.length
              ? `${s.length} ${s.length > 1
                ? "État séléctionés" : "État séléctioné"}`
              : "Ajouter un État"
          }>
            <MenuItem value={null}>
              <em>
                Filtrer par État
              </em>
            </MenuItem>
            {[
              "Déconnecté",
              "Disponible",
              "En Usage",
              "En Maintenance"
            ].map((state, index) => (
              <MenuItem
                key={index}
                value={index}
              >
                <Checkbox checked={statesFilter.indexOf(index) > -1} />
                {state}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <GridItem style={{ marginLeft: "10px" }}>
          <InputLabel id="label" className={classes.inputLabelRoot}>
            Disponibilité
          </InputLabel>
          <Select
            multiple
            displayEmpty
            classes={{ root: classes.formControlRoot }}
            value={availableFilter}
            onChange={({ target }) => handleFilter("available", target.value)}
            renderValue={s => s.length ? s?.map(i => 
              ["Livré", "Non-Livré"][i])?.join(' et ') : "Aucunne"
          }>
            <MenuItem value={null}>
              <em>
                Filtrer par Casier
              </em>
            </MenuItem>
            {[
              "Livré",
              "Non-Livré"
            ].map((availability, index) => (
              <MenuItem
                key={index}
                value={index}
              >
                <Checkbox checked={availableFilter.indexOf(index) > -1} />
                {availability}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
        <GridItem style={{ marginLeft: "10px" }}>
          <InputLabel id="label" className={classes.inputLabelRoot}>
            Nombre d'incidents
          </InputLabel>
          <Select
            multiple
            displayEmpty
            classes={{ root: classes.formControlRoot }}
            value={incidentsFilter}
            onChange={({ target }) => handleFilter("incident", target.value)}
            renderValue={s => s}
          >
            <MenuItem value={null}>
              <em>
                {true ? "Tous" : "Aucun"}
              </em>
            </MenuItem>
            {[
              "Aucun",
              ">= 1"
            ].map((incident, index) => (
              <MenuItem
                key={index}
                value={index}
              >
                <Checkbox checked={incidentsFilter.indexOf(index) > -1} />
                {incident}
              </MenuItem>
            ))}
          </Select>
        </GridItem>
      </GridContainer>
    </CardHeader>
  );
};

Filters.propTypes = {
  oubabox:         PropTypes.bool,
  oubaboxsList:    PropTypes.array,
  boxsFilter:      PropTypes.array,
  oubaboxsFilter:  PropTypes.array,
  typesFilter:     PropTypes.array,
  subTypesFilter:  PropTypes.array,
  statusFilter:    PropTypes.array,
  statesFilter:    PropTypes.array,
  availableFilter: PropTypes.array,
  labelFilter:     PropTypes.array,
  handleFilter:    PropTypes.func,
  activeFilter:    PropTypes.any,
  subTypes:        PropTypes.array
};

export default memo(Filters);
