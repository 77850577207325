import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import localization from "moment/locale/fr";

import {
  reservationRfidServices,
  reservationRessourceServices,
} from "../../services";

moment.updateLocale("fr", localization);

const RfidLogic = () => {
  const { myself } = useSelector(userState => userState.myself);

  const [refresh, setRefresh] = useState(false);

  const limitRfid = 15
  const limitTag = 10
  const limitSensorTag = 4

  const [rfidState, setRfidState] = useState("sensor");

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [rfidList, setRfidList] = useState(undefined)
  const [rfidCount, setRfidCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newRfidInfo, setNewRfidInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [pageTag, setPageTag] = useState([])
  const [pageSensorTag, setPageSensorTag] = useState(1)
  const [allTagsList, setAllTagsList] = useState(undefined)
  const [tagsList, setTagsList] = useState(undefined)
  const [tagsCount, setTagsCount] = useState(undefined)

  const [editTagModalOpened, setEditTagModalOpened] = useState(false);
  const [newTagInfo, setNewTagInfo] = useState(undefined);
  const [formValidTag, setFormValidTag] = useState(false);
  const [modalTypeTag, setModalTypeTag] = useState(false);
  const [formRessourceList, setFormRessourceList] = useState(undefined);
  const [formSensorList, setFormSensorList] = useState(undefined);

  const [rfidDestroy, setRfidDestroy] = useState(undefined);
  const [rfidDestroyType, setRfidDestroyType] = useState(undefined);

  const toggleConfirmRfidDestroy = (type, rfid) => {
    setRfidDestroyType(type);
    setRfidDestroy(rfid);
  };

  useEffect(() => {
    (async () => {
      const rfid = await reservationRfidServices.getAll()
      if (rfidState === "sensor") {
        if (rfid) {
          setRfidCount(rfid.length)
          setRfidList(rfid.slice(limitRfid * (page - 1), limitRfid * (page - 1) + limitRfid))
        }
      } else if (rfidState === "tag") {
        const tag = await reservationRfidServices.getAllTag()
        if (tag && rfid) {
          setAllTagsList(tag)
          const t = rfid.map(it => ({ sensor: it, tag: [] }))
          tag.forEach(it => {
            if (!it?.rfid_reader)
              return
            const elem = t.find(x => x?.sensor?.uuid === it?.rfid_reader?.uuid)
            if (elem && elem?.tag) {
              return elem?.tag.push(it)
            }
          })
          setTagsCount(t.length)
          const tagPaginate = t.slice(limitSensorTag * (pageSensorTag - 1), limitSensorTag * (pageSensorTag - 1) + limitSensorTag)
          setTagsList(tagPaginate)
          setPageTag(tagPaginate.map(it => ({ sensor: it.sensor.uuid, count: it.tag.length, page: 1, date: moment() })))
        }
      }
    })();
  }, [refresh, page, pageSensorTag, rfidState]);

  const refreshSensorTag = async (sensor) => {
    const tag = await reservationRfidServices.getAllTag()
    const rfid = await reservationRfidServices.getAll()
    if (tag && rfid?.find(it => it.uuid === sensor)) {
      let sensorToRefresh = tagsList.find(tag => tag?.sensor?.uuid === sensor)
      sensorToRefresh.sensor = rfid.find(it => it.uuid === sensor)
      sensorToRefresh.tag = tag.filter(it => it.rfid_reader?.uuid === sensor)
      setTagsList(tagsList.map(it => {
        if (it.sensor.uuid === sensor)
          return sensorToRefresh
        return it
      }))
      setPageTag(pageTag.map(it => {
        if (it.sensor === sensor)
          return {
            sensor: sensor,
            count: sensorToRefresh.tag.length,
            page: Math.min(sensorToRefresh.tag.length, it.page),
            date: moment()
          }
        return it
      }))
    }
  }

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const handlePaginationTag = (page, idx) => {
    const p = pageTag.slice(0)
    p[idx].page = page
    setPageTag(p);
  };

  const handlePaginationSensorTag = page => {
    setPageSensorTag(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deleteRfid = async uuid => {
    if (rfidDestroyType === "sensor") {
      deleteSensor(uuid)
    } else if (rfidDestroyType === "tag") {
      deleteTag(uuid)
    }
    toggleConfirmRfidDestroy(null)
  };

  const modalMessageRfid = () => {
    if (rfidDestroyType === "sensor") {
      return `Supprimer l'antenne "${rfidDestroy?.title}" ?`;
    } else if (rfidDestroyType === "tag") {
      return `Supprimer l'étiquette "${rfidDestroy?.title}" ?`;
    }
  };

  const deleteSensor = async uuid => {
    await reservationRfidServices.destroy(uuid)
    openNotification(`L'antenne ${rfidList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    refreshData()
  };

  const deleteTag = async uuid => {
    await reservationRfidServices.destroyTag(uuid)
    openNotification(`L'étiquette ${tagsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    refreshData()
  };

  const toggleModal = async (rfid = null) => {
    setFormValid(false);
    setModalType(rfid ? true : false);
    const rfidInfo = {
      uuid: rfid?.uuid,
      title: rfid?.title ? rfid?.title : '',
      host: rfid?.host ? rfid?.host : '',
      port: rfid?.port ? rfid?.port : '',
      username: rfid?.username ? rfid?.username : '',
      password: rfid?.password ? rfid?.password : '',
      showPassword: false,
    };
    setNewRfidInfo(rfidInfo);
    setEditModalOpened(!editModalOpened);
  };

  const isFormValid = rfidInfo => {
    if (!rfidInfo?.title?.length || !rfidInfo?.host?.length ||
      !rfidInfo?.username?.length || !rfidInfo?.password?.length ||
      isNaN(parseInt(rfidInfo?.port)) ||
      parseInt(rfidInfo?.port) < 0) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  const handleFormChange = (key, value) => {
    let rfidInfo = { ...newRfidInfo };
    if (key === "showPassword") {
      rfidInfo[key] = !rfidInfo[key];
    } else {
      rfidInfo[key] = value;
    }
    setNewRfidInfo(rfidInfo);
    isFormValid(rfidInfo);
  };

  const submitNewRfid = async event => {
    event.preventDefault();
    setFormValid(false);
    const rfidInfo = {
      ...newRfidInfo
    };
    if (newRfidInfo.uuid) {
      if (await reservationRfidServices.update(newRfidInfo.uuid, rfidInfo)) {
        openNotification(`L'antenne ${newRfidInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification de l'antenne ${newRfidInfo?.title}`, 'danger')
      }
    } else {
      if (await reservationRfidServices.add(rfidInfo)) {
        openNotification(`L'antenne ${newRfidInfo?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'antenne ${newRfidInfo?.title}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    setFormValid(true);
    refreshData();
  };

  const getAllRessourceform = async () => {
    const sensor = await reservationRfidServices.getAll()
    if (sensor) {
      setFormSensorList(sensor)
    }
    const ressource = await reservationRessourceServices.getAllRessources()
    if (ressource) {
      setFormRessourceList(ressource)
    }
  };

  const toggleModalTag = async (tag = null) => {
    getAllRessourceform();
    setFormValidTag(false);
    setModalTypeTag(tag ? true : false);
    const tagInfo = {
      uuid: tag?.uuid,
      title: tag?.title ? tag?.title : "",
      tagB64: tag?.tagB64 ? tag?.tagB64 : "OUBAAAAAAAAAAAAA",
      tagId: tag?.tagB64 ? rfidToHex(tag?.tagB64) : ['3940', '4000', '0000', '0000', '0000', '0000'],
      ressource: tag?.ressource?.uuid ? tag?.ressource?.uuid : "",
      sensor: tag?.rfid_reader?.uuid ? tag?.rfid_reader?.uuid : "",
      isCore: typeof tag?.isCore === "boolean" ? tag?.isCore : false,
    };
    if (tag?.tagB64) {
      rfidToAscii(rfidToHex(tag?.tagB64))
    }
    setNewTagInfo(tagInfo);
    setEditTagModalOpened(!editTagModalOpened);
  };

  const isFormValidTag = tagInfo => {
    if (!tagInfo?.title?.length || !tagInfo?.tagB64?.length ||
      tagInfo?.tagId?.length !== 6 ||
      !tagInfo?.ressource?.length || !tagInfo?.sensor?.length) {
      setFormValidTag(false);
    } else {
      setFormValidTag(true);
    }
  };

  const rfidToHex = (tag) => {
    if (!tag?.length)
      return ['', '', '', '', '', '']
    return Buffer.from(tag, "base64")
      .toString("ascii")
      .split('')
      .map(e => {
        let val = e.charCodeAt(0).toString(16)
        while (val?.length < 2) {
          val = '0' + val
        }
        return val
      })
      .join('')
      .match(/.{1,4}/g)
  }

  const rfidToAscii = (tag) => {
    if (!tag?.join('')?.length)
      return ''
    return Buffer.from(tag
      .join('')
      .match(/.{1,2}/g)
      .map(e =>
        String.fromCharCode(parseInt(e, 16))
      ).join('')
    ).toString("base64")
  }

  const handleFormChangeTag = (key, value, idx = null) => {
    let tagInfo = { ...newTagInfo };
    if (key === 'tagId') {
      tagInfo[key][idx] = value;
      tagInfo.tagB64 = rfidToAscii(tagInfo[key])
    } else {
      tagInfo[key] = value;
    }
    setNewTagInfo(tagInfo);
    isFormValidTag(tagInfo);
  };

  const submitNewTag = async event => {
    event.preventDefault();
    const findTag = allTagsList?.find(it => it.tagB64 === newTagInfo.tagB64)
    if (findTag && newTagInfo.uuid && findTag.uuid !== newTagInfo.uuid) {
      openNotification(`Erreur, l'id ${newTagInfo.tagB64} est déjà utilisé`, 'danger')
      return
    }
    setFormValidTag(false);
    const tagInfo = {
      title: newTagInfo.title,
      tagB64: newTagInfo.tagB64,
      isCore: newTagInfo.isCore,
    };
    if (newTagInfo.uuid) {
      if (await reservationRfidServices.updateTag(newTagInfo.uuid, tagInfo, newTagInfo.ressource, newTagInfo.sensor)) {
        openNotification(`L'étiquette ${newTagInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification de l'étiquette ${newTagInfo?.title}`, 'danger')
      }
    } else {
      if (await reservationRfidServices.addTag(tagInfo, newTagInfo.ressource, newTagInfo.sensor)) {
        openNotification(`L'étiquette ${newTagInfo?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'étiquette ${newTagInfo?.title}`, 'danger')
      }
    }
    setEditTagModalOpened(!editTagModalOpened);
    setFormValidTag(true);
    refreshData();
  };

  const getTimeInterval = interval => {
    if (interval < 60) {
      return `${interval} seconde${interval === 1 ? '' : 's'}`
    } else if (interval < 3600) {
      return `${Math.round(interval / 60)} minute${interval < 120 ? '' : 's'}`
    } else if (interval < 86400) {
      return `${Math.round(interval / 3600)} heure${interval < 7200 ? '' : 's'}`
    } else if (interval < 2678400) {
      return `${Math.round(interval / 86400)} jour${interval < 172800 ? '' : 's'}`
    } else {
      return `${Math.round(interval / 2678400)} mois`
    }
  }

  return {
    rfidDestroy,
    rfidCount,
    rfidList,
    editModalOpened,
    editTagModalOpened,
    formSensorList,
    formRessourceList,
    formValid,
    formValidTag,
    limitRfid,
    limitSensorTag,
    limitTag,
    newRfidInfo,
    newTagInfo,
    notifColor,
    notifMessage,
    modalType,
    modalTypeTag,
    myself,
    openNotif,
    page,
    pageSensorTag,
    pageTag,
    tagsCount,
    tagsList,
    deleteRfid,
    getTimeInterval,
    handleFormChange,
    handleFormChangeTag,
    handlePagination,
    handlePaginationSensorTag,
    handlePaginationTag,
    modalMessageRfid,
    refreshData,
    refreshSensorTag,
    setOpenNotif,
    setRfidState,
    submitNewRfid,
    submitNewTag,
    toggleConfirmRfidDestroy,
    toggleModal,
    toggleModalTag,
  }
}

export default RfidLogic