import { get_axs } from "../../../helpers/fetcher";

export const bookingServices = { getAll, getAllOnMonthYear, getAllPage };

async function getAll({ ressources, estates, sectors, startDate, endDate, price }) {
  return await get_axs([
    `/modules/reservations/bookings?`,
    (ressources && ressources.length) && `ressources=${ressources}`,
    (sectors && sectors.length) && `sectors=${sectors}`,
    (estates && estates.length) && `estates=${estates}`,
    startDate && `start=${startDate}`,
    endDate && `end=${endDate}`,
    price && `price=${price?.[0] || 0}-${price?.[1] || 150}`
  ].filter(_ => _).join('&'));
}

async function getAllOnMonthYear(month, year, ressourceId, page) {
  return await get_axs(
    `/modules/reservations/bookings/on/year/${year}/month/${month}?currentPage=${page}&pageSize=15${ressourceId}`
  );
}

async function getAllPage(startDate, endDate, search, ressourceId, status, page) {
  console.log(startDate, endDate, search, ressourceId, page)
  const requestURL = `/modules/reservations/bookings/page?currentPage=${page}&pageSize=15&search=${search}&start=${startDate}&end=${endDate}${ressourceId}${status}`
  console.log(requestURL);
  return await get_axs(requestURL);
}
