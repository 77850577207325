import React, { useEffect, memo } from "react";

import {
  Button as MUIButton,
  CircularProgress,
  makeStyles,
  Backdrop,
  Modal,
} from "@material-ui/core";

import { CheckCircle, Notifications, Warning } from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import Pagination from "@material-ui/lab/Pagination";

// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import OubaboxList from "./OubaboxList";

import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import { OubaboxIcon } from "assets/img/icons";
import EditOubaboxModal from "./EditOubaboxModal";
import EditDoorModal from "./EditDoorModal";
import OubaboxLogic from "./OubaboxLogic";
import Filters from "./Filters";
import Log from "./Log";

const useStyles = makeStyles(styles);
const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const Module = () => {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    itemToDestroy,
    editModalOpened,
    limitOubaboxs,
    newItemInfos,
    notifColor,
    notifMessage,
    openNotif,
    page,
    order,
    orderBy,
    showLogs,
    modalType,
    deleteItem,
    handlePagination,
    handleRequestSort,
    refreshData,
    setOpenNotif,
    handleFilter,
    labelFilter,
    statesFilter,
    availableFilter,
    incidentsFilter,
    toggleConfirmDestroy,
    toggleModal,
    toggleDisplayLogs,
    handleFormChange,
    submitNewOubabox,
    submitNewDoor,
    getStatusColor,
    handleUpdateBoxSize,
    getComparator,
    statusFilter,
    stableSort,
    oubaboxsList,
    sizesList,
    estateSectorsList,
  } = OubaboxLogic();

  return oubaboxsList ? (
    <div>
      <Snackbar
        close
        place="tr"
        message={notifMessage}
        open={openNotif}
        icon={
          notifColor === "success"
            ? CheckCircle
            : notifColor === "danger"
            ? Warning
            : Notifications
        }
        closeNotification={() => setOpenNotif(false)}
        color={notifColor}
      />
      {showLogs?.uuid && (
        <Log
          oubaboxUUID={showLogs.uuid}
          defaultData={showLogs.data}
          stableSort={stableSort}
          oubaboxsList={oubaboxsList}
          getComparator={getComparator}
          toggleDisplayLogs={toggleDisplayLogs}
        />
      )}
      {itemToDestroy && (
        <Modal
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          className={classes.modal}
          onClose={() => toggleConfirmDestroy(null)}
          open={itemToDestroy ? true : false}
        >
          <div className={classes.paper}>
            <ConfirmModal
              closeModal={() => toggleConfirmDestroy(null)}
              kind={`Supprimer ${
                itemToDestroy.label
                  ? `l'Oubabox "${itemToDestroy.label}"`
                  : `la boite "${itemToDestroy.bid}"`
              } ?`}
              makeAction={() => deleteItem(itemToDestroy)}
            />
          </div>
        </Modal>
      )}
      {editModalOpened &&
        newItemInfos &&
        (newItemInfos?.label?.length >= 0 ? (
          <EditOubaboxModal
            newItemInfos={newItemInfos}
            editModalOpened={editModalOpened}
            modalType={modalType}
            handleFormChange={handleFormChange}
            submitNewOubabox={submitNewOubabox}
            toggleModal={toggleModal}
            estateSectorsList={estateSectorsList}
          />
        ) : (
          <EditDoorModal
            newItemInfos={newItemInfos}
            editModalOpened={editModalOpened}
            modalType={modalType}
            handleFormChange={handleFormChange}
            submitNewDoor={submitNewDoor}
            toggleModal={toggleModal}
            oubaboxsList={oubaboxsList}
            sizesList={sizesList}
          />
        ))}
      <GridContainer>
        <Tabs
          tabs={[
            {
              tabName: "Oubabox",
              tabIcon: () => <OubaboxIcon backgroundcolor="#3D406E" />,
              tabContent: (
                <GridItem xs={12} sm={12} md={12}>
                  <CardBody>
                    <GridContainer>
                      <Filters
                        oubabox
                        handleFilter={handleFilter}
                        statusFilter={statusFilter}
                        labelFilter={labelFilter}
                        statesFilter={statesFilter}
                        availableFilter={availableFilter}
                        incidentsFilter={incidentsFilter}
                        //estatesFilter={estatesFilter}
                      />
                      <GridItem xs={12} sm={12} md={12}>
                        <MUIButton
                          className={classes.button}
                          onClick={() => toggleModal(null)}
                          startIcon={<AddIcon />}
                        >
                          Ajouter une Oubabox
                        </MUIButton>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <div>
                    {oubaboxsList && oubaboxsList.length === 0 ? (
                      <h4>Aucunne Oubabox présente dans cette résidence</h4>
                    ) : (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <OubaboxList
                            sizesList={sizesList}
                            oubaboxsList={oubaboxsList}
                            getStatusColor={getStatusColor}
                            handleUpdateBoxSize={handleUpdateBoxSize}
                            toggleConfirmDestroy={toggleConfirmDestroy}
                            toggleDisplayLogs={toggleDisplayLogs}
                            toggleModal={toggleModal}
                            refreshData={refreshData}
                            order={order}
                            orderBy={orderBy}
                            getComparator={getComparator}
                            stableSort={stableSort}
                            onRequestSort={handleRequestSort}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    {oubaboxsList.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 100,
                        }}
                      >
                        <Pagination
                          count={Math.ceil(oubaboxsList.length / limitOubaboxs)}
                          page={page}
                          onChange={(_, pageNumber) =>
                            handlePagination(pageNumber)
                          }
                          classes={{ ul: paginationClasses.ul }}
                          size="large"
                        />
                      </div>
                    )}
                  </div>
                </GridItem>
              ),
            },
          ]}
        />
      </GridContainer>
    </div>
  ) : (
    <div className={classes.circularProgress}>
      <CircularProgress />
    </div>
  );
};

export default memo(Module);
