import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupActions, sectorActions, userActions } from "../../actions";
import { groupServices, userService } from "../../services";
import parsePhoneNumber from "libphonenumber-js";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FileType from "file-type/browser";

const UsersLogic = (rgpd) => {
  const dispatch = useDispatch();

  const { myself, loadedMyself } = useSelector(userState => userState.myself);
  const { groups } = useSelector(userState => userState.groupsFetching);
  const { sectors } = useSelector(userState => userState.sectorsFetching);

  const { sectorsFilter, groupsFilter } = useSelector(
    userState => userState.searchUsers,
  );

  // React State
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState(undefined);
  const [usersMeta, setUsersMeta] = useState(undefined);

  const [openDialog, setOpenDialog] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [dialogSelected, setDialogSelected] = useState("");
  const [userSelected, setUserSelected] = useState("");
  const [estateSelected, setEstateSelected] = useState(undefined);
  const [drawer, setDrawer] = useState({ right: false });
  const [page, setPage] = useState(1);
  // React userState for user modification
  const [userTitle, setUserTitle] = useState("null");
  const [userId, setUserId] = useState("");
  const [enteredFirstName, setFirstName] = useState("");
  const [enteredLastName, setLastName] = useState("");
  const [enteredEmail, setEmail] = useState("");
  const [enteredMobilePhone, setMobilePhone] = useState("+33");
  const [group, setGroup] = useState("null");
  const [sector, setSector] = useState("null");
  const [submitted, setSubmitted] = useState(false);
  const [query, setQuery] = useState("");
  const [sectorGroups, setSectorGroups] = useState(undefined);
  const [selectedSectorID, setSelectedSectorID] = useState(undefined);
  const [selectedGroupID, setSelectedGroupID] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const [refreshUsers, setRefreshUsers] = useState(false);
  const [createValidation, setCreateValidation] = useState(true);
  const [updateValidation, setUpdateValidation] = useState(true);
  const [pendingUserNumber, setPendingUserNumber] = useState(0);
  const [countryCode, setCountryCode] = useState("FR");
  const [reject, setReject] = useState([]);

  // React userState for filter handling
  const [userState, setUserState] = useState(undefined);
  const [sectorFilter, setSectorFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [submitSearch, setSubmitSearch] = useState(false);
  const [currentUser, setCurrentUser] = useState("");

  const [errorUpload, setErrorUpload] = useState([]);
  const [uploadUserStart, setUploadUserStart] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [file, setFile] = useState(undefined);
  const [fileData, setFileData] = useState([]);
  const [errorFileData, setErrorFileData] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileTemplate] = useState([
    ...(!rgpd ? [{
      key: "call_title",
      title: "Civilité",
      include: ["civilite"],
      exclude: [],
      allowNull: false,
    }] : []),
    {
      key: "first_name",
      title: "Prénom",
      include: ["prenom"],
      exclude: ["propriétaire"],
      allowNull: false,
    }, {
      key: "last_name",
      title: "Nom",
      include: ["nom"],
      exclude: ["prenom", "batiment", "propriétaire"],
      allowNull: false,
    }, {
      key: "email",
      title: "Email",
      include: ["email"],
      exclude: [],
      allowNull: false,
    },
    ...(!rgpd ? [{
      key: "mobile_phone",
      title: "Téléphone",
      include: ["telephone"],
      exclude: [],
      allowNull: true,
    }, {
      key: "group",
      title: "Numéro de lot",
      include: ["lot"],
      exclude: [],
      allowNull: false,
    }, {
      key: "sector",
      title: "Nom du bâtiment",
      include: ["batiment"],
      exclude: [],
      allowNull: false,
    }, {
      key: "occupationStatus",
      title: "Propriétaire / Locataire",
      include: ["proprietaire", "locataire"],
      exclude: ["prenom", "nom"],
      allowNull: false,
    }, {
      key: "first_name_owner",
      title: "Prénom propriétaire",
      include: ["prenom", "proprietaire"],
      exclude: [],
      allowNull: true,
    }, {
      key: "last_name_owner",
      title: "Nom propriétaire",
      include: ["nom", "proprietaire"],
      exclude: ["prenom", "batiment"],
      allowNull: true,
    }] : []),
  ]);

  useEffect(() => {
    setUserState("accepted");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (userState) {
        const response = await userService.getAllBySite(
          page,
          query,
          userState,
          sectorFilter.map(sector => `&sector=${sector.id}`).join(""),
          groupFilter.map(group => `&group=${group.id}`).join(""),
        );

        if (response) {
            setUsersList(response?.data?.rows);
            setUsersMeta(response?.data?.meta);
            setPendingUserNumber(
              userState === "accepted" || userState === "disabled"
                ? (await userService.getAllBySite(1, "", "pending", [], []))?.data
                  ?.meta?.count
                : 0,
            );
          }

        }
      // }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userState,
    groupsFilter,
    sectorsFilter,
    submitSearch,
    page,
    refresh,
    refreshUsers,
  ]);

  useEffect(() => {
    handleClearSearch();

    return () => {
      dispatch(groupActions.getAllByEstate());
      dispatch(sectorActions.getAll());
      setUsersList(undefined);
      setUsersMeta(undefined);
      handleUserFiltering("sector", []);
      handleUserFiltering("group", []);
      setSelectedSectorID(undefined);
      setSelectedGroupID(undefined);
      setSector("null");
      setGroup("null");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    (async () => {
      setGroup(undefined);
      if (selectedSectorID) {
        const response = await groupServices.getAllBySector(selectedSectorID);
        if (response) {
          setSectorGroups(response);
        } else setSectorGroups([]);
      } else {
        setSelectedSectorID(undefined);
        setSectorGroups(undefined);
      }
    })();
  }, [selectedSectorID]);

  useEffect(() => {
    if (userSelected) {
      handleUserEdition("uid", userSelected.uid);
      handleUserEdition("civility", userSelected.callTitle);
      handleUserEdition("firstName", userSelected.firstName);
      handleUserEdition("lastName", userSelected.lastName);
      handleUserEdition("email", userSelected.email);
      handleUserEdition("phone", userSelected.mobilePhone);
    }
  }, [userSelected]);

  useEffect(() => {
    // const phoneNumber = parsePhoneNumber(enteredMobilePhone || "", countryCode);
    const basicFormIsValid =
      // userTitle !== "null" &&
      enteredFirstName.length && enteredLastName.length && enteredEmail.length;
    // phoneNumber?.isValid();
    if (basicFormIsValid && (rgpd || (sector && group && group !== "null"))) {
      setCreateValidation(false);
      setUpdateValidation(true);
    } else if (basicFormIsValid) {
      setUpdateValidation(false);
      setCreateValidation(true);
    } else {
      setUpdateValidation(true);
      setCreateValidation(true);
    }
  }, [
    userTitle,
    enteredFirstName,
    enteredLastName,
    enteredEmail,
    enteredMobilePhone,
    sector,
    group,
  ]);

  const toggleSector = sectorID => {
    if (sectorID) {
      setSelectedSectorID(sectorID);
      setSelectedGroupID(undefined);
    }
  };

  const toggleGroup = groupID => {
    if (groupID) setSelectedGroupID(groupID);
  };

  const toggleQuery = query => {
    setQuery(query);
  };

  const refreshData = () => setRefresh(!refresh);

  const handleUserFiltering = (type, value) => {
    switch (type) {
      case "sector":
        setSectorFilter(value);

        break;
      case "group":
        setGroupFilter(value);

        break;
      case "userState":
        setUserState(value);
        break;
      default:
        break;
    }
  };

  const handlePagination = page => {
    setPage(page);
  };

  const handleUserAssociation = (type, value) => {
    switch (type) {
      case "sector":
        setSector(value);
        break;
      case "group":
        setGroup(value);
        break;
      default:
        break;
    }
  };

  const handleUserEdition = (fieldType, field, country = "") => {
    switch (fieldType) {
      case "civility":
        setUserTitle(field);
        break;
      case "uid":
        setUserId(field);
        break;
      case "firstName":
        setFirstName(field);
        break;
      case "lastName":
        setLastName(field);
        break;
      case "email":
        setEmail(field);
        break;
      case "phone":
        setMobilePhone(field);
        setCountryCode(country.toUpperCase());
        break;
      default:
        break;
    }
  };

  const toggleModal = () => {
    setSelectedSectorID(undefined);
    setSelectedGroupID(undefined);
    setSector("null");
    setGroup("null");
    setEditModalOpened(!editModalOpened);
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const toggleModalUserType = (user = "") => {
    setUserSelected(user);

    user ? setModalType(true) : setModalType(false);
    setEditModalOpened(true);
  };

  const handleUser = user => setUserSelected(user);

  const handleReason = (uuid, reason) => {
    setReject(reject.map(r => (r.uuid === uuid ? { ...r, reason } : r)));
  };

  const handleReject = etuUUID => {
    if (reject.find(eTu => eTu.uuid === etuUUID))
      setReject(reject.filter(eTu => eTu.uuid !== etuUUID));
    else if (!etuUUID) setReject([]);
    else setReject([...reject, { uuid: etuUUID, reason: "" }]);
  };

  const toggleValidationModal = (user, uuid) => {
    if (user) setUserSelected(user);
    if (uuid) setEstateSelected(uuid);
    setValidationModal(user ? true : false);
  };

  const submitUpdateHandler = event => {
    event.preventDefault();
    setSubmitted(!submitted);
    const userToUpdate = {
      uid: userId,
      call_title: userTitle,
      first_name: enteredFirstName,
      last_name: enteredLastName,
      email: enteredEmail,
      mobile_phone: enteredMobilePhone,
    };

    if (userToUpdate) {
      dispatch(
        userActions.update(userToUpdate),
      );
      openNotification(`L'utilisateur ${userToUpdate.first_name} ${userToUpdate.last_name} a été modifié`, "success");
      setUsersList(
        usersList.map(user =>
          user.uid === userToUpdate.uid
            ? {
              ...user,
              ...{
                callTitle: userTitle,
                firstName: enteredFirstName,
                lastName: enteredLastName,
                email: enteredEmail,
                mobilePhone: enteredMobilePhone,
              },
            }
            : user,
        ),
      );
      toggleModal(false);
    }
  };

//Called to add one user at the time from form.
  const submitCreateHandle = async event => {
    event.preventDefault();
    setSubmitted(!submitted);
    const userToCreate = {
      call_title: userTitle,
      first_name: enteredFirstName,
      last_name: enteredLastName,
      email: enteredEmail,
      mobile_phone: enteredMobilePhone,
      sectorId: selectedSectorID,
      groupId: selectedGroupID,
    };
    if (userToCreate) {
      setLoading(true);
      const user = await userService.createUser(userToCreate);
      if (
        user.firstName &&
        user.lastName &&
        user.mobilePhone &&
        user.callTitle
      ) {
        setRefresh(!refresh);
      } else {
        setUsersList([...usersList, user]);
      }
      toggleModal(false);
      setLoading(false);
      openNotification(`L'utilisateur ${user.firstName} ${user.lastName} a été créé`, "success");
    }
  };

  const handleClickOpen = (type, element, user) => {
    setUserSelected(user);
    if (type === "group") {
      setDialogSelected({ type: type, group: element });
      setOpenDialog(true);
    }
    if (type === "sector") {
      setDialogSelected({ type: type, sector: element });
      setOpenDialog(true);
    }
    if (type === "addGroup") {
      setDialogSelected({ type: type, element });
      setOpenDialog(true);
    }
    if (type === "addSector") {
      setDialogSelected({ type: type, element });
      setOpenDialog(true);
    }
    if (type === "removeUser") {
      setDialogSelected({ type: type, element });
      setOpenDialog(true);
    }
  };

  const handleAttach = async (userUUID, sectorUUID, groupUUID) => {
    if (userUUID && sectorUUID && groupUUID) {
      const response = await userService.linkEstateToUser(
        userUUID,
        sectorUUID,
        groupUUID,
      );
      const user = usersList.find(it => it.uid === userUUID);
      openNotification(`${user?.firstName} ${user?.lastName} a été associé à ${groups.find(it => it.uuid === groupUUID)?.title}`, "success");
      if (response) {
        setUsersList(
          usersList.map(user =>
            user.uid === userUUID
              ? {
                ...user,
                sectors: [
                  ...user.sectors.filter(
                    sector => sector.uuid !== sectorUUID,
                  ),
                ].concat(
                  sectors.find(sector => sector.id === response.sectorId) ||
                  [],
                ),
                groups: [
                  ...user.groups.filter(group => group.uuid !== groupUUID),
                ].concat(
                  groups.find(group => group.id === response.groupId) || [],
                ),
                estateToUsers: [...user.estateToUsers].concat(response),
              }
              : user,
          ),
        );
        setUsersMeta(response?.data?.meta);
        setSelectedSectorID(undefined);
        setSectorGroups(undefined);
        setOpenDialog(false);
        toggleDrawer(false);
      }
    }
  };

  const handleDetach = async (userUUID, sectorUUID, groupUUID) => {
    setLoading(true);

    if (userState === "accepted") {
      const response = await userService.unLinkEstateToUser(
        userUUID,
        sectorUUID,
        groupUUID,
      );
      if (response) setLoading(false);
    } else {
      await userService.linkEstateToUser(
        userUUID,
        sectorUUID,
        groupUUID,
      );
    }
    const user = usersList.find(it => it.uid === userUUID);
    openNotification(`${user?.firstName} ${user?.lastName} a été ${userState === "accepted" ? "dissocié de" : "associé à"} ${groups.find(it => it.uuid === groupUUID)?.title}`, "success");
    setOpenDialog(false);
    setLoading(false);
    if (usersList.length === 1)
      setPage(page - 1 || 1);
    setRefresh(!refresh);
  };

  const handleRemoveFromResidence = async userUid => {
    setLoading(true);
    const response = await userService.removeFromResidence(userUid);
    setOpenDialog(false);
    if (response) {
      setLoading(false);
      setRefresh(!refresh);
      toggleDrawer(false);
      openNotification(
        "L'utilisateur a bien été supprimé de la résidence.",
        "success",
        6,
      );
    } else {
      openNotification(
        "Une erreur s'est produite lors de l'exclusion de l'utilisateur.",
        "danger",
        6,
      );
    }
  };

  const searchFilterHandling = () => handleSubmitSearch(!submitSearch);

  const handleClearSearch = async () => {
    setGroupFilter([]);
    setSectorFilter([]);
    setQuery("");
    setSubmitSearch(!submitSearch);
  };

  const toggleDrawer = (anchor, open, user) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setCurrentUser(user);
    setDrawer({ ...drawer, [anchor]: open });
  };

  const sendNewPassword = userUUID => {
    if (userUUID) {
      dispatch(userActions.sendNewPassword(userUUID));
      openNotification(
        "Un email contenant un lien de modification de mot de passe a été envoyé.",
        "success",
        6,
      );
    }
  };
  const handleSubmitSearch = bool => {
    setSubmitSearch(bool);
  };

  const validate = (etuUUID, decision) => {
    const response = dispatch(
      userActions.controlAccess(
        etuUUID,
        decision,
        reject.find(r => r.uuid === etuUUID)?.reason,
      ),
    );
    if (response) {
      const user = {
        ...userSelected,
        estateToUsers:
          userSelected.estateToUsers.filter(eTu => eTu.uuid !== etuUUID) || [],
      };
      const estateId = userSelected.estateToUsers.find(
        eTu => eTu.uuid === etuUUID,
      )?.estateId;
      handleUser(user);
      setUsersList(
        usersList.map(elem => (elem.uid === user.uid ? user : elem)),
      );
      if (decision) {
        openNotification(`La demande d'inscription de ${user.firstName} ${user.lastName} a été accepté`, "success");
      } else {
        openNotification(`La demande d'inscription de ${user.firstName} ${user.lastName} a été refusé`, "success");
      }
      if (
        (!user.estateToUsers.filter(
            eTu => eTu.state === "pending" && eTu.estateId === estateId,
          ).length &&
          userState === "pending") ||
        (!user.estateToUsers.filter(
            eTu => eTu.state === "denied" && eTu.estateId === estateId,
          ).length &&
          userState === "denied")
      ) {
        setUsersList(usersList.filter(user => user.uid !== userSelected.uid));
      }
    }
    toggleValidationModal(false);
  };

  const handleUserState = newState => {
    setPage(1);
    setUserState(newState);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

//Called for batch import / User List from Excel File
  const uploadUser = async (rgpd) => {
    setProgress(0);
    setErrorUpload([]);
    setUploadUserStart(true);
    let errorTotal = 0;
    let userUpload = 0;
    for (let line of fileData) {
      setProgress((fileData.length / line.line) * 100);
      let error = 0;
      let sector = null;
      if (line.data?.sector !== null && !rgpd) {
        sector = sectors.find(
          it =>
            it.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase() ===
            line.data?.sector
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
        );
        if (!sector) {
          error += 1;
          setErrorUpload(oldArray => [
            ...oldArray,
            `Ligne ${line.line}: ${fileTemplate.find(it => it.key === "sector").title
            }: Valeur invalide`,
          ]);
        }
      }
      let group = null;
      if (line.data?.group !== null && !rgpd) {
        group = groups.find(
          it =>
            it.title
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase() ===
            line.data?.group
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
        );
        if (!group) {
          error += 1;
          setErrorUpload(oldArray => [
            ...oldArray,
            `Ligne ${line.line}: ${fileTemplate.find(it => it.key === "group").title
            }: Valeur invalide`,
          ]);
        }
      }
      if (line.data?.call_title !== null && !rgpd) {
        if (
          line.data?.call_title.toUpperCase() !== "MR" &&
          line.data?.call_title.toUpperCase() !== "MM"
        ) {
          error += 1;
          setErrorUpload(oldArray => [
            ...oldArray,
            `Ligne ${line.line}: ${fileTemplate.find(it => it.key === "call_title").title
            }: Valeur invalide (MR ou MM)`,
          ]);
        }
      }
      let phoneNumber = null;
      if (line?.data?.mobile_phone !== null && !rgpd) {
        phoneNumber = parsePhoneNumber(
          line.data?.mobile_phone || "",
          countryCode,
        );
        if (!phoneNumber || !phoneNumber.isValid()) {
          error += 1;
          setErrorUpload(oldArray => [
            ...oldArray,
            `Ligne ${line.line}: ${fileTemplate.find(it => it.key === "mobile_phone").title
            }: Numéro de téléphone invalide`,
          ]);
        }
      }
      let occupationStatus = null;
      if (line.data?.occupationStatus !== null && !rgpd) {
        const ownerNorm = line.data?.occupationStatus
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        if (
          ownerNorm.search("proprietaire") < 0 &&
          ownerNorm.search("locataire") < 0
        ) {
          error += 1;
          setErrorUpload(oldArray => [
            ...oldArray,
            `Ligne ${line.line}: ${fileTemplate.find(it => it.key === "occupationStatus").title
            }: Valeur invalide (Propriétaire ou Locataire)`,
          ]);
        } else {
          occupationStatus = ownerNorm.search("proprietaire") < 0 ? "TENANT" : "OWNER";
        }
      }
      const owner = [line.data?.first_name_owner, line.data?.last_name_owner];
      if (error) {
        errorTotal += error;
        continue;
      }
      const userToCreate = {
        call_title: line.data?.call_title,
        first_name: line.data?.first_name,
        last_name: line.data?.last_name,
        email: line.data?.email,
        mobile_phone: phoneNumber?.number,
        sectorId: sector?.id,
        groupId: group?.id,
      };
      if (
        userToCreate &&
        !usersList.find(it => it.email === line.data?.email)
      ) {
        //Finally, let's create the user.
        const usr = await userService.createUser(userToCreate);
        if (!usr) {
          errorTotal += 1;
          setErrorUpload(oldArray => [
            ...oldArray,
            `Ligne ${line.line}: Une erreur est survenue pendant la création de l'utilisateur, veuillez vérifier les données ou réessayer ultérieurement`,
          ]);
          continue;
        }
        if (occupationStatus) {
          await userService.updateOccupationStatus(usr.uid, occupationStatus, group?.id);
        }
        const eTuToUpdate = {
          ownerName: owner.join(" "),
        };
        if (eTuToUpdate.ownerName.length > 1) {
          await userService.updateOwnerName(usr.estateToUsers.find(it => it.groupId === group?.id && it.sectorId === sector?.id).uuid, eTuToUpdate);
        }
        userUpload += 1;
      } else {
        errorTotal += 1;
        setErrorUpload(oldArray => [
          ...oldArray,
          `Ligne ${line.line}: L'utilisateur existe déjà ou cette adresse email est déjà utilisée`,
        ]);
      }
    }
    setProgress(100);
    setRefreshUsers(!refreshUsers);
    const userPlural = userUpload > 1 ? "s" : "";
    const errorPlural = errorTotal > 1 ? "s" : "";
    openNotification(
      `La création des utilisateurs est terminée: ${userUpload} utilisateur${userPlural} créé${userPlural}, ${errorTotal} erreur${errorPlural} détectée${errorPlural}`,
      "info",
      15,
    );
    setUploadUserStart(false);
  };

  const generateHeaderInfo = (line = 1) => {
    let header = { line };
    fileTemplate.map(it => (header[it.key] = null));
    return header;
  };

  const validateHeaderInfo = headerInfo => {
    return fileTemplate.find(it => headerInfo[it.key] === null) === undefined;
  };

  const parseFileKey = (cell, headerInfo, col) => {
    for (let box of fileTemplate) {
      if (
        !headerInfo[box.key] &&
        box.include
          .map(it => cell.toLowerCase().search(it) < 0)
          .reduce((a, b) => a + b, 0) === 0 &&
        box.exclude
          .map(it => cell.toLowerCase().search(it) >= 0)
          .reduce((a, b) => a + b, 0) === 0
      ) {
        headerInfo[box.key] = col;
        return headerInfo;
      }
    }
    return headerInfo;
  };

  const fillFileData = (v, data, line, key) => {
    if (!data.find(it => it.line === line)) {
      let l = { line, data: {} };
      fileTemplate.map(it => (l.data[it.key] = null));
      data.push(l);
    }
    data.find(it => it.line === line).data[key] = v.toString();
    return data;
  };

  const parseFileData = (f, headerInfo) => {
    let data = [];
    const keys = Object.keys(f).filter(it => it[0] !== "!");
    const keysHeader = fileTemplate.map(it => it.key);
    keys.forEach(c => {
      const line = c.match(/^\d+|\d+\b|\d+(?=\w)/g)[0];
      const col = c.replace(/[0-9]/g, "");
      if (parseInt(line) <= parseInt(headerInfo.line)) return;
      const key = keysHeader.find(it => headerInfo[it] === col);
      if (key) {
        data = fillFileData(f[c].v, data, line, key);
      }
    });
    setFileData(data);
    setFileUpload(true);
  };

  const parseFile = (f) => {
    let headerInfo = generateHeaderInfo();
    let line = 1;
    let col = "A";
    const keys = Object.keys(f).filter(it => it[0] !== "!");
    for (let c of keys) {
      line = c.match(/^\d+|\d+\b|\d+(?=\w)/g)[0];
      col = c.replace(/[0-9]/g, "");
      if (line !== headerInfo.line) headerInfo = generateHeaderInfo(line);
      headerInfo = parseFileKey(
        f[c].v
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
        headerInfo,
        col,
      );
      if (validateHeaderInfo(headerInfo)) {
        parseFileData(f, headerInfo);
        return;
      }
    }
    openNotification(
      "Erreur lors de la lecture du fichier. Vérifiez que le titre des colonnes comprend ces champs: " +
      fileTemplate.map(it => it.title).join(", "),
      "danger",
      15,
    );
  };

  const readFile = () => {
    setErrorUpload([]);
    setUploadUserStart(false);
    setFileUpload(false);
    setErrorFileData(false);
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => {
      console.log("file reading has failed");
      openNotification(
        "La lecture du fichier a échoué ! Vérifiez que le fichier existe ou ressayer de le sélectionner",
        "danger",
        15,
      );
    };
    reader.onload = evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      parseFile(ws);
    };
    reader.readAsBinaryString(file);
  };

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) setFile(acceptedFiles[0]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    type: "file",
    accept:
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, text/csv",
    onDrop,
    maxFiles: 1,
  });

  const handleUpdateOccupationStatus = async (userUUID, status, groupId) => {
    await userService.updateOccupationStatus(
      userUUID,
      status,
      groupId,
    );
    setRefresh(!refresh);
    openNotification("Le statut d'occupation a bien été modifié", "success");
  };

  const downloadProofOfResidence = async (userUUID, filename) => {
    const res = await userService.downloadProofOfResidence(userUUID, filename);
    if (res) {
      const blob = new Blob([res]);
      const type = await FileType.fromBlob(blob);
      saveAs(blob, "Justificatif_de_domicile." + type.ext);
    } else {
      openNotification("Erreur, aucun justificatif n'a été trouvé", "danger");
    }
  };

  return {
    createValidation,
    currentUser,
    dialogSelected,
    drawer,
    editModalOpened,
    errorFileData,
    errorUpload,
    estateSelected,
    file,
    fileData,
    fileTemplate,
    fileUpload,
    group,
    groups,
    groupFilter,
    isDragAccept,
    isDragActive,
    isDragReject,
    loading,
    query,
    modalType,
    myself,
    notifColor,
    notifMessage,
    openDialog,
    openNotif,
    page,
    pendingUserNumber,
    progress,
    refresh,
    sector,
    sectors,
    sectorFilter,
    sectorGroups,
    reject,
    updateValidation,
    uploadUserStart,
    usersList,
    usersMeta,
    userSelected,
    userState,
    userTitle,
    validationModal,
    downloadProofOfResidence,
    getInputProps,
    getRootProps,
    handleAttach,
    handleClearSearch,
    handleClickOpen,
    handleDetach,
    handlePagination,
    handleRemoveFromResidence,
    handleUpdateOccupationStatus,
    handleUser,
    handleUserAssociation,
    handleUserEdition,
    handleUserFiltering,
    handleUserState,
    handleReason,
    handleReject,
    readFile,
    refreshData,
    setErrorFileData,
    setOpenNotif,
    setUsersList,
    sendNewPassword,
    searchFilterHandling,
    submitCreateHandle,
    submitUpdateHandler,
    toggleDialog,
    toggleDrawer,
    toggleGroup,
    toggleQuery,
    toggleModal,
    toggleModalUserType,
    toggleSector,
    toggleValidationModal,
    uploadUser,
    validate,
  };
};

export default UsersLogic;
