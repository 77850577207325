import { get_axs, post_axs, put_axs, remove_axs } from "../../../helpers/fetcher";

export const reservationRfidServices = {
  add,
  update,
  destroy,
  getAll,
  addTag,
  updateTag,
  destroyTag,
  getAllTag,
};

async function add(data) {
  return post_axs(
    "/modules/reservations/rfid",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(uuid, data) {
  return put_axs(
    `/modules/reservations/rfid/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function getAll() {
  return get_axs("/modules/reservations/rfid");
}


async function destroy(uuid) {
  return remove_axs(`/modules/reservations/rfid/${uuid}`);
}

async function addTag(data, ressourceUUID, sensorUUID) {
  return post_axs(
    "/modules/reservations/rfid/tag",
    new URLSearchParams({
      ...data,
      ressourceUUID,
      sensorUUID,
    })
  );
}

async function updateTag(uuid, data, ressourceUUID, sensorUUID) {
  return put_axs(
    `/modules/reservations/rfid/tag/${uuid}`,
    new URLSearchParams({
      ...data,
      ressourceUUID,
      sensorUUID,
    })
  );
}

async function getAllTag() {
  return get_axs("/modules/reservations/rfid/tag");
}


async function destroyTag(uuid) {
  return remove_axs(`/modules/reservations/rfid/tag/${uuid}`);
}
