/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

export default function FormRfid(props) {
  const classes = useStyles();

  const {
    formValid,
    modalType,
    newRfidInfo,
    handleFormChange,
    submitNewRfid,
    toggleModal,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalType ? (
            <Typography variant="h5">Modifier l'antenne</Typography>
          ) : (
            <Typography variant="h5">Ajouter une antenne</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewRfid} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Titre *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newRfidInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={4}>
              <InputLabel>IP / Domaine *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newRfidInfo.host}
                name="host"
                required
                fullWidth
                id="host"
                margin="normal"
                variant="outlined"
                onChange={event =>
                  handleFormChange("host", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputLabel>Port *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newRfidInfo.port}
                name="port"
                required
                fullWidth
                type="number"
                variant="outlined"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                margin="normal"
                id="port"
                onChange={event => 
                  handleFormChange("port", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Utilisateur *</InputLabel>
              <TextField
                defaultValue={newRfidInfo.username}
                name="usernameRfid"
                required
                fullWidth
                id="usernameRfid"
                onChange={event =>
                  handleFormChange("username", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Mot de passe *</InputLabel>
              <TextField
                defaultValue={newRfidInfo.password}
                name="passwordRfid"
                required
                fullWidth
                id="passwordRfid"
                type={newRfidInfo.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleFormChange('showPassword', null)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {newRfidInfo.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
                onChange={event =>
                  handleFormChange("password", event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Enregister
          </Button>
        </form >
      </div >
    </Container >
  );
}

FormRfid.propTypes = {
  formValid: PropTypes.bool,
  modalType: PropTypes.bool,
  newRfidInfo: PropTypes.object,
  handleFormChange: PropTypes.func,
  submitNewRfid: PropTypes.func,
  toggleModal: PropTypes.func,
};
