import React, { useEffect, useMemo } from "react";
import GroupsLogic from "./GroupsLogic";

import {
  CircularProgress,
  Modal,
  CssBaseline,
  Container,
  makeStyles,
  TextField,
  Button as MUIButton,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Box,
  Typography,
  Tooltip,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Card from "components/Card/Card.js";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fbfbfb",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles(styles);

export default function Groups(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    editModalOpened,
    errorFileData,
    errorUpload,
    file,
    fileData,
    fileTemplate,
    fileUpload,
    isDragActive,
    isDragAccept,
    isDragReject,
    formAttributesList,
    groupDestroy,
    groupsCount,
    groupsList,
    limitGroups,
    modalType,
    newGroupsInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    progress,
    uploadGroupStart,
    deleteGroup,
    getRootProps,
    getInputProps,
    handleFormChange,
    handlePagination,
    readFile,
    refreshData,
    setErrorFileData,
    setOpenNotif,
    submitNewGroup,
    toggleConfirmGroupDestroy,
    toggleModal,
    uploadGroup,
  } = GroupsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (groupsList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {groupDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmGroupDestroy(null)}
            open={groupDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmGroupDestroy(null)}
                kind={`Supprimer l'appartement "${groupDestroy.title}" ?`}
                makeAction={() => deleteGroup(groupDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newGroupsInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    {modalType ? (
                      <Typography variant="h5">Modifier l'appartement</Typography>
                    ) : (
                      <Typography variant="h5">Ajouter un appartement</Typography>
                    )}
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewGroup} noValidate>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <InputLabel>Titre *</InputLabel>
                        <TextField
                          autoComplete="fname"
                          defaultValue={newGroupsInfo.title}
                          name="title"
                          required
                          fullWidth
                          id="title"
                          onChange={event =>
                            handleFormChange("title", event.target.value)
                          }
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel>Secteur *</InputLabel>
                        {formAttributesList ? (
                          <Select
                            value={newGroupsInfo.attribute}
                            style={{ top: "16px" }}
                            id="select-type"
                            fullWidth
                            displayEmpty
                            onChange={event =>
                              handleFormChange("attribute", event.target.value)
                            }
                          >
                            <MenuItem value={""}>
                              <em>Aucune séléction</em>
                            </MenuItem>
                            {formAttributesList &&
                              formAttributesList.map((it, i) => (
                                <MenuItem key={i} value={it.uuid}>
                                  <div>{it.name}</div>
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          <Typography variant="h3">
                            <Skeleton width="auto" />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newGroupsInfo?.title?.length || !newGroupsInfo?.attribute?.length}
                    >
                      Enregister
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Appartements",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter un appartement
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>

                    <div>
                      {groupsList && groupsList.length === 0 ? (
                        <h4>Aucun appartement à afficher</h4>
                      ) :
                        (<Table
                          tableHeaderColor="primary"
                          tableType="links"
                          tableHead={
                            [
                              "Titre",
                              "Secteur",
                              ""
                            ]
                          }
                          tableData={
                            groupsList &&
                            groupsList.map(group => {
                              const groupList = {
                                title: group?.title,
                                sector: group?.sector?.name,
                                actions: (
                                  <div style={{
                                    display: "flex",
                                  }}>
                                    <Button
                                      style={{ marginLeft: 'auto', }}
                                      size="sm"
                                      onClick={() => toggleModal(group)}
                                      color="warning"
                                    >
                                      Éditer
                                    </Button>
                                    <Button
                                      size="sm"
                                      style={{ marginLeft: '2%' }}
                                      onClick={() => toggleConfirmGroupDestroy(group)}
                                      color="danger"
                                    >
                                      Supprimer
                                    </Button>
                                  </div>
                                ),
                              };
                              return Object.values(groupList);
                            })
                          }
                        />)
                      }
                      {groupsCount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 100,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(groupsCount / limitGroups)}
                            page={page}
                            onChange={(event, pageNumber) => {
                              handlePagination(pageNumber);
                            }}
                            classes={{ ul: paginationClasses.ul }}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                )
              },
              {
                tabName: "Importer des appartements",
                tabIcon: GroupAddIcon,
                tabContent: (
                  <div style={{ marginTop: "20px" }}>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p style={{ fontSize: 16 }}>
                          Déposer le fichier ici ... (format .xsl, .xlsx,
                          .ods, .csv)
                        </p>
                      ) : (
                        <p style={{ fontSize: 16 }}>
                          Déposer le fichier ici ou selectionner le en
                          cliquant ici (format .xls, .xlsx, .ods, .csv)
                        </p>
                      )}
                    </div>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1.5%",
                          fontSize: 18,
                          marginBottom: "15px",
                        }}
                      >
                        {file?.name}
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          onClick={readFile}
                          disabled={!file}
                          color="primary"
                        >
                          Lire le fichier
                        </Button>
                      </GridItem>
                      <Card
                        style={{
                          boxShadow: "none",
                          marginTop: 0,
                          paddingTop: 0,
                        }}
                      >
                        <CardBody>
                          {fileUpload && (
                            <Table
                              tableHeaderColor="primary"
                              tableType="user"
                              tableHead={["Ligne"].concat(
                                fileTemplate
                                  .map(it => it.title + (it.allowNull ? "" : " *"))
                                  .concat(["Statut"])
                              )}
                              tableData={
                                fileData &&
                                fileData.map(line => {
                                  const lineList = { ligne: line.line };
                                  fileTemplate.forEach(
                                    it =>
                                    (lineList[it.title] =
                                      line.data[it.key])
                                  );
                                  const missingData =
                                    fileTemplate
                                      .map(it => line.data[it.key] === null && !it.allowNull)
                                      .reduce((a, b) => a + b, 0) > 0;
                                  if (missingData && !errorFileData) {
                                    setErrorFileData(true);
                                  }
                                  lineList.status = missingData ? (
                                    <Tooltip title="Un ou plusieurs champs sont manquant">
                                      <WarningIcon
                                        style={{ color: "#f0ad4e" }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <CheckCircleIcon
                                      style={{ color: "#5cb85c" }}
                                    />
                                  );
                                  return Object.values(lineList);
                                })
                              }
                            />
                          )}
                        </CardBody>
                        {fileUpload && (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "2%",
                              marginBottom: "2%",
                            }}
                          >
                            <Button
                              onClick={uploadGroup}
                              disabled={errorFileData}
                              color="primary"
                            >
                              Ajouter les appartements
                            </Button>
                          </GridItem>
                        )}
                        {uploadGroupStart && (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "1%",
                            }}
                          >
                            <Box position="relative" display="inline-flex">
                              <CircularProgress variant="determinate" value={progress} />
                              <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                                  progress,
                                )}%`}</Typography>
                              </Box>
                            </Box>
                          </GridItem>
                        )}
                        {errorUpload &&
                          errorUpload.map((error, i) => (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              key={i}
                            >
                              <WarningIcon style={{ color: "#f0ad4e" }} />
                              <div style={{ marginLeft: "10px" }}>
                                {error}
                              </div>
                            </GridItem>
                          ))}
                      </Card>
                    </GridContainer>
                  </div>
                ),
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}